import React, { useEffect, useState } from "react";
import { Table } from "keep-react";
import axios from "axios";
import apiUrl from "../apiConfig";
import DocumentOrderModal from "../components/DocumentOrderModal";
import AddDocumentModal from "../components/AddDocumentModal";
import AddSectionModal from "../components/AddSectionModal";
import DeleteDocumentModal from "../components/DeleteDocumentModal";
import { FaFileCircleCheck } from "react-icons/fa6";
import Login from "./Login";
import { RxDragHandleHorizontal } from "react-icons/rx";
import { IoAdd } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import DeleteSectionModal from "../components/DeleteSectionModal";
import EditSectionModal from "../components/EditSectionModal";
import { LuPencilLine } from "react-icons/lu";
import { CircleProgress, LineProgress } from "keep-react";
import Spinnerpage from "../components/Spinnerpage";
import PieChart from "../components/PieChart";
import { PiDotsThreeOutlineDuotone } from "react-icons/pi";
import { FiDownload } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GiUpgrade } from "react-icons/gi";
import Tooltip from "../components/Tooltip";

export default function Documents({ isDarkMode, url }) {
  const [documents, setDocuments] = useState([]);
  const [openDocumentModal, setOpenDocumentModal] = useState([false]);
  const [openSectionModal, setOpenSectionModal] = useState(false);
  const [openDeleteSectionModal, setOpenDeleteSectionModal] = useState([false]);
  const [openEditSectionModal, setOpenEditSectionModal] = useState([false]);
  const [openDeleteModal, setOpenDeleteModal] = useState({});
  const [pieData, setPieData] = useState({});
  const [openCompModal, setopenCompModal] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [sectionsLength, setSectionsLength] = useState(0);
  const [documentsLength, setDocumentsLength] = useState(0);
  const manageCompModal = () => {
    setopenCompModal(!openCompModal);
  };
  // const [pdfUrl, setPdfUrl] = useState("");
  const [param, setParam] = useState(false);
  const token = localStorage.getItem("token");

  const handleModal = (sectionKey) => {
    setParam(!param);
    const updatedModal = [...openDocumentModal];
    updatedModal[sectionKey] = !updatedModal[sectionKey];
    setOpenDocumentModal(updatedModal);
  };
  const [activeResume, setActiveResume] = useState("");

  const handleSectionModal = () => {
    setOpenSectionModal(!openSectionModal);
  };

  const handleDeleteSection = (sectionKey) => {
    setOpenDeleteSectionModal(!openDeleteSectionModal);
  };

  const handleDeleteModal = (sectionKey, documentIndex) => {
    setParam(!param);
    const updatedModal = { ...openDeleteModal };
    updatedModal[sectionKey][documentIndex] =
      !updatedModal[sectionKey][documentIndex];
    setOpenDeleteModal(updatedModal);
  };

  const handleDeleteSectionModal = (sectionKey, sectionIndex) => {
    // // // console,log(openDeleteSectionModal);
    // // // console,log(sectionKey);
    // // // console,log(sectionIndex);
    setParam(!param);
    const updatedModal = [...openDeleteSectionModal];
    updatedModal[sectionIndex] = !updatedModal[sectionIndex];
    setOpenDeleteSectionModal(updatedModal);
  };
  const handleEditSectionModal = (sectionKey, sectionIndex) => {
    setParam(!param);
    const updatedModal = [...openEditSectionModal];
    updatedModal[sectionIndex] = !updatedModal[sectionIndex];
    setOpenEditSectionModal(updatedModal);
    return;
  };

  const handleeditclosedeleteopen = (sectionKey, sectionIndex) => {
    const updatedModal = [...openEditSectionModal];
    const updatedModal1 = [...openEditSectionModal];
    updatedModal1[sectionIndex] = !updatedModal1[sectionIndex];
    setOpenDeleteSectionModal(updatedModal);
    setOpenEditSectionModal(updatedModal1);
    // // // console,log("setOpenDeleteSectionModal: ", updatedModal)
    // // // console,log("setOpenEditSectionModal: ", updatedModal1)
  };
  const handleDelete = async (sectionKey, doc, documentIndex) => {
    // // // // console,log(section);
    // // // // console,log(doc);
    setParam(!param);
    // // // console,log(openDeleteModal);
    const updatedModal = { ...openDeleteModal };
    updatedModal[sectionKey][documentIndex] =
      !updatedModal[sectionKey][documentIndex];
    // // // console,log(updatedModal);
    setOpenDeleteModal(updatedModal);
  };

  // const handleSectionDelete = async (sectionKey) => {};

  const [linkchanged, setlinkchanged] = useState(activeResume);
  const [documentsSize, setDocumentsSize] = useState(0);

  const makeactive = async (link) => {
    try {
      const reqbody = {
        Resume: link,
      };
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      if (response.status === 200) {
        setlinkchanged(link);
      }
      // // // console,log(reqbody);
    } catch (err) {
      // // // console,log(err);
    }
  };
  const [loading, setloading] = useState(false);
  const [showdoc, setshowdoc] = useState({});
  useEffect(() => {
    const getactiveresume = async () => {
      try {
        const response = await axios.post(apiUrl.profile.getprofile + token);
        // // // console,log(response.data.existing_profile.Resume);
        setActiveResume(response.data.existing_profile.Resume);
      } catch (err) {
        // // // console,log(err);
      }
    };

    const getDocuments = async () => {
      if (param == false) {
        try {
          const response = await axios.post(
            apiUrl.documents.getdocuments + `${token}`
          );
          if (response && response.data.documents) {
            // // // console,log(response.data.documents);
            setDocumentsSize(response.data.size.toFixed(2));
            setDocuments(response.data.documents);
            const sectionlen = response.data.documents.length;
            setSectionsLength(response.data.documents.length);
            const obj = {};
            const resp = {};
            let totalDocumentsCount = 0;
            response.data.documents.forEach((key) => {
              resp[key.Name] = key.Documents;
            });
            // // // console,log(resp);
            for (const key in resp) {
              totalDocumentsCount += resp[key].length;
            }
            setDocumentsLength(totalDocumentsCount);
            setshowdoc(resp);
            const keys = Object.keys(resp);

            keys.forEach((key) => {
              obj[key] = Array.from({ length: resp[key].length }, () => false);
            });
            // // // console,log(obj);
            setOpenDeleteModal(obj);
            const newArray = Array.from({ length: sectionlen }, () => false);
            const newSectionArray = Array.from(
              { length: sectionlen },
              () => false
            );
            // // // console,log(newArray);
            setOpenDeleteSectionModal(newSectionArray);
            setOpenEditSectionModal(newSectionArray);
            setOpenDocumentModal(newArray);
          }
        } catch (error) {
          // console.error(error);
        }
      }
    };

    getDocuments();
    getactiveresume();
  }, [token, param, openSectionModal, linkchanged, openCompModal]);

  if (token === null) return <Login />;
  else if (loading) {
    return (
      <div>
        <Spinnerpage />{" "}
      </div>
    );
  } else
    return (
      <div
        className={`flex justify-center min-h-screen ${
          // f0f1f4
          isDarkMode ? "bg-[#282c34]" : "bg-[#ffffff]"
        }`}
      >
        <div className="w-full md:max-w-screen-xl md:w-[100%] px-4 ">
          <div className="  flex justify-between items-center w-full h-fit mt-4">
            <div className="text-xl font-[550] flex items-center gap-1">
              {" "}
              Documents{" "}
              <Tooltip text="Organize your documents effortlessly by creating sections with custom names. Tailor your file structure to your needs for easy access and better management!">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                  />
                </svg>
              </Tooltip>
            </div>
            <div className=" flex gap-2 items-center h-full">
              <div
                className="md:block hidden text-[#0052ff] duration-300 cursor-pointer mt-2 py-1 px-4 border rounded-3xl border-[#0052ff] hover:bg-blue-200 "
                onClick={manageCompModal}
              >
                <p className=" md:text-sm text-xs">Reorder Sections</p>
              </div>
              <div
                className=" block md:hidden h-full mt-1"
                onClick={manageCompModal}
              >
                <RxDragHandleHorizontal size={25} />
              </div>
              <div
                className=" block md:hidden h-full mt-1"
                onClick={handleSectionModal}
              >
                <IoAdd size={25} />
              </div>
              <div
                className="md:block hidden bg-[#0052ff] text-white mt-2 w-fit cursor-pointer  hover:bg-[#014cec] py-1 px-4 rounded-3xl"
                onClick={handleSectionModal}
              >
                <p className=" md:text-sm text-xs">Add Section</p>
              </div>
            </div>
          </div>

          <div className="flex md:flex-row-reverse flex-col gap-x-4">
            <div className="w-[100%]">
              <div className=" w-full">
                {openSectionModal && (
                  <AddSectionModal
                    handleSectionModal={handleSectionModal}
                    isDarkMode={isDarkMode}
                  />
                )}
                {Object.keys(showdoc).length === 0 && (
                  <div className="flex h-full w-full justify-center items-center">
                    <Spinnerpage />
                  </div>
                )}

                {openCompModal && (
                  <DocumentOrderModal
                    manageCompModal={manageCompModal}
                    openCompModal={openCompModal}
                    url={url}
                    isDarkMode={isDarkMode}
                  />
                )}
                {Object.keys(showdoc).map((sectionKey, sectionIndex) => (
                  <div key={sectionIndex}>
                    <div className="flex justify-center text-black">
                      {openDocumentModal[sectionIndex] && (
                        <AddDocumentModal
                          handleModal={handleModal}
                          sectionKey={sectionKey}
                          sectionIndex={sectionIndex}
                          isDarkMode={isDarkMode}
                          documentsSize={documentsSize}
                        />
                      )}

                      {openEditSectionModal[sectionIndex] && (
                        <EditSectionModal
                          sectionKey={sectionKey}
                          sectionIndex={sectionIndex}
                          handleEditSectionModal={() =>
                            handleEditSectionModal(sectionKey, sectionIndex)
                          }
                          handleDeleteSectionModal={() =>
                            handleDeleteSectionModal(sectionKey, sectionIndex)
                          }
                          handleeditclosedeleteopen={() =>
                            handleeditclosedeleteopen(sectionKey, sectionIndex)
                          }
                          isDarkMode={isDarkMode}
                        />
                      )}

                      {openDeleteSectionModal[sectionIndex] && (
                        <DeleteSectionModal
                          sectionKey={sectionKey}
                          sectionIndex={sectionIndex}
                          handleDeleteSectionModal={() =>
                            handleDeleteSectionModal(sectionKey, sectionIndex)
                          }
                          isDarkMode={isDarkMode}
                        />
                      )}

                      <div className=" max-w-screen-xl md:w-full w-[100vw] justify-between flex md:flex-row flex-col pt-4">
                        <div className="md:w-full w-[100vw] bg-white md:rounded-md h-fit p-4 border-[1px] ">
                          <div className="flex justify-between items-center px-2">
                            <div className="text-lg text-black pb-2 font-[550]">
                              {sectionKey}
                            </div>
                            {sectionKey !== "Resume" ? (
                              // <FaEdit size={15}
                              //   className="text-black py-1 px-4 rounded-3xlcursor-pointer flex items-center justify-center hover:bg-red-200"
                              //   onClick={() => handleDeleteSectionModal(sectionKey,sectionIndex)}

                              // />
                              <div
                                className=" p-2 bg-white rounded-full cursor-pointer hover:bg-[#f0f1f4]"
                                onClick={() =>
                                  handleEditSectionModal(
                                    sectionKey,
                                    sectionIndex
                                  )
                                }
                              >
                                <LuPencilLine />
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>

                          {showdoc[sectionKey].length !== 0 && (
                            <Table
                              className={` w-full divide-y border-[1px]  divide-gray-200 font-[550]${
                                isDarkMode ? "bg-[#3b4354]" : "bg-white"
                              }`}
                            >
                              <thead>
                                <tr
                                  className={`h-[3rem] ${
                                    isDarkMode
                                      ? "text-gray-100"
                                      : "text-black bg-gray-100"
                                  }`}
                                >
                                  <th
                                    className={`min-w-[50px] font-[550] w-1/4 text-left px-2 text-base ${
                                      isDarkMode
                                        ? "text-gray-100"
                                        : "text-black"
                                    }`}
                                  >
                                    Name
                                  </th>
                                  {/* <th
                                  className={`min-w-[300px] w-1/6 text-left text-base ${
                                    isDarkMode ? "text-gray-100" : "text-black"
                                  }`}
                                >
                                  Name of the File Uploaded
                                </th> */}
                                  <th
                                    className={`min-w-[50px] font-[550] w-1/4 text-left text-base ${
                                      isDarkMode
                                        ? "text-gray-100"
                                        : "text-black"
                                    }`}
                                  >
                                    Created on{" "}
                                  </th>
                                  <th
                                    className={`min-w-[50px] w-1/4 text-base ${
                                      isDarkMode
                                        ? "text-gray-100"
                                        : "text-black"
                                    }`}
                                  ></th>
                                </tr>
                              </thead>
                              <tbody className="divide-gray-25 divide-y">
                                {showdoc[sectionKey].map(
                                  (document, documentIndex) =>
                                    document && (
                                      <tr
                                        key={documentIndex}
                                        className={`h-[3rem] border-b-[1px]  ${
                                          isDarkMode
                                            ? "bg-[#3b4354] text-gray-100"
                                            : "bg-white text-black hover:bg-gray-100"
                                        }`}
                                      >
                                        <td>
                                          <div className="w-full h-full flex font-[550] items-center text-sm justify-start px-2">
                                            {document.Name}
                                          </div>
                                        </td>
                                        {/* <td>
                                        <div className="w-full h-full flex items-center justify-start ">
                                          {document.File}{" "}
                                        </div>{" "}
                                      </td> */}
                                        <td>
                                          <div className="w-full h-full flex text-sm items-center justify-start ">
                                            {new Date(
                                              document.CreatedDate
                                            ).toLocaleDateString()}
                                          </div>
                                        </td>

                                        <td>
                                          <div className="flex justify-end">
                                            {openDeleteModal[sectionKey][
                                              documentIndex
                                            ] && (
                                              <DeleteDocumentModal
                                                handleDeleteModal={() =>
                                                  handleDeleteModal(
                                                    sectionKey,
                                                    documentIndex
                                                  )
                                                }
                                                isDarkMode={isDarkMode}
                                                section={sectionKey}
                                                docname={document.Name}
                                                token={token}
                                              />
                                            )}
                                            <div className="w-full h-[3rem]  flex items-center justify-end pr-4">
                                              {showdoc.Name !== " " && (
                                                <div className="flex gap-2 items-center ">
                                                  {/* {sectionKey === "Resume" ? (
                                                    activeResume ===
                                                    document.Link ? (
                                                      <div className="text-green-500 flex gap-1 items-center font-[550]">
                                                        <FaFileCircleCheck />
                                                      </div>
                                                    ) : (
                                                      <div>
                                                        <div
                                                          className="border-green-500 md:block hidden cursor-pointer text-green-500 hover:bg-green-200 border py-1 px-3 rounded-3xl"
                                                          onClick={() =>
                                                            makeactive(
                                                              document.Link
                                                            )
                                                          }
                                                        >
                                                          <div className="md:text-sm text-xs hidden md:block">
                                                            <p>Make Active</p>
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="bg-green-500 md:hidden block cursor-pointer hover:bg-green-600 text-white py-1 px-4 rounded-3xl"
                                                          onClick={() =>
                                                            makeactive(
                                                              document.Link
                                                            )
                                                          }
                                                        >
                                                          <FaFileCircleCheck />
                                                        </div>
                                                      </div>
                                                    )
                                                  ) : (
                                                    <div className="flex gap-1 items-center font-[550]"></div>
                                                  )} */}
                                                  <div className="text-[#0052ff] duration-300 cursor-pointer py-1 px-4 border rounded-3xl border-[#0052ff] hover:bg-blue-200">
                                                    <a
                                                      className="md:text-sm text-xs hidden md:block"
                                                      href={document.Link}
                                                      target="_blank"
                                                    >
                                                      {/* <FiDownload /> */}
                                                      <p>Download</p>
                                                    </a>
                                                    <a
                                                      className="block md:hidden"
                                                      href={document.Link}
                                                      target="_blank"
                                                    >
                                                      <FiDownload className="text-[#0052ff]" />
                                                    </a>
                                                  </div>

                                                  <div
                                                    className="text-red-500 border border-red-500 py-1 px-4 rounded-3xl hover:bg-red-100 cursor-pointer"
                                                    onClick={() =>
                                                      handleDelete(
                                                        sectionKey,
                                                        document,
                                                        documentIndex
                                                      )
                                                    }
                                                  >
                                                    <div className="md:text-sm text-xs hidden md:block">
                                                      {/* <RiDeleteBin6Line /> */}
                                                      <p>Delete</p>
                                                    </div>
                                                    <div className="block md:hidden">
                                                      <RiDeleteBin6Line className="text-red-500" />
                                                    </div>
                                                  </div>
                                                  {/* <div
                                                  className="flex items-center"
                                                  onClick={() => {
                                                    setDropDown(true);
                                                  }}
                                                >
                                                  <PiDotsThreeOutlineDuotone
                                                    size={20}
                                                  />
                                                </div> */}
                                                  {/* {dropDown && (
                                                  <div className="absolute z-10 mt-1 bg-white border max-h-[150px] overflow-y-auto rounded-md w-[100%] shadow-lg">
                                                    <ul>
                                                      <li>
                                                        Download
                                                      </li>
                                                      <li>
                                                        Delete
                                                      </li>
                                                    </ul>
                                                  </div>
                                                )} */}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                        {/* <td className="">
                                      <PiDotsThreeOutlineDuotone size={20}/>
                                      </td> */}
                                      </tr>
                                    )
                                )}
                              </tbody>
                            </Table>
                          )}

                          <div>
                            <div
                              className="w-fit text-[#0052ff] duration-300 cursor-pointer mt-2 py-1 px-4 border rounded-3xl border-[#0052ff] hover:bg-blue-200 "
                              onClick={() => handleModal(sectionIndex)}
                            >
                              <div className="md:text-sm text-xs">
                                {/* <IoIosAddCircleOutline /> */}
                                <p>Add Document</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* <div className="md:w-[30%] w-[100%] h-full md:mt-0 my-2  pt-2">
              <div className="w-full my-2 p-2 md:rounded-md bg-white border-[1px] shadow-md ">
                <div className="px-2">
                  {" "}
                  <div className="text-lg pb-4 font-[550]">
                    Document Storage
                  </div>
                  <LineProgress progress={(documentsSize / 10) * 100}>
                    <LineProgress.Text className="text-xs"></LineProgress.Text>
                  </LineProgress>
                  <div className="text-sm text-gray-500">
                    {documentsSize} MB/10 MB
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 ">
                <div className="bg-white border rounded-xl">
                  <div className="text-lg ">Document Storage</div>
                  <br />
                  <div className="flex justify-center">
                    <div className="w-[75%] px-4">
                      <LineProgress progress={(documentsSize / 10) * 100}>
                        <LineProgress.Text className="text-xs">
                          {documentsSize} MB/10 MB
                        </LineProgress.Text>
                      </LineProgress>
                    </div>
                  </div>
                </div>
                <div className="bg-white border rounded-xl">
                  <div className="text-lg">Number of Sections</div>
                  <br />
                  <div className="pb-4 text-center text-2xl ">
                    {Object.keys(showdoc).length}
                  </div>
                </div>
                <div className="bg-white border rounded-3xl flex gap-3 ">
                  <div className="p-5 text-lg">Documents per section</div>
                  <div className="flex justify-center p-4">
                    <PieChart data={documents} />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {!loading && (
            <div className="flex flex-row w-full md:w-1/4 my-2 gap-1 py-4">
              <div className="flex flex-col w-full gap-1">
                <LineProgress
                  progress={(documentsSize / 10) * 100}
                  className="bg-[#0052ff] "
                />
                <div className="flex flex-col gap-1 text-xs text-gray-500">
                  <div>{documentsSize} MB of 10 MB used</div>
                  {/* <div className="text-decoration underline">
                  Upgrade
                </div> */}
                </div>
              </div>
              {/* <div className="md:block hidden bg-[#0052ff] text-white mt-2 w-fit cursor-pointer  hover:bg-[#014cec] py-1 px-4 rounded-3xl">
              <p className="flex gap-2 items-center md:text-sm text-xs">Upgrade <GiUpgrade size={17}/></p>
              
            </div> */}
            </div>
          )}
        </div>
      </div>
    );
}
