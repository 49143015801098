import React, { useEffect, useState } from "react";
import { LuPencilLine } from "react-icons/lu";
import EditUrlModal from "./EditUrlModal";
import EditCurrLoc from "./EditCurrLoc";
import { CircleProgress } from "keep-react";
import project from "../images/Defaultproject.png";
import education from "../images/DefaultEducation.png";
import experience from "../images/DefaultCompany.png";
import skill from "../images/DefaultSkills.png";
import award from "../images/DefaultAwards.png";
import certificate from "../images/DefaultCertificate.png";
import volunteer from "../images/DefaultVolunteer.png";
import defaultDp from "../asserts/dummyDp.png";
import apiUrl from "../apiConfig";
import axios from "axios";

export default function URLComponent({
  urlsubmit,
  curraddsubmit,
  isDarkMode,
  url,
  curradd,
  publicview,
  api,
  setapi,
}) {
  const token = localStorage.getItem("token");
  const [progress, setprogress] = useState(0);
  const [unfilledsecs, setunfilledsecs] = useState([]);
  const svgs = {
    "Profile picture": <img src={defaultDp} className="size-3" alt="" />,
    Headline: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-3 "
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
        />
      </svg>
    ),
    Summary: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-3 "
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
        />
      </svg>
    ),
    Education: <img src={education} className="size-3" alt="" />,
    Experience: <img src={experience} className="size-3" alt="" />,
    Project: <img src={project} className="size-3" alt="" />,
    Skill: <img src={skill} className="size-3" alt="" />,
  };
  const svgss = [
    "profilepic",
    "currentRole",
    "summary",
    "education",
    "experience",
    "projects",
    "skills",
  ];

  useEffect(() => {
    const unfilledsec = async () => {
      try {
        const response = await axios.post(
          apiUrl.profile.getprofilesectionsstatus + token
        );
        setunfilledsecs(response.data.sections);

        setprogress(response.data.progress);
      } catch (err) {
        console.log(err);
      }
    };
    unfilledsec();
  }, [api]);

  const [viewediturl, setviewediturl] = useState(false);
  const handleviewurlModel = () => {
    setviewediturl(!viewediturl);
  };
  const [viewEditCurrLoc, setviewEditCurrLoc] = useState(false);
  const handleEditCurrLocModel = () => {
    setviewEditCurrLoc(!viewEditCurrLoc);
  };

  // // // console,log(connectionres)
  return (
    <div>
      <div className="">
        {!publicview && progress !== 100 && (
          <div
            className={`w-full p-2 border-[1px]  md:rounded-md mb-4    ${
              isDarkMode ? "bg-[#3b4354] text-white" : "bg-white"
            }`}
          >
            <div className="font-[550] text-lg">Profile Status</div>
            <div className="flex gap-2 lg:items-center lg:flex-row flex-row md:flex-col  px-2 py-2 ">
              <div className="">
                <CircleProgress
                  progress={progress}
                  className=" "
                  strokeColor="#0052ff"
                  strokeWidth={4}
                ></CircleProgress>
              </div>  
              <div>
                <div className="grid grid-cols-2 gap-1 gap-x-4 ">
                  {Object.keys(svgs).map((key, index) => {
                    return (
                      <div key={index} className={` flex items-center gap-1`}>
                        {unfilledsecs.includes(svgss[index]) ? (
                          svgs[key]
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-3 text-green-500"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                          </svg>
                        )}
                        <div className="text-[0.75rem] block truncate">
                          {key}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="">
        {!publicview && (
          <div
            className={`w-full p-2 border-[1px]  md:rounded-md    ${
              isDarkMode ? "bg-[#3b4354] text-white" : "bg-white"
            }`}
          >
            <div>
              {viewediturl && (
                <EditUrlModal
                  url={url}
                  urlsubmit={urlsubmit}
                  viewediturl={viewediturl}
                  setviewediturl={setviewediturl}
                  handleviewurlModel={handleviewurlModel}
                  isDarkMode={isDarkMode}
                />
              )}
              {viewEditCurrLoc && (
                <EditCurrLoc
                  viewEditCurrLoc={viewEditCurrLoc}
                  curraddsubmit={curraddsubmit}
                  setviewEditCurrLoc={setviewEditCurrLoc}
                  handleEditCurrLocModel={handleEditCurrLocModel}
                  isDarkMode={isDarkMode}
                  curradd={curradd}
                />
              )}
              <div>
                <div>
                  <div className="  flex justify-between px-2 items-center">
                    {" "}
                    <div className="font-[550] text-lg">Your Profile URL</div>
                    <div
                      className=" p-2 hover:bg-gray-300 cursor-pointer duration-300 rounded-full"
                      onClick={handleviewurlModel}
                    >
                      <LuPencilLine size={20} />
                    </div>
                  </div>
                  <div>
                    <div
                      className="px-2 mx-2 text-sm text-gray-500"
                      style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      aspireup.io/{url}
                    </div>
                  </div>
                </div>
                {/* <hr />
              <div>
                <div className="text-lg flex justify-between p-2 items-center">
                  {" "}
                  <div className="font-[550]">Your Current Location</div>

                  
                  <div
                    className=" p-2 hover:bg-gray-300 cursor-pointer duration-300 rounded-full"
                    onClick={handleEditCurrLocModel}
                  >
                    <LuPencilLine size={20} />
                  </div>
                </div>
                <div>
                  <div className="px-2 text-sm text-gray-500">
                    <div>{curradd.streetAddress}</div>
                    <div>{curradd.society}</div>
                    <div>{curradd.city}</div>
                    <div>
                      {curradd.country} {curradd.pincode}
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
