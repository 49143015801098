import { IoMdClose, IoMdInformationCircleOutline } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { TextInput } from "keep-react";
import { FaSpinner } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import apiUrl from "../apiConfig";
import { toast } from "react-toastify";
import DeleteSectionModal from "./DeleteSectionModal";

export default function EditSectionModal(props) {
  const [val, setVal] = useState(props.sectionKey);
  const sectionKey = props.sectionKey;
  const [open, setOpen] = useState(true);
  const isDarkMode = props.isDarkMode;
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState("")
  const closemodal = () => {
    props.handleEditSectionModal(props.sectionKey, props.sectionIndex);
  };
  const token = localStorage.getItem("token");

  const handleUpdateSection = async (data) => {
    setLoading(true)
    // // // console,log("edit section initiated");
    // // // console,log(data);
    if(data.sectionName === props.sectionKey){
      seterror("New name cannot be previous name");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(apiUrl.documents.editsection + token, {
        section: sectionKey,
        val: data.sectionName,
      });
      if (response.status === 200) {
        // alert("changed")
        closemodal();
      }
    } catch (err) {
      // // // console,log("Error updating section:", err);
      seterror(err.response.data.message);
      setLoading(false);
    }
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closemodal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
            <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                  <div
                    className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                      isDarkMode
                        ? "bg-[#2a303c] border-[#2a303c]"
                        : "bg-white text-black"
                    }`}
                  >
                    <div className="flex w-full items-end justify-between py-2 cursor-pointer mb-1">
                      <div className="text-2xl font-[550]">Edit Section</div>
                      <IoMdClose
                        size={30}
                        onClick={closemodal}
                        className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                      />
                    </div>
                    {/* <p className="pb-2">
                      Edit the name of this section to better reflect its
                      content or purpose. Enter the new section name below and
                      click confirm to save the changes.
                    </p> */}

                    <form onSubmit={handleSubmit(handleUpdateSection)}>
                      <div className="pb-2">
                        {/* Register the input field with useForm */}
                        {/* <TextInput
                    id="input"
                    placeholder="Section name"
                    color="gray"
                    {...register("sectionName", { required: true })}
                  /> */}
                         <label className="font-[550] ">Name</label>
                        <input
                          className={`${error && "border-red-500"} w-full p-2 bg-white focus:outline-none text-black border-[1.5px] border-black rounded-md `}
                          placeholder="Section name"
                          defaultValue={val}
                          onChange={(e) => setVal(e.target.value)}
                          {...register("sectionName", {
                            required: "Please enter the Section name.",
                          })}
                        />
                            {
                        error && <div className="text-red-500 flex gap-1 items-center"><IoMdInformationCircleOutline/> {error}</div>
                      }
                      </div>
                      <div className="flex py-2 justify-between">
                        <button
                          type="button"
                          className="bg-red-500 text-white hover:bg-red-600 cursor-pointer font-[550] py-1 px-4 rounded-3xl my-2 md:my-0"
                          onClick={() => {
                            props.handleeditclosedeleteopen(
                              props.sectionKey,
                              props.sectionIndex
                            );
                          }}
                        >
                          Delete
                        </button>
                        {loading ? (
                          <FaSpinner
                            size={30}
                            className="animate-spin text-[#0052ff] mr-2"
                          />
                        ) : (
                          <button
                            type="submit"
                            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-2 md:my-0"
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
