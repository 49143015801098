import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Checkbox, Label } from "keep-react";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiUrl from "../../apiConfig";

export default function RemoveConnectionModal({
    handleRemoveConnectionModal,handleOpenTagModal,token,selectedUser,disconnect, tag}) {


        const handleDisconnect = () =>{
            disconnect(selectedUser.url)
            handleRemoveConnectionModal()
        }
  return (
    <Transition.Root show={true} as={Fragment}>
    <Dialog
      as="div"
      className="relative z-10"
      onClose={handleRemoveConnectionModal}
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
        <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
              <div className="md:w-[40vw]  w-[100%] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4 bg-white text-black">
                <div>
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="text-2xl capitalize font-[550]">{tag? "Untag " + selectedUser.Name: "Remove Connection"}</div>

                    <IoMdClose
                      size={30}
                      onClick={handleRemoveConnectionModal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  <div>
                      <div className="">
                        <p className="text-body-4 pt-4 leading-relaxed ">
                        Are you sure you want to remove <span className="font-[550]">{selectedUser.Name}</span> from your {tag? tag + " tag" :"connections"}?
                        </p>
                      </div>
                    </div>
                  <div className="flex justify-end my-2">
                    <button
                      className="bg-red-500 text-white px-3 py-1 font-[550] border rounded-3xl hover:bg-red-600 my-2 md:my-0"
                      onClick={handleDisconnect}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
  )
}
