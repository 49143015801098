import React, { useEffect } from "react";
import apiUrl from "../../apiConfig";
import axios from "axios";
import { useState } from "react";
import ProfileTile from "./ProfileTile";
import DefaultCompany from "../../images/DefaultCompany.png";
import DefaultEducation from "../../images/DefaultEducation.png";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import RemoveConnectionModal from "./RemoveConnectionModal";

export default function SelectedTagUsers({
  activetag,
  setActivetag,
  refresh,
  setrefresh,
  currentUserId,
}) {
  const token = localStorage.getItem("token");
  const [tagUsers, setTagUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(apiUrl.companies.getcompanies);
        setCompanies(response.data);
      } catch (error) {
        // console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const [universities, setUniversities] = useState([]);
  useEffect(() => {
    // Fetch university names from the backend when the component mounts

    const fetchUniversities = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        // // // console,log(response.data);
        setUniversities(response.data);
        // setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversities();
  }, []);

  const findimage = (name, type) => {
    const company = companies.find((obj) => obj.Name === name);
    const comimage = company ? company.s3Link : DefaultCompany;
    const university = universities.find((obj) => obj.Name === name);
    const schimage = university ? university.s3Link : DefaultEducation;
    return type === "job" ? comimage : schimage;
  };

  const handleUntag = async (url) => {
    try {
      const response = await axios.post(apiUrl.tags.untaguser + token, {
        TagName: activetag.TagName,
        url: url,
      });
      if (response.status === 200) {
        // Update the state correctly by filtering out the untagged user
        setTagUsers((prevTagUsers) =>
          prevTagUsers.filter((user) => user.url !== url)
        );
      }
    } catch (error) {
      // console,log(error);
    }
  };

  function encrypt(string1, string2, shift) {
    // Use a unique delimiter that won't appear in the original strings
    const delimiter = "|";

    // Concatenate the two strings with the delimiter
    let concatenatedString = string1 + delimiter + string2;

    // Encrypt the concatenated string with a shift cipher
    let encryptedString = "";

    for (let i = 0; i < concatenatedString.length; i++) {
      let charCode = concatenatedString.charCodeAt(i);

      // Shift the character code
      if (charCode >= 65 && charCode <= 90) {
        // Uppercase letters
        encryptedString += String.fromCharCode(
          ((charCode - 65 + shift) % 26) + 65
        );
      } else if (charCode >= 97 && charCode <= 122) {
        // Lowercase letters
        encryptedString += String.fromCharCode(
          ((charCode - 97 + shift) % 26) + 97
        );
      } else {
        encryptedString += concatenatedString[i]; // Non-alphabetical characters
      }
    }

    return encryptedString;
  }

  const navigateToChats = async (item) => {
    // console,log(item, currentUserId);
    try {
      const response = await axios.post(apiUrl.getuserdetails + token);
      if (response.status === 200) {
        const chaturl = encrypt(item.UserId, response.data.existingUser._id, 7);
        navigate("/chats/" + chaturl);
      }
    } catch (err) {}
  };

  const disconnect = async (url) => {
    try {
      const resp = await axios.post(apiUrl.connections.disconnect + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        setTagUsers((prevTagUsers) =>
          prevTagUsers.filter((user) => user.url !== url)
        );
        setrefresh(!refresh);
      }
    } catch (err) {
      // // console,log("err", err.message);
    }
  };

  useEffect(() => {
    // console,log(activetag);
    const users = activetag.TagUsers;
    const fetchTagUsers = async (id) => {
      try {
        // console,log(id);
        const response = await axios.post(apiUrl.profile.getprofilefromid + id);

        const newProfile = {
          profilepic: response.data.profile.Profilepic,
          Name: response.data.profile.FullName.DisplayName,
          currentRole: response.data.profile.currentRole,
          latestEducation: response.data.profile.LatestEducation,
          latestExperience: response.data.profile.LatestExperience,
          Location: response.data.profile.Location,
          url: response.data.url,
          UserId: id,
        };
        // console,log(newProfile);
        setTagUsers((prev) => [...prev, newProfile]);
      } catch (error) {
        // console,log(error);
      }
    };
    for (let i = 0; i < users.length; i++) {
      fetchTagUsers(users[i].UserId);
    }
  }, []);

  const [openRemoveConnectionModal, setopenRemoveConnectionModal] =
    useState(false);
  const handleRemoveConnectionModal = () => {
    setopenRemoveConnectionModal(!openRemoveConnectionModal);
  };
  const [openRemoveTagModal, setopenRemoveTagModal] = useState(false);
  const handleRemoveTagModal = () => {
    setopenRemoveTagModal(!openRemoveTagModal);
  };

  const [selectedUser, setselectedUser] = useState(null);
  return (
    <div className="bg-white w-full border-[1px]  md:rounded-md p-4">
      {openRemoveConnectionModal && (
        <RemoveConnectionModal
          handleRemoveConnectionModal={handleRemoveConnectionModal}
          token={localStorage.getItem("token")}
          selectedUser={selectedUser}
          disconnect={disconnect}
        />
      )}
      {openRemoveTagModal && (
        <RemoveConnectionModal
          handleRemoveConnectionModal={handleRemoveTagModal}
          token={localStorage.getItem("token")}
          selectedUser={selectedUser}
          disconnect={handleUntag}
          tag={activetag.TagName}
        />
      )}
      <div
        className=" flex items-center mb-4 cursor-pointer w-fit"
        onClick={() => setActivetag(null)}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </div>

        <h1 className=" text-xl font-[550] inline-block">
          {activetag.TagName}
        </h1>
      </div>

      {tagUsers.length !== 0 ? (
        tagUsers.map((user, index) => (
          <div
            key={index}
            className={` ${
              index === tagUsers.length - 1 ? "" : "border-b-[1px]"
            } p-2 h-full flex md:flex-row flex-col justify-between md:items-start py-2 w-full`}
          >
            <div className=" flex justify-between w-full">
              <ProfileTile user={user} findimage={findimage} />
              <div className="md:hidden ">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                        />
                      </svg>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className={`absolute ${"right-0"}  z-10 w-40 origin-bottom-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    >
                      <div
                        className="py-1"
                        onClick={() => {
                          setselectedUser(user);
                          handleRemoveTagModal();
                        }}
                      >
                        <Menu.Item>
                          <div className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                            Untag
                          </div>
                        </Menu.Item>
                      </div>
                      <div
                        className="py-1"
                        onClick={() => {
                          setselectedUser(user);
                          handleRemoveConnectionModal();
                        }}
                      >
                        <Menu.Item>
                          <div className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                            Remove Connection
                          </div>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>

            <div className=" py-3 flex gap-3">
              <button
                className="duration-300 font-[550] h-fit cursor-pointer py-[0.125rem] px-3 border rounded-3xl md:text-sm text-xs text-[#0052ff] border-[#0052ff] hover:bg-[#80808025]"
                onClick={() => navigateToChats(user)}
              >
                <div className="flex gap-1 items-center ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                    />
                  </svg>
                  <div className=" md:hidden ">Message</div>
                </div>
              </button>
              <div className="md:flex hidden">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                        />
                      </svg>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className={`absolute ${"right-0"}  z-10 w-40 origin-bottom-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    >
                      <div
                        className="py-1"
                        onClick={() => {
                          setselectedUser(user);
                          handleRemoveTagModal();
                        }}
                      >
                        <Menu.Item>
                          <div className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                            Untag
                          </div>
                        </Menu.Item>
                      </div>
                      <div
                        className="py-1"
                        onClick={() => {
                          setselectedUser(user);
                          handleRemoveConnectionModal();
                        }}
                      >
                        <Menu.Item>
                          <div className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                            Remove Connection
                          </div>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className=" text-base font-[550] text-center w-full  pb-2">
          no users tagged{" "}
        </div>
      )}
    </div>
  );
}
