const skills = [
  "Java",
  "Python",
  "JavaScript",
  "C++",
  "C",
  "Data Structures & Algorithms",
  "Object-Oriented Programming (OOP)",
  "Full Stack Development",
  "Git",
  "System Design",
  "MySQL",
  "PostgreSQL",
  "React.js",
  "Node.js",
  "Oracle"
];

export default skills;
