import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Months from "../asserts/Months";
import apiUrl from "../apiConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import countriesList from "../asserts/Countrylist";

export default function ChangePersonalDetails({ url }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectedCountry, setselectedCountry] = useState("");
  const [filteredCounties, setfilteredCounties] = useState([]);
  const [submittedData, setSubmittedData] = useState(null);
  const navigate = useNavigate();
  const [datevalue, setDateValue] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [userphonenumber, setUserPhoneNumber] = useState("");
  const [savedMessage, setSavedMessage] = useState("");
  const [userdata, setUserData] = useState({
    FullName: {
      FirstName: "",
      MiddleName: "",
      LastName: "",
      DisplayFirstName: "",
      DisplayMiddleName: "",
      DisplayLastName: "",
    },
    Location: {
      City: "",
      Country: "",
      PinCode: "",
      StreetAddress: "",
    },
    Survey: {
      "What is your gender identity?": "male",
      "What is your race? (Select all that apply)": {
        Asian: true,
        "Native Hawaiian or Pacific Islander": false,
        "Black or African American": false,
        White: false,
        "Hispanic or Latinx": false,
        "Not listed": false,
        "Native American or Alaskan Native": false,
      },
      "What is your sexual orientation?": " ",
      "What is your age range?": " ",
      "What is your military status?": " ",
    },
  });
  const token = localStorage.getItem("token");
  const [gender, setGender] = useState("male");


  const handleGenderChange = (selectedGender) => {
    setGender(selectedGender);
  };

  const submitform = async (data) => {
    const formbody = {
      FullName: {
        FirstName: data.FirstName || userdata.FullName.FirstName,
        MiddleName: data.MiddleName || userdata.FullName.MiddleName,
        LastName: data.LastName || userdata.FullName.LastName,
        DisplayFirstName: userdata.FullName.DisplayFirstName,
        DisplayMiddleName: userdata.FullName.DisplayMiddleName,
        DisplayLastName: userdata.FullName.DisplayLastName,
        DisplayName : (data.FirstName || userdata.FullName.FirstName) && (data.LastName || userdata.FullName.LastName) ? (data.FirstName || userdata.FullName.FirstName) + " " + (data.LastName || userdata.FullName.LastName) : (data.FirstName || userdata.FullName.FirstName) || userdata.FullName.FirstName
      },
      Location: {
        City: data.City || userdata.Location.City,
        Country: selectedCountry || userdata.Location.Country,
        society: data.society || userdata.Location.society,
        PinCode: data.PinCode || userdata.Location.PinCode,
        StreetAddress: data.StreetAddress || userdata.Location.StreetAddress,
      },
      Survey: {
        "What is your gender identity?": gender,
        "What is your race? (Select all that apply)": {
          Asian: true,
          "Native Hawaiian or Pacific Islander": false,
          "Black or African American": false,
          White: false,
          "Hispanic or Latinx": false,
          "Not listed": false,
          "Native American or Alaskan Native": false,
        },
        "What is your sexual orientation?": " ",
        "What is your age range?": " ",
        "What is your military status?": " ",
      },
      PhoneNumber: data.PhoneNumber || userphonenumber,
      DOB: data.DOB || userdata.DOB,
    };
    setSubmittedData(data);

    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        formbody
      );

      if (response.status == 200) {
        // navigate("/" + url);
        setSavedMessage("All changes saved successfully");
      }
    } catch (error) {
      // // //console.log(error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(
          apiUrl.profile.getprofile + `${token}`
        );

        if (response.status == 200) {
          setUserPhoneNumber(response.data.existing_user.phone);

          setUserData(response.data.existing_profile);
          setselectedCountry(response.data.existing_profile.Location.Country);
          setGender(
            response.data.existing_profile.Survey[
              "What is your gender identity?"
            ]
          );
        }
      } catch (error) {
        // // //console.log(error);
      }
    };
    fetchUserData();
  }, [savedMessage]);

  const handleCityChange = (e) => {
    const inputCity = e.target.value;
    setselectedCountry(inputCity);
    // Filter cities based on input (starts with or includes)
    const filteredStartsWith = countriesList.filter((obj) =>
      obj.toLowerCase().startsWith(inputCity.toLowerCase())
    );

    const filteredIncludes = countriesList.filter((obj) =>
      obj.toLowerCase().includes(inputCity.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filteredStartsWith.concat(filteredIncludes));

    // Convert back to array
    const filtered = Array.from(uniqueItemsSet);

    setfilteredCounties(filtered);
  };
  const handleCitySelect = (city) => {
    setselectedCountry(city);
    setfilteredCounties([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click occurs outside of it
      if (filteredCounties.length > 0) {
        const dropdown = document.getElementById("country");
        if (dropdown && !dropdown.contains(event.target)) {
          setfilteredCounties([]);
        }
      }
    };

    const handleEscapeKey = (event) => {
      // Close the dropdown if the "Escape" key is pressed
      if (event.key === "Escape") {
        setfilteredCounties([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [filteredCounties]);

  return (
    <div className="bg-white w-full  rounded-xl p-4">
      <h1 className=" text-xl font-[550]">Personal Details</h1>
      <form onSubmit={handleSubmit(submitform)} className="">
        <div className="mt-2">
          <label htmlFor="FirstName" className="font-[550]">
            First Name
          </label>
          <input
            type="text"
            defaultValue={userdata.FullName.FirstName}
            placeholder="First Name"
            className="w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-1"
            name="FirstName"
            required
            {...register("FirstName", {
              maxLength: {
                value: 100,
                message: "First name must not exceed 100 characters",
              },
              pattern: {
                value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                message:
                  "First name can only contain alphanumeric characters, spaces, underscores, and hyphens",
              },
            })}
          />
          {errors.FirstName && (
            <p className="text-red-500 text-sm">{errors.FirstName.message}</p>
          )}
        </div>
        {/* <div className="mt-2">
          <label htmlFor="FirstName" className="font-[550]">
            Middle Name
          </label>
          <input
            type="text"
            defaultValue={userdata.FullName.MiddleName}
            placeholder="Middle Name"
            className="w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-1"
            name="FirstName"
            {...register("MiddleName")}
          />
        </div> */}
        <div className="mt-2">
          <label htmlFor="LastName" className="font-[550]">
            Last Name
          </label>
          <input
            type="text"
            defaultValue={userdata.FullName.LastName}
            placeholder="Last Name"
            className="w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-1"
            name="Last Name"
            required
            {...register("LastName", {
              maxLength: {
                value: 100,
                message: "Last name must not exceed 100 characters",
              },
              pattern: {
                value:
                  /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                message:
                  "Last name can only contain alphanumeric characters, spaces, underscores, and hyphens",
              },
            })}
          />
          {errors.LastName && (
            <p className="text-red-500 text-sm">{errors.LastName.message}</p>
          )}

        </div>
        <div className="mt-2">
          <label htmlFor="PhoneNumber" className="font-[550]">
            Phone Number
          </label>
          <input
            type="tel"
            placeholder="Phone Number"
            defaultValue={userphonenumber}
            className="w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-1"
            name="PhoneNumber"
            required
            {...register("PhoneNumber")}
          />
        </div>
        <div className="mt-2">
          <label htmlFor="Gender" className="font-[550]">
            Gender
          </label>
          <br />
          <div className="flex gap-4 px-2">
            <input
              type="checkbox"
              name="male"
              id="male"
              checked={gender === "male"}
              onChange={() => handleGenderChange("male")}
            />
            <label>Male</label>
          </div>
          <div className="flex gap-4 px-2">
            <input
              type="checkbox"
              name="female"
              id="female"
              checked={gender === "female"}
              onChange={() => handleGenderChange("female")}
            />
            <label>Female</label>
          </div>
          <div className="flex gap-4 px-2">
            <input
              type="checkbox"
              name="others"
              id="others"
              checked={gender === "others"}
              onChange={() => handleGenderChange("others")}
            />
            <label>Other</label>
          </div>
        </div>

        <div className="flex flex-col py-2">
          <label htmlFor="date" className="font-[550] pb-1">
            Date of Birth
          </label>
          <input
            type="date"
            id="date"
            defaultValue={userdata.DOB}
            name="date"
            {...register("DOB")}
            className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mt-2">
          <label htmlFor="StreetAddress" className="font-[550]">
            Street Address
          </label>
          <input
            type="text"
            defaultValue={userdata.Location.StreetAddress}
            name="StreetAddress"
            className="w-full p-2    border-[1.5px]  rounded-md my-1"
            {...register("StreetAddress")}
          />
        </div>
        <div className="mt-2">
          <label htmlFor="StreetAddress" className="font-[550]">
            Community Name
          </label>
          <input
            type="text"
            defaultValue={userdata.Location.society}
            name="StreetAddress"
            className="w-full p-2    border-[1.5px]  rounded-md my-1"
            {...register("society")}
          />
        </div>
        <div className="mt-2">
          <label htmlFor="City" className="font-[550]">
            City
          </label>
          <input
            type="text"
            defaultValue={userdata.Location.City}
            name="City"
            className="w-full p-2    border-[1.5px]  rounded-md my-1"
            {...register("City")}
          />
        </div>
        <div className="mt-2">
          <label htmlFor="PinCode" className="font-[550]">
            Pin Code
          </label>
          <input
            type="number"
            defaultValue={userdata.Location.PinCode}
            name="PinCode"
            className="w-full p-2    border-[1.5px]  rounded-md my-1"
            {...register("PinCode")}
          />
        </div>
        <div className="mt-2">
          <label htmlFor="Country" className="font-[550]">
            Country
          </label>
          <div className="w-[100%] relative">
            <div>
              <input
                type="text"
                className={`w-full p-2 border-[1.5px] rounded-md my-2 `}
                name="city"
                value={selectedCountry}
                {...register("Country")}
                onChange={handleCityChange}
              />
              {/* Dropdown menu for filtered cities */}
              {filteredCounties.length > 0 && (
                <div
                  id="country"
                  className="absolute z-10 mt-1 bg-white border w-[100%] max-h-[150px] overflow-y-auto   rounded-md shadow-lg"
                >
                  {filteredCounties.map((city, index) => (
                    <div
                      key={index}
                      className="cursor-pointer hover:bg-gray-100 p-2"
                      onClick={() => handleCitySelect(city)}
                    >
                      {city}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        {savedMessage != "" ? (
          <div className="flex items-center text-green-700 pt-2">
            <TiTick />
            {savedMessage}
          </div>
        ) : (
          <div></div>
        )}

        <div className="flex flex-row-reverse mt-2">
          <button
            type="submit"
            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl my-4 md:my-0"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}
