import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Months from "../asserts/Months";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiUrl from "../apiConfig";
import { useContext } from "react";
import { logincontext } from "../contexts/Logincontext";
import Spinnerpage from "./Spinnerpage";
export default function DeleteAccountModal({
  handleDeleteAccountModal,
  email,
  token,
}) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [
    currentuser,
    loginerror,
    UserloginStatus,
    Loginuser,
    Signupuser,
    VerifyOTP,
    VerifyForgotPasswordOTP,
    Signupadmin,
    Logoutuser,
  ] = useContext(logincontext);

  const handleDeleteAccount = async () => {
    setLoading(true);
    try {
      const response = await axios.post(apiUrl.senddeleteaccountmessage + token, {
        email: email,
      });
      if (response.status == 200) {
        // // console,log(response.data)
        // console,log("Email Sent Successfully");
      }
    } catch (error) {
      // console,log(error);
    }
    setLoading(false);
    handleDeleteAccountModal();
    Logoutuser();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={handleDeleteAccountModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform   overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div className="md:w-[40vw]  w-[100%] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4 bg-white text-black">
                  
                    <div>
                      <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                        <div className="text-2xl font-[550]">
                          Delete Account
                        </div>

                        <IoMdClose
                          size={30}
                          onClick={handleDeleteAccountModal}
                          className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                        />
                      </div>
                      <div className="">
                        {/* Send a requesting mail to service@aspireup.ai. After further verification, the account will be deleted. */}
                        By clicking the delete button, you agree to the deletion
                        of your account. Further communication will be via email
                        sent to {email}
                      </div>
                      <div className="flex justify-end my-2">
                          <button
                            className="bg-red-500 text-white px-3 py-1 font-[550] border rounded-3xl hover:bg-red-600 my-2 md:my-0"
                            onClick={handleDeleteAccount}
                          >
                            Delete
                          </button>
                      </div>
                    </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
