"use client";
import { Title } from "chart.js";
import { Accordion } from "keep-react";
import { useState } from "react";

export const Accordioncomponent = () => {
  const curriculum = [
    {
      Title: "Basic, Complexity Analysis, Bit-Manipulation, Recursion",
      Topics: [
        "Basics of programming",
        "Data types & operators",
        "Complexity Analysis of Algorithms",
        "Bit-Manipulation",
        "Modular Arithmetic",
        "Recursion",
        "Backtracking",
      ],
    },
    {
      Title: "Sorting, Searching, Hashing, Strings, Mixed-bag",
      Topics: [
        "Sorting Techniques & Applications",
        "Two pointer technique",
        "Master's Theorem",
        "Searching Techniques",
        "Applications of Binary Search",
        "Hashing",
        "Maps and Sets",
        "Arrays",
        "Strings",
        "Mixed-bag problem-solving",
        "Prefix-sum",
        "Prime Numbers",
        "Miscellaneous Concepts",
      ],
    },
    {
      Title: "Stacks, Queuesm Linked Lists, Trees, Heaps, Tries",
      Topics: [
        "Stacks and Queues",
        "Amortized Analysis of Algorithms",
        "Basics of Linked-Lists",
        "Advanced problems on LL",
        "LRU Cache",
        "Basics of Trees",
        "Binary Search Trees",
        "Advanced problems on Trees",
        "Priority Queues",
        "Trie DS and Applications",
      ],
    },
    {
      Title: "Dynamic Programming and Graph Theory",
      Topics: [
        "Basics of Dynamic Programming",
        "Subarrays and Subsequences",
        "Miscellaneous Problems on DP",
        "The Knapsack Problem",
        "Matrix DP",
        "Introduction to Graph Theory",
        "Basics of Graph Theory",
        "Important Graph Algorithms",
      ],
    },
  ];

  const [isopen, setIsopen] = useState([
    true,
    ...Array(curriculum.length - 1).fill(false),
  ]);
  const toggle = (index) => {
    setIsopen((prev) => {
      const newarr = [...prev];
      newarr[index] = !newarr[index];
      return newarr;
    });
  };

  return (
    <div>
      <Accordion flush={true} openFirstPanel={true}>
        {curriculum.map((item, key) => (
          <Accordion.Panel
            key={key}
            className=" mb-2  rounded-md border-0 bg-[#f2f2f2] "
            onClick={() => toggle(key)}
          >
            <Accordion.Container>
              <Accordion.Title className=" ">
                <div className="flex justify-start">
                  <div className="text-black font-[550]">{item.Title}</div>
                </div>
              </Accordion.Title>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={`size-6 ${
                  isopen[key] ? "transform rotate-180" : ""
                }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m19.5 8.25-7.5 7.5-7.5-7.5"
                />
              </svg>
            </Accordion.Container>
            <Accordion.Content className="bg-white rounded-b-lg pt-4">
              <ul className=" list-decimal px-4">
                {item.Topics.map((topic,k) => (
                  <li className="text-black pb-1 " key={k}>{topic}</li>
                ))}
              </ul>
            </Accordion.Content>
          </Accordion.Panel>
        ))}
      </Accordion>
    </div>
  );
};
