import React from "react";
import { BsFacebook } from "react-icons/bs";
import { BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";

export default function Footer() {
  const footercontent = {
    "Job Seekers": ["Candidate Signup", "Success Stories", "FAQ"],
    Employees: ["Employer Signup", "SuccessStories", "FAQ"],
    Company: ["Support", "About Company", "Careers"],
    Resources: [
      "Events",
      "Jobs directory",
      "Company directory",
      "Release notes",
    ],
  };
  return (
    <div className=" flex justify-center border-t-[1px] ">
      <div className="w-full md:max-w-screen-xl md:w-[100%] flex md:flex-row flex-col gap-x-4 md:p-4 p-2  text-gray-500 justify-between items-center h-12">
        <div className=" md:text-sm text-xs ">© 2024 Aspireup. All rights reserved.</div>
        <div className="flex items-center gap-1 h-full">
          <span className=" md:text-sm text-xs">Follow us on :</span>
          <a
            href="https://www.linkedin.com/company/aspireup-ai/"
            target="_blank"
          >
            <span className=" md:text-sm text-xs">
              <BsLinkedin className=" " />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}
