import React, { useState } from "react";
import { useEffect } from "react";
// import { Worker, Viewer } from '@react-pdf-viewer/pdfjs';
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import apiUrl from "../apiConfig";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
// import { toast } from "react-toastify";
import axios from "axios";
import "@react-pdf-viewer/core/lib/styles/index.css";
import AddResumeModal from "../components/AddResumeModal";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Login from "./Login";
import { useLocation } from "react-router-dom";
const ViewResume = ({ formdetails, isDarkMode, url }) => {
  const buttontextstyle = "text-xs md:text-md";
  const navigate = useNavigate();
  const [pdfUrl, setPdfUrl] = useState("");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [showaddresumemodal, setShowaddresumemodal] = useState(false);
  const Location = useLocation();

  const [viewprof, setviewprof] = useState(false);
  const handleEditResume = () => {
    setShowaddresumemodal(!showaddresumemodal);
  };
  const [pv, setpv] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const uri = Location.pathname;
        const reqbody = {
          url: uri.split("/")[1],
        };
        // // // // console,log(uri.split("/")[1])
        const response = await axios.post(
          `${apiUrl.profile.getresume}${token}`,
          reqbody,
          { responseType: "arraybuffer" }
        );
        // Create a Blob from the array buffer
        // // // console,log(response.data);
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });

        // Create a Blob URL from the response data
        const blobUrl = URL.createObjectURL(pdfBlob);
        const resp = await axios.post(
          apiUrl.profile.getprofile + token,
          reqbody
        );
        // // // console,log(resp);
        setpv(resp.data.publicview);
        setLoading(false);
        setPdfUrl(blobUrl);
      } catch (error) {
        // console.error(error);
        // Handle error, show an alert, etc.
        // toast.error("something went wrong");
      }
    };

    const fetchDatawot = async () => {
      try {
        const uri = Location.pathname;
        const reqbody = {
          url: uri.split("/")[1],
        };
        // // // console,log(reqbody);
        // // // // console,log(uri.split("/")[1])
        const response = await axios.post(
          `${apiUrl.profile.getresume}`,
          reqbody,
          { responseType: "arraybuffer" }
        );

        // Create a Blob from the array buffer
        // // // console,log(response.data);
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });

        // Create a Blob URL from the response data
        const blobUrl = URL.createObjectURL(pdfBlob);
        const resp = await axios.post(apiUrl.profile.getprofile, reqbody);
        // // // console,log(resp);
        setpv(resp.data.publicview);
        setLoading(false);
        setPdfUrl(blobUrl);
      } catch (error) {
        // // // console,log("error encountered");
        if (error.response.status === 501) {
          setviewprof(true);
        }
      }
    };
    if (token) {
      fetchData();
    } else {
      fetchDatawot();
    }
  }, [showaddresumemodal]);
  if (viewprof && token == null) {
    return <Login />;
  } else {
    return (
      <div>
        {showaddresumemodal && (
          <AddResumeModal
            showaddresumemodal={showaddresumemodal}
            setShowaddresumemodal={setShowaddresumemodal}
            isDarkMode={isDarkMode}
          />
        )}

        {!loading && (
          <div>
            <div className="flex justify-center pt-2">
              <div className="flex justify-between w-[100%] px-4  max-w-screen-xl  items-center">
                <div
                  className="flex h-full items-center text-[#0052ff] cursor-pointer "
                  onClick={() => navigate("/" + url)}
                >
                  <MdKeyboardArrowLeft size={20} />
                  <span>Go to dashboard</span>
                </div>
                {!pv && (
                  <div
                    className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] text-xs py-1 px-4 rounded-3xl my-4 md:my-0"
                    onClick={handleEditResume}
                  >
                    <div className="flex gap-1 justify-center items-center">
                      <FaEdit size={15} />
                      <p className={buttontextstyle}>Upload New Resume</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <br />
          </div>
        )}
        {loading && (
          <div className="flex justify-center items-center h-[90vh]">
            <FaSpinner className="animate-spin text-[#0052ff] mr-2" size={40} />
          </div>
        )}
        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          {pdfUrl && (
            <div style={{ width: "100%", height: "90vh" }}>
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}
              >
                <Viewer fileUrl={pdfUrl} />
              </Worker>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default ViewResume;
