import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import DeleteTagModal from "./DeleteTagModal";
import SelectedTagUsers from "./SelectedTagUsers";
import DeleteModal from "../DeleteModal";
import AddNewTagModal from "./AddNewTagModal";
import EditTagModal from "./EditTagModal";
import Tooltip, { TooltipComponent } from "../Tooltip";
export default function Tags({ refresh, setrefresh, currentUserId }) {
  const [tags, setTags] = useState([]);
  const [openDeleteTagModal, setOpenDeleteTagModal] = useState(false);
  const [openAddNewTagModal, setOpenAddNewTagModal] = useState(false);
  const [openEditTagModal, setOpenEditTagModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState({});
  const [param, setParam] = useState(false);
  const token = localStorage.getItem("token");
  const [loading, setloading] = useState(true);
  const fetchTags = async () => {
    setloading(true);
    try {
      const response = await axios.post(apiUrl.tags.gettags + token);
      if (response.status == 200) {
        setTags(response.data.tags.Tags);
      }
    } catch (error) {
      // console,log(error);
    }
    setloading(false);
  };

  useEffect(() => {
    fetchTags();
  }, [param, refresh]);

  const handleDeleteTagModal = (tag) => {
    setSelectedTag(tag);
    setOpenDeleteTagModal(!openDeleteTagModal);
  };

  const handleAddNewTagModal = () => {
    setOpenAddNewTagModal(!openAddNewTagModal);
  };

  const handleEditTagModal = (tag) => {
    setSelectedTag(tag);
    setOpenEditTagModal(!openEditTagModal);
  };

  const handleDeleteTag = async () => {
    try {
      const response = await axios.post(apiUrl.tags.deletetag + token, {
        TagName: selectedTag.TagName,
      });
      if (response.status == 200) {
        setParam(!param);
        handleDeleteTagModal();
      }
    } catch (error) {
      // console,log(error);
    }
  };

  const [activetag, setActivetag] = useState(null);
  if (activetag) {
    return (
      <SelectedTagUsers
        activetag={activetag}
        setActivetag={setActivetag}
        refresh={refresh}
        setrefresh={setrefresh}
        currentUserId={currentUserId}
      />
    );
  } else {
    return (
      <div className="bg-white w-full border-[1px]  md:rounded-md px-4 py-2">
        <div className="flex justify-between items-center mb-4">
          <h1 className=" text-lg font-[550] flex items-center gap-1">
            Tags
            <Tooltip text="Use Tags to organize your connections with custom labels! Group people by role, project, or anything you want for easier networking. Try it now!">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
            </Tooltip>
          </h1>
          <button
            className="duration-300 font-[550] cursor-pointer py-[0.125rem] px-3 border rounded-3xl md:text-sm text-xs text-[#0052ff] border-[#0052ff] hover:bg-[#80808025] h-fit"
            onClick={handleAddNewTagModal}
          >
            Add Tag
          </button>
        </div>
        {/* {openDeleteTagModal && (
          <DeleteTagModal
            handleOpenTagModal={handleDeleteTagModal}
            token={token}
            selectedTag={selectedTag}
            param={param}
            setParam={setParam}
          />
        )} */}
        {openDeleteTagModal && (
          <DeleteModal
            name={"Tag"}
            inst={selectedTag.TagName}
            onClick={handleDeleteTagModal}
            handleDeleteEducation={handleDeleteTag}
            isDarkMode={false}
          />
        )}
        {openAddNewTagModal && (
          <AddNewTagModal
            handleOpenTagModal={handleAddNewTagModal}
            token={token}
            param={param}
            setParam={setParam}
          />
        )}
        {openEditTagModal && (
          <EditTagModal
            openEditTagModal={openEditTagModal}
            setOpenEditTagModal={setOpenEditTagModal}
            selectedTag={selectedTag}
            tagName={selectedTag.TagName}
            token={token}
            param={param}
            setParam={setParam}
          />
        )}
        <div className={tags.length !== 0 ? "" : "w-full"}>
          {loading ? (
            <div className="w-full py-4 flex justify-center items-center">
              <div className="flex space-x-2 w-20">
                <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink" />
                <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-1" />
                <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-2" />
              </div>
            </div>
          ) : tags.length !== 0 ? (
            tags.map((tag, index) => (
              <div
                className={`text-base  flex flex-col md:flex-row md:items-center  w-full ${
                  tags.length - 1 === index ? "" : "border-b-[1px]"
                } py-2 flex justify-between hover:bg-blue-100 cursor-pointer px-2`}
                key={index}
              >
                <div className=" flex justify-between ">
                  {" "}
                  <div>{tag.TagName}</div>
                  <div className=" md:hidden inline-block ">
                    {" "}
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className=" duration-300   font-[550] cursor-pointer  px-1 md:text-sm text-xs  hover:text-gray-700">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                            />
                          </svg>
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className={`absolute ${"right-0"}  z-10 w-40 origin-bottom-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                        >
                          <div className="py-1">
                            <Menu.Item>
                              <div
                                className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                                onClick={() => handleEditTagModal(tag)}
                              >
                                Edit Tag
                              </div>
                            </Menu.Item>
                          </div>
                          <div className="py-1">
                            <Menu.Item>
                              <div
                                className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                                onClick={() => handleDeleteTagModal(tag)}
                              >
                                Delete Tag
                              </div>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>{" "}
                </div>
                <div className="flex gap-2 items-center">
                  <div
                    className=" text-[#0052ff] hover:underline cursor-pointer text-xs md:text-sm"
                    onClick={() => setActivetag(tag)}
                  >
                    {tag.TagUsers.length}{" "}
                    {tag.TagUsers.length === 1 ? "Connection" : "Connections"}
                  </div>

                  <Menu
                    as="div"
                    className="relative md:inline-block hidden text-left"
                    onClick={() => setSelectedTag(tag)}
                  >
                    <div>
                      <Menu.Button className=" duration-300  pt-2 font-[550] cursor-pointer  px-1 md:text-sm text-xs  hover:text-gray-700">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                          />
                        </svg>
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className={`absolute ${"right-0"}  z-10 w-40 origin-bottom-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      >
                        <div className="py-1">
                          <Menu.Item>
                            <div
                              className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                              onClick={() => handleEditTagModal(tag)}
                            >
                              Edit Tag
                            </div>
                          </Menu.Item>
                        </div>
                        <div className="py-1">
                          <Menu.Item>
                            <div
                              className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                              onClick={() => handleDeleteTagModal(tag)}
                            >
                              Delete Tag
                            </div>
                          </Menu.Item>
                        </div>
                        {/* <div className="py-1">
                        <Menu.Item>
                              <div
                                className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100"
                                onClick={() => handleDeleteTagModal(tag)}
                              >
                                Edit Tag
                              </div>
                            </Menu.Item>
                        </div> */}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            ))
          ) : (
            <div className=" text-base font-[550] text-center w-full  pb-2">
              No Tags
            </div>
          )}
        </div>
      </div>
    );
  }
}
