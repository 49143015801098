import React, { useEffect, useRef, useState, Fragment } from "react";
import { IoMdClose } from "react-icons/io";
import { Dialog, Transition } from "@headlessui/react";
import { Checkbox, Label } from "keep-react";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiUrl from "../../apiConfig";

export default function AddTagModal({
  handleOpenTagModal,
  token,
  selectedUser,
}) {
  const [tags, setTags] = useState([]);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [addtag, setAddtag] = useState(false);

  // Form handling with react-hook-form
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tag: "",
    },
  });

  // Fetch existing tags on component load
  useEffect(() => {
    fetchTags();
  }, []);

  // Fetch tags from the server
  const fetchTags = async () => {
    try {
      const response = await axios.post(apiUrl.tags.gettags + token);
      if (response.status === 200) {
        setTags(response.data.tags.Tags);
      }
    } catch (error) {
      // console.error("Error fetching tags:", error);
    }
  };

  // Add a new tag
  const addTag = async (data) => {
    setError("");
    setMessage("");
    try {
      const response = await axios.post(apiUrl.tags.addtag + token, {
        TagName: data.tag,
      });
      if (response.status === 200) {
        reset(); // Clear the form
        fetchTags(); // Refresh tags
        setAddtag(false); // Hide the add tag input
        setMessage("Tag added successfully");
      }
    } catch (error) {
      setError(error.response?.data?.error || "Error adding tag");
    }
  };

  // Save selected tags for the user
  const handleSaveTags = async () => {
    setError("");
    setMessage("");
    const selectedTags = tags.filter((tag) =>
      tag.TagUsers.some(
        (t) => t.UserId.toString() === selectedUser.UserId.toString()
      )
    );
    try {
      const response = await axios.post(apiUrl.tags.addusertotag + token, {
        userId: selectedUser.UserId,
        tags: selectedTags.map((tag) => tag.TagName),
      });
      if (response.status === 200) {
        setMessage("Tags saved successfully");
      }
    } catch (error) {
      setError("Error saving tags");
    }
  };

  // Toggle user association with tags
  const handleTagChange = (tag) => {
    const updatedTags = tags.map((t) =>
      t.TagName === tag.TagName
        ? {
            ...t,
            TagUsers: t.TagUsers.some(
              (user) => user?.UserId?.toString() === selectedUser.UserId
            )
              ? t.TagUsers.filter(
                  (user) => user.UserId.toString() !== selectedUser.UserId
                )
              : [...t.TagUsers, { UserId: selectedUser.UserId }],
          }
        : t
    );
    setTags(updatedTags);
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => handleOpenTagModal()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl text-left transition-all mx-4">
                <div className="md:w-[40vw] max-w-screen-lg w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto rounded-xl border-[1.5px] p-4 bg-white">
                  <div>
                    <div className="flex w-full items-start justify-between py-4 cursor-pointer">
                      <div className="text-xl font-[550]">
                        Add Tag -{" "} <span className=" md:hidden "><br /></span>
                        <span className="text-base md:text-xl font-[550]">{selectedUser.Name}</span>
                      </div>

                      <IoMdClose
                        size={30}
                        onClick={() => handleOpenTagModal()}
                        className="p-1 hover:bg-gray-200 border-none rounded-full duration-300"
                      />
                    </div>

                    <button
                      onClick={() => setAddtag(!addtag)}
                      className="text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100 cursor-pointer font-[550] py-1 md:px-4 px-3 text-sm md:text-left text-center rounded-3xl my-2 md:my-0"
                    >
                      Add Tag 
                    </button>

                    {/* Form to add a new tag */}
                    <form className="" onSubmit={handleSubmit(addTag)}>
                      <div className="py-4 px-2">
                        <div className="max-h-[32vh] md:max-h-[65vh] w-full overflow-y-auto">
                          {tags.map((tag, key) => (
                            <div className="pr-4" key={key}>
                              <fieldset className="flex items-center gap-2 py-2">
                                <Label
                                  htmlFor={key}
                                  className="text-base cursor-pointer px-2 w-full block"
                                >
                                  {tag.TagName}
                                </Label>
                                <Checkbox
                                  className="text-black"
                                  id={key}
                                  name="name"
                                  variant="rounded"
                                  checked={tag.TagUsers.some(
                                    (user) =>
                                      user?.UserId?.toString() ===
                                      selectedUser.UserId
                                  )}
                                  onChange={() => handleTagChange(tag)}
                                />
                              </fieldset>
                              {key !== tags.length - 1 && <hr />}
                            </div>
                          ))}
                        </div>

                        {addtag && (
                          <div>
                            <div className="flex gap-4 items-center justify-between pt-4">
                              <input
                                type="text"
                                placeholder="New Tag Name"
                                className="border-b-[1px] w-full p-1 text-sm focus:outline-none"
                                {...register("tag", {
                                  required: "Tag is required",
                                  maxLength: {
                                    value: 25,
                                    message:
                                      "Tag must not exceed 25 characters",
                                  },
                                  pattern: {
                                    value:
                                      /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9/s_-]+$/,
                                    message:
                                      "Tag can only contain alphanumeric characters, spaces, underscores, and hyphens",
                                  },
                                })}
                              />
                              <button
                                className="text-gray-700 focus:outline-none border-[1.5px] border-gray-700 hover:bg-gray-100 cursor-pointer font-[550] py-1 md:px-4 px-3 text-sm rounded-3xl"
                                type="submit"
                              >
                                Add
                              </button>
                            </div>
                            {/* Display validation errors */}
                            {errors.tag && (
                              <p className="text-red-500 text-sm">
                                {errors.tag.message}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    </form>

                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    {message && (
                      <p className="text-green-500 text-sm">{message}</p>
                    )}

                    {/* Save Tags Button */}
                    <div className="flex justify-end my-2">
                      <button
                        className="bg-[#0052ff] text-white hover:bg-[#014cec] cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4"
                        onClick={handleSaveTags}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
