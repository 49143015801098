import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { set, useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Months from "../asserts/Months";
import countriesList from "../asserts/Countrylist";
import apiUrl from "../apiConfig";
import DefaultEducation from "../images/DefaultEducation.png";
import { FaSpinner } from "react-icons/fa";
import { Label, Radio } from "keep-react";

export default function EditEduModal2({
  vieweditprofile,
  setvieweditprofile,
  values,
  isDarkMode,
  api,
  setapi,
}) {
  const [check, setcheck] = useState(values.graduated);
  const [graduatedError, setGraduatedError] = useState("");
  const checked = () => {
    setcheck(!check);
  };

  // const handleSchoolChange = (e) => {
  //   const inputSchool = e.target.value;
  //   setSelectedSchool(inputSchool);
  //   // Filter schools based on input
  //   // You would replace usSchools with the array containing your school names
  //   // For the purpose of this example, let's assume we have a dummy array called usSchools
  //   const filtered = usSchools.filter((school) =>
  //     school.toLowerCase().startsWith(inputSchool.toLowerCase())
  //   );
  //   setFilteredSchools(filtered);
  // };

  // const handleSchoolSelect = (school) => {
  //   setSelectedSchool(school);
  //   setFilteredSchools([]);
  // };
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedtoMonth, setSelectedtoMonth] = useState("");
  const [selectedtoYear, setSelectedtoYear] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [filteredCounties, setfilteredCounties] = useState([]);
  const [selectedLevelOfEdu, setSelectedLevelOfEdu] = useState(
    values.levelofedu
  );
  const levelOfEducations = [
    "No Formal Education",
    "Some High School",
    "High School Diploma or Equivalent",
    "Some College",
    "Associate Degree",
    "Bachelor's Degree",
    "Some Graduate School",
    "Master's Degree",
    "Professional Degree (e.g., MD, JD)",
    "Doctoral Degree (e.g., PhD, EdD)",
    "Other",
  ];

  const fieldOfStudy = [
    "Computer Science",
    "Electrical Engineering",
    "Mechanical Engineering",
    "Biology",
    "Mathematics",
    "Physics",
    "Chemistry",
    "Information Technology",
    "Civil Engineering",
    "Chemical Engineering",
    "Aerospace Engineering",
    "Biomedical Engineering",
    "Environmental Science",
    "Materials Science",
    "Industrial Engineering",
    "Software Engineering",
    "Data Science",
    "Artificial Intelligence",
    "Robotics",
    "Statistics",
    "Geology",
    "Astronomy",
    "Nuclear Engineering",
    "Systems Engineering",
    "Applied Mathematics",
    "Bioinformatics",
    "Biotechnology",
    "Earth Science",
    "Marine Biology",
    "Neuroscience",
  ];
  const [filteredFieldOfStudy, setFilteredFieldOfStudy] = useState([]);

  const [selectedFieldOfStudy, setSelectedFieldOfStudy] = useState(
    values.field
  );
  const [filteredLevelofEducations, setFilteredLevelofEducations] = useState(
    []
  );
  const [selectedSchoolImage, setSelectedSchoolImage] =
    useState(DefaultEducation);
  const [universities, setUniversities] = useState([]);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const values1 = watch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const closemodal = () => {
    setvieweditprofile(!vieweditprofile);
    setapi(!api);
  };
  const initialtoyears = [
    ...[...Array(Months[1][0] + 11 - Months[1][0]).keys()].map(
      (num) => Months[1][0] + 11 - num
    ),
    ...Months[1],
  ];
  const [toyears, settoyears] = useState(initialtoyears);
  const yearselected = (e) => {
    setSelectedYear(e.target.value);
    settoyears(
      initialtoyears.slice(0, initialtoyears[0] - parseInt(e.target.value) + 1)
    );
    // settoyears(Months[1].slice(0,  Months[1][0] - parseInt(e.target.value) + 1));
  };
  const handleLevelOfEduChange = (e) => {
    const levelofeducation = e.target.value;
    setSelectedLevelOfEdu(levelofeducation);
    const filtered1 = levelOfEducations.filter((obj) =>
      obj.toLowerCase().startsWith(levelofeducation.toLowerCase())
    );
    const filtered2 = levelOfEducations.filter((obj) =>
      obj.toLowerCase().includes(levelofeducation.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);
    // if(levelofeducation==""){
    //   setFilteredLevelofEducations([]);
    // }
    setFilteredLevelofEducations(finalFiltered);
  };

  const handleFieldOfStudyChange = (e) => {
    const fieldofstudy = e.target.value;
    setSelectedFieldOfStudy(fieldofstudy);
    const filtered1 = fieldOfStudy.filter((obj) =>
      obj.toLowerCase().startsWith(fieldofstudy.toLowerCase())
    );

    const filtered2 = fieldOfStudy.filter((obj) =>
      obj.toLowerCase().includes(fieldofstudy.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);
    // if(levelofeducation==""){
    //   setFilteredLevelofEducations([]);
    // }
    setFilteredFieldOfStudy(finalFiltered);
  };

  const handleFieldOfStudySelect = (name) => {
    setSelectedFieldOfStudy(name);
    setFilteredFieldOfStudy([]);
  };
  const handleLevelOfEduSelect = (name) => {
    setSelectedLevelOfEdu(name);
    setFilteredLevelofEducations([]);
  };
  useEffect(() => {
    const fetchUniversities = async (school) => {
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        const img = response.data.find((obj) => obj.Name == school);
        setSelectedSchoolImage(img ? img.s3Link : DefaultEducation);
        setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversities(values.school);
    setSelectedSchool(values.school);
    setselectedCountry(values.country);
    setSelectedMonth(values.fromMonth);
    setSelectedYear(values.fromYear);
    setSelectedtoMonth(values.toMonth);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click occurs outside of it
      if (filteredSchools.length > 0) {
        const dropdown = document.getElementById("schoolDropdown");
        if (dropdown && !dropdown.contains(event.target)) {
          setFilteredSchools([]);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filteredSchools]);
  const [loading, setloading] = useState(false);
  const [canBeGraduated, setCanBeGraduated] = useState(
    new Date(values.toYear, values.toMonth) < new Date()
  );
  const submitform = async (data) => {
    // // // // console,log(data);
    setloading(true);
    if (data.isPursuing) {
      data.toMonth = "";
      data.toYear = "";
    }
    data.school = selectedSchool;
    data.country = selectedCountry;
    data.fromMonth = selectedMonth;
    data.fromYear = selectedYear;
    data.field = selectedFieldOfStudy;
    data.levelofedu = selectedLevelOfEdu;
    data.toMonth = selectedtoMonth;
    data.graduated = check;
    if (check) {
      const toDate = new Date(`${data.toYear}-${selectedtoMonth}-01`);
      const today = new Date();
      if (toDate > today) {
        setGraduatedError(
          "Graduated date should not be greater than current date"
        );
        setloading(false);
        return;
      }
    }
    const reqbody = {
      education: data,
      id: values._id,
    };
    try {
      const response = await axios.post(
        apiUrl.profile.editexp + token,
        reqbody
      );
      //// // // console,log(response);
      if (response.status == 200) {
        //// // // console,log(response.body);
        // Navigate to the next page or wherever you want
        closemodal();
      }
    } catch (err) {
      //// // // console,log(err);
    } finally {
      setloading(false);
    }
  };
  const [tomonths, settomonths] = useState(Months[0]);

  const monthsselected = (e) => {
    setSelectedMonth(e.target.value);
    if (values.fromYear !== values.toYear && selectedYear !== selectedtoYear)
      settomonths(Months[0].slice(Months[0].indexOf(e.target.value)));
  };
  useEffect(() => {
    // // // console,log(values.fromYear, values.toYear, selectedYear, selectedtoYear);
    if (selectedYear === selectedtoYear) {
      settomonths(Months[0].slice(Months[0].indexOf(values.fromMonth)));
    } else {
      settomonths(Months[0]);
    }
  }, [selectedYear, selectedtoYear]);

  useEffect(() => {
    setSelectedMonth(values.fromMonth);
    // // // console,log(values.fromYear, values.toYear, selectedYear, selectedtoYear);
    if (values.fromYear === values.toYear) {
      settomonths(Months[0].slice(Months[0].indexOf(values.fromMonth)));
    } else {
      settomonths(Months[0]);
    }
    setSelectedYear(values.fromYear);
    setSelectedtoYear(values.toYear);
  }, []);

  const [lengthexceeded, setlengthexceeded] = useState(false);
  const handleSchoolChange = (e) => {
    const inputSchool = e.target.value;
    if (inputSchool.length > 100) {
      setlengthexceeded(true);
      return;
    } else {
      setlengthexceeded(false);
    }
    setSelectedSchool(inputSchool);

    const filtered1 = universities.filter((obj) =>
      obj.Name.toLowerCase().startsWith(inputSchool.toLowerCase())
    );

    const filtered2 = universities.filter((obj) =>
      obj.Name.toLowerCase().includes(inputSchool.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);

    if (inputSchool == "") {
      setSelectedSchoolImage("");
    }
    if (finalFiltered.length > 0) {
      // setSelectedSchoolImage(filtered[0].s3Link);
    } else {
      setSelectedSchoolImage(DefaultEducation);
    }
    setFilteredSchools(finalFiltered);
  };

  const handleSchoolSelect = (obj) => {
    setSelectedSchool(obj.Name);
    setSelectedSchoolImage(obj.s3Link);
    setFilteredSchools([]);
  };

  const [selectedCountry, setselectedCountry] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filteredSchools.length > 0 &&
        !document.getElementById("schoolDropdown").contains(event.target)
      ) {
        setFilteredSchools([]);
      }
      if (
        filteredLevelofEducations.length > 0 &&
        !document
          .getElementById("levelofeducationDropdown")
          .contains(event.target)
      ) {
        setFilteredLevelofEducations([]);
      }
      if (
        filteredCounties.length > 0 &&
        !document.getElementById("countryDropdown").contains(event.target)
      ) {
        setfilteredCounties([]);
      }
      if (
        filteredFieldOfStudy.length > 0 &&
        !document.getElementById("fieldOfStudyDropdown").contains(event.target)
      ) {
        setFilteredFieldOfStudy([]);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setFilteredSchools([]);
        setFilteredLevelofEducations([]);
        setfilteredCounties([]);
        setFilteredFieldOfStudy([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [
    filteredSchools,
    filteredLevelofEducations,
    filteredCounties,
    filteredFieldOfStudy,
  ]);
  const handleCityChange = (e) => {
    const inputCity = e.target.value;
    setselectedCountry(inputCity);
    // Filter cities based on input (starts with or includes)
    const filteredStartsWith = countriesList.filter((obj) =>
      obj.toLowerCase().startsWith(inputCity.toLowerCase())
    );

    const filteredIncludes = countriesList.filter((obj) =>
      obj.toLowerCase().includes(inputCity.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filteredStartsWith.concat(filteredIncludes));

    // Convert back to array
    const filtered = Array.from(uniqueItemsSet);

    setfilteredCounties(filtered);
  };
  const handleCitySelect = (city) => {
    setselectedCountry(city);
    setfilteredCounties([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close the dropdown if the click occurs outside of it
      if (filteredCounties.length > 0) {
        const dropdown = document.getElementById("country");
        if (dropdown && !dropdown.contains(event.target)) {
          setfilteredCounties([]);
        }
      }
    };

    const handleEscapeKey = (event) => {
      // Close the dropdown if the "Escape" key is pressed
      if (event.key === "Escape") {
        setfilteredCounties([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [filteredCounties]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closemodal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                    isDarkMode
                      ? "bg-[#2a303c] border-[#2a303c]"
                      : "bg-white text-black"
                  }`}
                >
                  {" "}
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="text-2xl font-[550]">Edit Education</div>
                    <IoMdClose
                      size={30}
                      onClick={closemodal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  <br />
                  <div className="">
                    <form
                      action=""
                      onSubmit={handleSubmit(submitform)}
                      className="flex flex-col relative gap-4"
                    >
                      <div>
                        <label htmlFor="school" className="font-[550]">
                          School <span className="text-red-500">*</span>
                        </label>
                        <div
                          className={`w-full p-2 border-[1.5px] flex gap-4 items-center rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedSchoolImage && (
                            <img
                              src={selectedSchoolImage}
                              alt="School Logo"
                              className="h-6 w-6 ml-2"
                            />
                          )}
                          <input
                            type="text"
                            className="focus:outline-none w-[100%]"
                            name="school"
                            required
                            {...register("school", {
                              maxLength: {
                                value: 100,
                                message:
                                  "School must not exceed 100 characters",
                              },
                              pattern: {
                                value:
                                  /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                                message:
                                  "School can only contain alphanumeric characters, spaces, underscores, and hyphens",
                              },
                            })}
                            value={selectedSchool}
                            onChange={handleSchoolChange}
                          />
                        </div>
                        {errors.school && (
                          <p className="text-red-500 text-sm">
                            {errors.school.message}
                          </p>
                        )}

                        {/* Dropdown menu for filtered schools */}
                        {filteredSchools.length > 0 && (
                          <div
                            id="schoolDropdown"
                            className="absolute z-10 mt-1 bg-white border rounded-md w-[100%] max-h-[150px] overflow-y-auto shadow-lg"
                          >
                            {filteredSchools.map((obj, index) => (
                              <div
                                key={index}
                                className="cursor-pointer flex hover:bg-gray-100 p-2"
                                onClick={() => handleSchoolSelect(obj)}
                              >
                                <img
                                  src={obj.s3Link}
                                  alt="School Logo"
                                  className="h-6 w-6 mr-2 rounded-sm"
                                />
                                {/* Display School logo */}
                                {obj.Name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="">
                        <label htmlFor="levelofedu" className=" font-[550]">
                          Level of education{" "}
                          <span className=" text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2 border-[1.5px]  rounded-md my-1  ${
                            isDarkMode
                              ? "border-[#3b4354] bg-[#3b4354] "
                              : "bg-white text-black"
                          }`}
                          name="levelofedu"
                          required
                          {...register("levelofedu", {
                            maxLength: {
                              value: 100,
                              message:
                                "Level of education must not exceed 100 characters",
                            },
                            pattern: {
                              value:
                                /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                              message:
                                "Level of education can only contain alphanumeric characters, spaces, underscores, and hyphens",
                            },
                          })}
                          value={selectedLevelOfEdu}
                          onChange={handleLevelOfEduChange}
                        />
                        {errors.levelofedu && (
                          <p className="text-red-500 text-sm">
                            {errors.levelofedu.message}
                          </p>
                        )}
                        {filteredLevelofEducations.length > 0 && (
                          <div
                            id="levelofeducationDropdown"
                            className="absolute z-10 mt-1 bg-white border rounded-md w-[100%]  max-h-[150px] overflow-y-auto shadow-lg"
                          >
                            {filteredLevelofEducations.map((obj, index) => (
                              <div
                                key={index}
                                className="cursor-pointer flex hover:bg-gray-100 p-2"
                                onClick={() => handleLevelOfEduSelect(obj)}
                              >
                                {obj}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div>
                        <label htmlFor="field" className=" font-[550]">
                          Field of study <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-1  ${
                            isDarkMode
                              ? "border-[#3b4354] bg-[#3b4354] "
                              : "bg-white text-black"
                          }`}
                          name="field"
                          {...register("field", {
                            maxLength: {
                              value: 100,
                              message:
                                " Field of study must not exceed 100 characters",
                            },
                            pattern: {
                              value:
                                /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                              message:
                                " Field of study can only contain alphanumeric characters, spaces, underscores, and hyphens",
                            },
                          })}
                          value={selectedFieldOfStudy}
                          onChange={handleFieldOfStudyChange}
                        />
                        {errors.field && (
                          <p className="text-red-500 text-sm">
                            {errors.field.message}
                          </p>
                        )}
                        {filteredFieldOfStudy.length > 0 && (
                          <div
                            id="fieldOfStudyDropdown"
                            className="absolute z-10 mt-1 bg-white border max-h-[150px] overflow-y-auto rounded-md w-[100%] shadow-lg"
                          >
                            {filteredFieldOfStudy.map((obj, index) => (
                              <div
                                key={index}
                                className="cursor-pointer flex hover:bg-gray-100 p-2"
                                onClick={() => handleFieldOfStudySelect(obj)}
                              >
                                {obj}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>

                      <div>
                        <label htmlFor="city" className="font-[550]">
                          City
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                          name="city"
                          {...register("city", {
                            maxLength: {
                              value: 100,
                              message: "City must not exceed 100 characters",
                            },
                            pattern: {
                              value:
                                /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                              message:
                                "City can only contain alphanumeric characters, spaces, underscores, and hyphens",
                            },
                          })}
                          defaultValue={values.city}
                        />
                        {errors.city && (
                          <p className="text-red-500 text-sm">
                            {errors.city.message}
                          </p>
                        )}
                        {/* Dropdown menu for filtered cities */}
                      </div>
                      <div>
                        <label htmlFor="country" className="font-[550]">
                          Country <span className="text-red-500">*</span>
                        </label>
                        <div className="w-[100%] relative">
                          <div>
                            <input
                              type="text"
                              className={`w-full p-2 border-[1.5px] rounded-md my-2 `}
                              name="city"
                              value={selectedCountry}
                              {...register("Country", {
                                maxLength: {
                                  value: 100,
                                  message:
                                    "Country must not exceed 100 characters",
                                },
                                pattern: {
                                  value:
                                    /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                                  message:
                                    "Country can only contain alphanumeric characters, spaces, underscores, and hyphens",
                                },
                              })}
                              onChange={handleCityChange}
                            />
                            {errors.Country && (
                              <p className="text-red-500 text-sm">
                                {errors.Country.message}
                              </p>
                            )}
                            {/* Dropdown menu for filtered cities */}
                            {filteredCounties.length > 0 && (
                              <div
                                id="countryDropdown"
                                className="absolute z-10 mt-1 bg-white border w-[100%] max-h-[150px] overflow-y-auto   rounded-md shadow-lg"
                              >
                                {filteredCounties.map((city, index) => (
                                  <div
                                    key={index}
                                    className="cursor-pointer hover:bg-gray-100 p-2"
                                    onClick={() => handleCitySelect(city)}
                                  >
                                    {city}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        {/* education years */}

                        <div className="">
                          <div className="pb-4">
                            <label
                              htmlFor="fromMonth"
                              className="font-[550] block"
                            >
                              From <span className="text-red-500">*</span>
                            </label>
                            <div className="flex justify-between">
                              <select
                                className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  ${
                                  isDarkMode
                                    ? "border-[#3b4354] bg-[#3b4354] "
                                    : ""
                                }`}
                                name="Month"
                                required
                                {...register("fromYear")}
                                value={selectedYear}
                                // defaultValue={}
                                onChange={yearselected}
                              >
                                <option value="">Year</option>
                                {Months[1].map((country, index) => (
                                  <option key={index} value={country}>
                                    {country}
                                  </option>
                                ))}
                              </select>
                              <select
                                className={`w-[48%] py-2    border-[1.5px]  rounded-md my-1  ${
                                  isDarkMode
                                    ? "border-[#3b4354] bg-[#3b4354] "
                                    : "bg-white text-black"
                                }`}
                                name="fromMonth"
                                {...register("fromMonth")}
                                value={selectedMonth}
                                onChange={monthsselected}
                              >
                                <option value="">Month</option>
                                {Months[0].map((month, index) => (
                                  <option key={index} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="toMonth"
                              className="font-[550] block"
                            >
                              To (or expected){" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="flex justify-between">
                              <select
                                className={`w-[48%] py-2    border-[1.5px]  rounded-md my-1  ${
                                  isDarkMode
                                    ? "border-[#3b4354] bg-[#3b4354] "
                                    : "bg-white text-black"
                                }`}
                                name="toYear"
                                required
                                defaultValue={values.toYear}
                                {...register("toYear")}
                                onChange={(e) =>
                                  setSelectedtoYear(e.target.value)
                                }
                              >
                                <option value="">Year</option>
                                {toyears.map((year, index) => (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>

                              <select
                                className={`w-[48%] py-2    border-[1.5px]  rounded-md my-1  ${
                                  isDarkMode
                                    ? "border-[#3b4354] bg-[#3b4354] "
                                    : "bg-white text-black"
                                }`}
                                name="toYear"
                                defaultValue={values.toMonth}
                                {...register("toMonth")}
                                onChange={(e) =>
                                  setSelectedtoMonth(e.target.value)
                                }
                              >
                                <option value="">Month</option>
                                {tomonths.map((year, index) => (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <fieldset className="items-center gap-2 my-2 py-2">
                            <Label
                              htmlFor="graduated"
                              className="flex text-black font-[550] text-md gap-4 items-center"
                            >
                              Graduated
                              <Radio
                                id="graduated"
                                name="graduated"
                                className="text-black"
                                checked={check}
                                onClick={checked}
                                disabled={canBeGraduated}
                                onChange={() => {}} // This is required for the Radio component to work
                              />
                            </Label>
                          </fieldset>
                          {graduatedError && (
                            <div className="text-red-500">{graduatedError}</div>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row-reverse w-full justify-between">
                        {loading ? (
                          <FaSpinner
                            size={30}
                            className="text-[#0052ff] animate-spin"
                          />
                        ) : (
                          <div>
                            {/* <button
                    onClick={() => {navigate("/profile/page3");
                    window.scroll(0, 0)}}
                      className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                    >
                      Back
                    </button> */}
                            <button
                              type="submit"
                              className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
