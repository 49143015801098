import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ProfilepicModal from "../ProfilepicModal";
import dummyDP from "../../asserts/dummyDp.png";
import axios from "axios";
import apiUrl from "../../apiConfig";
export default function Profile3_1() {
  const [title, setTitle] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [profilepicmodal, setprofilepicmodal] = useState(false);
  const [image, setimage] = useState("");
  const [dummyDP, setdummyDP] = useState(false);
  const token = localStorage.getItem("token");
  const [gender, setGender] = useState(" ");

  const handleGenderChange = (selectedGender) => {
    setGender(selectedGender);
  };
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const toggleProfile = () => {
    setprofilepicmodal(!profilepicmodal);
  };

  const saveTitle = async () => {
    try {
      const response = await axios.post(apiUrl.profile.editprofile + token, {
        currentRole: title,
        DOB: DOB,
        Survey: {
          "What is your gender identity?": gender,
        },
      });
      if (response.status == 200) {
        navigate("/signup/add-education");
        window.scroll(0, 0);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const postProfileStatus = async () => {
      try {
        const reqbody = {
          profilestatus: "/signup/profilepicture",
        };
        const response = await axios.post(
          apiUrl.profile.editprofile + `${token}`,
          reqbody
        );
        if (response.status == 200) {
        }
      } catch (error) {
        //console.log(error)
      }
    };

    postProfileStatus();
  }, []);
  const [DOB, setDOB] = useState("");
  return (
    <div>
      <div className="h-1 w-full flex">
        <div className="h-full bg-[#0052ff] w-[37.5%]"></div>
        <div className="h-full w-[62.5%]"></div>
      </div>
      <div className="m-4 ">
        <div className="py-3 text-2xl font-[550] ">
          Add a profile picture and title
        </div>
        <div>This helps you showcase your profile</div>
        <br />
        <div className=" flex flex-col gap-2">
          <div className="flex flex-col">
            <label htmlFor="title" className=" font-[550]">
              Profile Headline
              {/* <span className="text-red-500">*</span> */}
            </label>
            <input
              type="text"
              className={`w-full p-2    border-[1.5px]  rounded-md my-2  `}
              name="title"
              value={title}
              onChange={handleTitleChange}
            />
          </div>

          <div className="flex flex-col py-2">
            <label htmlFor="date" className="font-[550] pb-1">
              Date of Birth
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={DOB}
              onChange={(e) => setDOB(e.target.value)}
              className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="flex flex-col py-2">
            <label htmlFor="date" className="font-[550] pb-1">
              Gender
            </label>
            <div className="flex gap-4 px-2">
              <input
                type="checkbox"
                name="male"
                id="male"
                checked={gender === "male"}
                onChange={() => handleGenderChange("male")}
              />
              <label>Male</label>
            </div>
            <div className="flex gap-4 px-2">
              <input
                type="checkbox"
                name="female"
                id="female"
                checked={gender === "female"}
                onChange={() => handleGenderChange("female")}
              />
              <label>Female</label>
            </div>
            <div className="flex gap-4 px-2">
              <input
                type="checkbox"
                name="others"
                id="others"
                checked={gender === "others"}
                onChange={() => handleGenderChange("others")}
              />
              <label>Other</label>
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="profilepic" className=" font-[550]">
              Profile picture
              {/* <span className="text-red-500">*</span> */}
            </label>
            <button
              type="button"
              className="w-full p-2 border-[1.5px]  rounded-md my-2  bg-[#eef0f3] hover:bg-[#e9ebee]"
              onClick={() => {
                setprofilepicmodal(true);
              }}
            >
              Upload Profile Picture
            </button>
            {image && (
              <img
                src={image}
                className=" h-full rounded-lg aspect-square z-10 border-2 border-white bg-white"
                alt="Uploaded Profile Picture"
              />
            )}
          </div>
          <div className="flex flex-row-reverse  w-full justify-between">
            {loading ? (
              <FaSpinner size={30} className="text-[#0052ff] animate-spin" />
            ) : (
              <div>
                {/* <button
                  onClick={() => {
                    navigate("/profile/page1");
                    window.scroll(0, 0);
                  }}
                  className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                >
                  Back
                </button> */}
                <button
                  onClick={saveTitle}
                  className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                >
                  Next
                </button>
              </div>
            )}

            <button
              className="text-[#0052ff]  font-[550] "
              onClick={() => {
                navigate("/signup/add-education");
                window.scroll(0, 0);
              }}
            >
              Skip
            </button>
          </div>
        </div>
      </div>
      {profilepicmodal && (
        <ProfilepicModal
          toggleProfile={toggleProfile}
          dummyDP={dummyDP}
          image={image}
          setimage={setimage}
          title="Profile picture"
          type="signup"
        />
      )}
    </div>
  );
}
