import React, { useEffect, useState } from "react";
import axios from "axios";
import apiUrl from "../apiConfig";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function GlobalAdmin() {
  const [admins, setAdmins] = useState([]);
  const [accessCodes, setAccessCodes] = useState([]);
  const [accessCodeStatus, setAccessCodeStatus] = useState("Select");

  const fetchAllAdmins = async () => {
    try {
      const response = await axios.post(apiUrl.globaladmin.getalladmins);
      if (response.status == 200) {
        setAdmins(response.data.admins);
      }
    } catch (error) {}
  };

  const fetchAllAccessCodes = async (filter) => {
    try {
      const response = await axios.post(apiUrl.globaladmin.getallaccesscodes, {
        filter: filter,
      });
      if (response.status == 200) {
        setAccessCodes(response.data.accessCodes);
      }
    } catch (error) {}
  };
  useEffect(() => {
    fetchAllAdmins();
    fetchAllAccessCodes("Select");
  }, []);

  const changeAccessCodeStatus = (e) => {
    setAccessCodeStatus(e.target.value);
    fetchAllAccessCodes(e.target.value);
  };
  return (
    <div className={`flex justify-center min-h-screen bg-[#ffffff]`}>
      <div className="w-full md:max-w-screen-xl md:w-[100%] px-4 ">
        <br />
        <div className="text-3xl">Global Admin Dashboard</div>
        <br />
        {admins.length > 0 && (
          <div className="bg-gray-100 p-2">
            <div className="font-[550]">List of Admins</div>
            <br />
            <table className="table-auto w-full divide-y divide-gray-200 font-[550] bg-white">
              <thead className="border">
                <tr className="h-[3rem] text-black bg-gray-200">
                  <th className="w-fit text-left  text-md text-black">Admin Id</th>
                  <th className="w-fit text-left  text-md text-black">Email</th>
                  <th className="w-fit text-left  text-md text-black">
                    Created At
                  </th>
                  <th className="w-fit text-left  text-md text-black"></th>
                </tr>
              </thead>
              {admins.map((admin, index) => (
                <tbody className="divide-gray-25 divide-y" key={index}>
                  <tr className="h-[3rem] border-b-[1px] bg-white text-black hover:bg-gray-100">
                    <td>{admin._id}</td>
                    <td>{admin.email}</td>
                    <td>{new Date(admin.createdAt).toLocaleString()}</td>
                    <td className="text-right">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className=" duration-300   font-[550] cursor-pointer  px-1 md:text-sm text-xs  hover:text-gray-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                              />
                            </svg>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            className={`absolute ${"right-0"}  z-10 w-40 origin-bottom-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                          >
                              <Menu.Item>
                                <div className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                                  Delete Admin
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                                  Change Password
                                </div>
                              </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        )}
        <br />
        <br />
        {accessCodes.length > 0 && (
          <div className="bg-gray-100 p-2">
            <div className="flex flex-col gap-4">
              <div className="font-[550]">List of Access Codes</div>
              <div className="flex flex-col">
                <label
                  className="text-sm text-black"
                  htmlFor="accessCodeStatus"
                >
                  Status
                </label>
                <select
                  id="accessCodeStatus"
                  className="bg-white border border-gray-300 rounded-md shadow-sm p-2 w-28"
                  value={accessCodeStatus}
                  onChange={(e) => changeAccessCodeStatus(e)}
                >
                  <option value="Select">Select</option>
                  <option value="Expired">Expired</option>
                  <option value="Active">Active</option>
                </select>
              </div>
            </div>
            <br />
            <table className="table-auto w-full divide-y divide-gray-200 font-[550] bg-white">
              <thead className="border">
                <tr className="h-[3rem] text-black bg-gray-200">
                  <th className="w-fit text-left  text-md text-black">Access Code Id</th>
                  <th className="w-fit text-left text-md text-black">Code</th>
                  <th className="w-fit text-left text-md text-black">
                    Created At
                  </th>
                  <th className="w-fit  text-left text-md text-black">
                    Times Used
                  </th>
                  <th className="w-fit  text-left  text-md text-black">
                    Expiry Status
                  </th>
                  <th className="text-right text-md text-black"></th>
                </tr>
              </thead>
              {accessCodes.map((accessCode, index) => (
                <tbody className="divide-gray-25 divide-y" key={index}>
                  <tr className="h-[3rem] border-b-[1px] bg-white text-black hover:bg-gray-100">
                    <td>{accessCode._id}</td>
                    <td>{accessCode.accesscode}</td>
                    <td>{new Date(accessCode.createdAt).toLocaleString()}</td>
                    <td>{accessCode.timesused}</td>
                    <td
                      className={`text-${
                        accessCode.expired ? "red-500" : "green-500"
                      }`}
                    >
                      {accessCode.expired ? "Expired" : "Active"}
                    </td>
                    <td className="text-right">
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className=" duration-300   font-[550] cursor-pointer  px-1 md:text-sm text-xs  hover:text-gray-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
                              />
                            </svg>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            className={`absolute ${"right-0"}  z-10 w-40 origin-bottom-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                          >
                              <Menu.Item>
                                <div className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                                  Delete
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                <div className=" text-gray-900 block px-4 py-2 text-sm cursor-pointer whitespace-nowrap w-full hover:bg-gray-100">
                                  Re-activate
                                </div>
                              </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        )}
        <br />
      </div>
    </div>
  );
}
