import React, { useEffect, useRef, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { LuPencilLine } from "react-icons/lu";
import DeleteModal from "./DeleteModal";
import axios from "axios";
import apiUrl from "../apiConfig";
import EditCertificationModal from "./EditCertificationModal";
import { RxOpenInNewWindow } from "react-icons/rx";
import DashSecOptions from "./DashSecOptions";

export default function Certifications({ props }) {
  const [showModal, setShowModal] = useState(false);
  const handledelete = () => {
    setShowModal(!showModal);
  };

  const handleDeleteCertification = async () => {
    try {
      const exp1 = {
        Organisation: props.item.Organisation,
        ...props.item.roles[rolenumber],
      };

      const response = await axios.post(
        apiUrl.profile.deleteprofile + props.token,
        {
          certifications: exp1,
        }
      );
      if (response.status === 200) {
        setShowModal(!showModal);
        props.setapi(!props.api);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const [showeditcert, setshoweditcert] = useState(false);
  const [exp, setexp] = useState({});
  const handleEdit = (number) => {
    setrolenumber(number);
    const exp1 = {
      Organisation: props.item.Organisation,
      ...props.item.roles[number],
    };
    setexp(exp1);
    setshoweditcert(!showeditcert);
    props.setapi(!props.api);
  };

  const [rolenumber, setrolenumber] = useState(0);
  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  const updateDivHeight = () => {
    if (divRef.current) {
      const height = divRef.current.offsetHeight;
      setDivHeight(height);
    }
  };

  useEffect(() => {
    // Calculate height on mount and when roles change
    updateDivHeight();

    // Add a resize event listener to handle responsiveness
    window.addEventListener("resize", updateDivHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, [props.item.roles]); // Depend on the roles to recalculate height when they change

  return (
    <div className="pt-2">
      {showeditcert && (
        <EditCertificationModal
          handleshowcertmodal={handleEdit}
          values={exp}
          isDarkMode={props.isDarkMode}
          api={props.api}
          setapi={props.setapi}
        />
      )}
      {showModal && (
        <DeleteModal
          onClick={handledelete}
          handleDeleteEducation={handleDeleteCertification}
          isDarkMode={props.isDarkMode}
          inst={props.item.roles[rolenumber].Name}
          name={"certification"}
        />
      )}

      <div className="flex justify-between w-[95%] ">
        <div className="flex gap-2 flex-col w-full ">
          <div className=" flex gap-2 items-center">
            <div className="md:h-[50px] h-[40px] aspect-square">
              {props.image && (
                <img
                  src={props.image}
                  alt="Company Logo"
                  className={
                    props.image.startsWith("https://")
                      ? " rounded-md "
                      : " rounded-md  border-[1.5px] p-1 border-black "
                  }
                />
              )}
            </div>
            <div className="flex flex-col justify-start h-full">
              <p className="font-[550]">{props.item.Organisation} </p>
            </div>
          </div>
          <div className="text-md w-full relative flex gap-2">
            <div className="w-[40px] md:w-[50px] relative">
              <div className="absolute h-full border-[1px] left-1/2"></div>
            </div>
            <div className="flex flex-col gap-0 w-full">
              <div className="flex flex-col gap-4 w-full">
                {props.item.roles.map((role, key) => (
                  <div
                    key={key}
                    className="flex justify-between items-start w-full relative"
                  >
                    {key === props.item.roles.length - 1 && (
                      <div
                        style={{
                          // height: `85%`, // Apply the calculated height here if needed
                          top: `${divHeight / 2 + 1.5}px`, // Dynamically apply the top value
                        }}
                        className="absolute border-[5px] sm:h-[91%] h-[84%]  border-white left-[-27px] md:left-[-32px]"
                      ></div>
                    )}

                    <div className="flex flex-col gap-0">
                      <div ref={divRef} className="flex relative">
                        <div className="absolute left-[-25px] top-1/2 md:left-[-30px] border-[1px] w-[20px] md:w-[25px]" />
                        <h1 className="text-sm font-[550] px-1 ">{role.Name}</h1>
                      </div>

                      <p className="text-sm px-1">
                        {role.credentialid} {role.credentialurl}
                      </p>

                      <p className="text-sm px-1">
                        {role.Skills.length !== 0 && (
                          <span className="font-[550]">Skills: </span>
                        )}
                        {role.Skills.map((item, key) => (
                          <span key={key}>
                            {item}
                            {item === role.Skills[role.Skills.length - 1]
                              ? "."
                              : ","}{" "}
                          </span>
                        ))}
                      </p>
                      {role.issuemonth &&
                        role.issueyear &&
                        role.expirymonth &&
                        role.expiryyear && (
                          <p className="text-sm text-gray-400 px-1">
                            {role.issuemonth} {role.issueyear} -{" "}
                            {role.expirymonth} {role.expiryyear}
                          </p>
                        )}

                      <div className="py-2 w-fit px-1">
                        {role.link && (
                          <a
                            className="border-gray-100 flex justify-center items-center gap-2 hover:bg-slate-100 hover:shadow-lg text-sm duration-300 cursor-pointer border-2 p-2 rounded-2xl"
                            target="_blank"
                            href={
                              role.link.startsWith("https://")
                                ? role.link
                                : "https://" + role.link
                            }
                          >
                            <span>View certificate</span>
                            <RxOpenInNewWindow />
                          </a>
                        )}
                      </div>
                    </div>
                    {!props.publicview && (
                      <div>
                        <DashSecOptions
                          edititem={() => handleEdit(key)}
                          deleteitem={() => {
                            setrolenumber(key);
                            setShowModal(!showModal);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {props.length - 1 !== props.key && (
         <hr className="text-center w-full mt-2" />
      )}
    </div>
  );
}
