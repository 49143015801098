import React, { useEffect, useState, Fragment } from "react";
import { IoMdClose } from "react-icons/io";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiUrl from "../../apiConfig";

export default function EditTagModal({
  openEditTagModal,
  setOpenEditTagModal,
  token,
  tagName,
  selectedTag,
  param,
  setParam,
}) {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tag: tagName || "", // Set default tag value here
    },
  });

  // Set the value of the input field when `tagName` changes
  useEffect(() => {
    if (tagName) {
      setValue("tag", tagName);
    }
  }, [tagName, setValue]);

  const handleEditTag = async (data) => {
    setError("");
    setMessage("");
    try {
      const response = await axios.post(
        `${apiUrl.tags.edittag}${token}`, // Correct API URL
        {
          TagName: selectedTag?.TagName, // Handle undefined `selectedTag`
          newTagName: data.tag,
        }
      );
      if (response.status === 200) {
        setParam(!param);
        setMessage("Tag updated successfully");
      }
    } catch (error) {
      setError(error.response?.data?.error || "An error occurred");
    }
  };

  return (
    <Transition.Root show={openEditTagModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setOpenEditTagModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl text-left transition-all mx-4">
                <div className="md:w-[40vw] max-w-screen-lg w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto rounded-xl border-[1.5px] p-4 bg-white">
                  <div>
                    <div className="flex w-full items-start justify-between py-4 cursor-pointer">
                      <div className="text-xl font-[550]">Edit Tag</div>

                      <IoMdClose
                        size={30}
                        onClick={() => setOpenEditTagModal(false)}
                        className="p-1 hover:bg-gray-200 border-none rounded-full duration-300"
                      />
                    </div>

                    <form className="" onSubmit={handleSubmit(handleEditTag)}>
                      <div className="">
                        <div className="flex gap-4 items-center justify-between pt-4">
                          <input
                            type="text"
                            placeholder="New Tag Name"
                            className="w-full p-2 border-[1.5px] rounded-md"
                            {...register("tag", {
                              required: "Tag is required",
                              maxLength: {
                                value: 25,
                                message: "Tag must not exceed 25 characters",
                              },
                              pattern: {
                                value: /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9/s_-]+$/,
                                message:
                                  "Tag can only contain alphanumeric characters, spaces, underscores, and hyphens",
                              },
                            })}
                          />
                        </div>
                        {errors.tag && (
                          <p className="text-red-500 text-sm">
                            {errors.tag.message}
                          </p>
                        )}
                      </div>
                      {error && <p className="text-red-500 text-sm">{error}</p>}
                      {message && (
                        <p className="text-green-500 text-sm">{message}</p>
                      )}
                      <div className="flex justify-end my-2">
                        <button
                          className="bg-[#0052ff] text-white hover:bg-[#014cec] cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4"
                          type="submit" // Submit button should be inside the form
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
