import React, { useState } from "react";
import axios from "axios";
import apiUrl from "../apiConfig";
import { useNavigate } from "react-router-dom";

export default function AdminAddLogos() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [type, setType] = useState("Company");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!name || !selectedImage) {
      setError("Please provide both a name and an image.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("type", type);
      formData.append("logo", selectedImage); // Assuming selectedImage is a file object

      const response = await axios.post(apiUrl.admin.addlogo, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(response.data);
      setError(""); // Clear any previous error
      setSuccessMessage(`${type} logo uploaded successfully!`);
      setName("");
      setSelectedImage(null);

      // Optionally navigate to another page or clear the form after success
      // navigate("/admin/logos");
    } catch (error) {
      console.error(error);
      setError("An error occurred while uploading the logo. Please try again.");
    }
  };

  return (
    <div
        className={`flex justify-center min-h-screen bg-[#ffffff]`}
      >
        <div className="w-full md:max-w-screen-xl md:w-[100%] px-4 ">
        <div className="flex items-center">
          <div className="flex flex-col my-4">
            <div className="text-3xl mb-4">Add Logo</div>

            <div className="flex mb-4">
              <div className="flex flex-col gap-2">
                <label className="font-[550]">Type of Logo</label>
                <select
                  className="w-full px-2 py-2 border-[1.5px] rounded-md"
                  value={type}
                  onChange={handleTypeChange}
                >
                  <option value="Company">Company</option>
                  <option value="School">School</option>
                </select>
              </div>
            </div>

            <div className="flex mb-4">
              <div className="flex flex-col gap-2">
                <label className="font-[550]">Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  className="border-[1px] border-[#eceff1] rounded-md px-2 py-2"
                  placeholder="Enter Name"
                />
              </div>
            </div>

            <div className="flex mb-4">
              <div className="flex flex-col gap-2">
                <label className="font-[550]">Logo</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            </div>
            {/* Error Message */}
            {error && (
              <div className="text-red-500 text-sm mb-2">{error}</div>
            )}

            {/* Success Message */}
            {successMessage && (
              <div className="text-green-500 text-sm mb-2">{successMessage}</div>
            )}
            
            <button
              className="w-fit bg-[#0052ff] text-white text-sm py-1 px-4 rounded-3xl"
              onClick={handleSubmit}
            >
              Add Logo
            </button>
          </div>

          {/* Image Preview */}
          {selectedImage && (
            <div className="ml-4">
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="Uploaded Preview"
                className="object-cover"
                width={200}
                height={200}
                onLoad={() => URL.revokeObjectURL(selectedImage)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
