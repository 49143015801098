import React from "react";
import { NavLink } from "react-router-dom";

export default function ProfileTile({ user, findimage }) {
  return (
    <NavLink to={"/" + user.url} className="group w-full [75%] md:w-full overflow-x-hidden">
      <div className="flex flex-row items-start w-full h-full  gap-2">
        <img
          src={user.profilepic || user.Profilepic}
          className="lg:w-[15%] md:w-[20%] w-1/4 h-auto aspect-square rounded-md"
          alt=""
        />
        <div className="w-full flex flex-col gap-0.5 md:gap-1">
          <div className="text-sm font-[550] group-hover:underline w-full">
            {user.Name}
          </div>
          <div className="text-xs text-gray-500">
            {user.currentRole.trim() ? user.currentRole : <br />}
          </div>
          <div className="flex flex-col gap-1">
            {user.Location.City.trim() !== "" && (
              <div className="text-sm flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="md:size-5 size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                  />
                </svg>
                <div className="text-xs text-gray-500">
                  {user.Location.City}
                </div>
              </div>
            )}
            {user.latestExperience && user.latestExperience.jobTitle && (
               <div className="text-sm flex items-center gap-1 w-full">
               <img
                 src={findimage(
                   user.latestExperience.company,
                   "job"
                 )}
                 alt=""
                 className="md:size-5 size-4 rounded-sm"
               />
               <span className="truncate block  text-xs text-gray-500 ">
                 {user.latestExperience.company}
               </span>
             </div>
            )}
            {user.latestEducation && user.latestEducation.levelofedu && (
              <div className="text-sm flex items-center gap-1 w-full">
              <img
                src={findimage(
                  user.latestEducation.school,
                  "school"
                )}
                alt=""
                className="size-4 md:size-5 rounded-sm"
              />{" "}
              <span className="truncate block text-xs text-gray-500 ">
                {user.latestEducation.school}
              </span>
            </div>
            )}
          </div>
        </div>
      </div>
    </NavLink>
  );
}
