import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase/setup";
import axios from "axios";
import apiUrl from "../../apiConfig";
import { FaSpinner } from "react-icons/fa";
const countryCodes = require("country-codes-list");

export default function Profile0({ isDarkMode }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [viewotp, setviewotp] = useState(false);
  const [user, setuser] = useState(null);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [phone, setphone] = useState("");
  const [hideconf, sethideconf] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSkip = async () => {
    try {
      navigate(`/signup/resume`);
      const reqbody = {
        ph: "",
        profilestatus: "/signup/resume",
      };
      const response = await axios.put(apiUrl.profile.sendsms + token, reqbody);
      if (response.status == 200) {
      }
    } catch (err) {
      // toast.error("something went wrong try again");
    }
  };
  // const [ setviewotp] = useContext(Otpcontext);
  const inputRefs = useRef(
    Array(6)
      .fill(null)
      .map(() => React.createRef())
  );
  const token = localStorage.getItem("token");
  const codesobj = countryCodes.customList(
    "countryCode",
    "+{countryCallingCode}"
  );
  useEffect(() => {
    const verifyDetails = async () => {
      try {
        const response = await axios.post(
          apiUrl.profile.getprofile + `${token}`
        );
        // // // console,log(response.data);
        if (response.data.existing_user.phone != " ") {
          // navigate("/")
        }
      } catch (error) {
        // // // console,log(error);
      }
    };

    const postProfileStatus = async () => {
      try {
        const reqbody = {
          profilestatus: "/signup/phoneno",
        };
        const response = await axios.post(
          apiUrl.profile.editprofile + `${token}`,
          reqbody
        );
        if (response.status == 200) {
        }
      } catch (error) {
        //console.log(error);
      }
    };

    verifyDetails();
    postProfileStatus();
  }, []);

  function onOTPVerify() {
    let flag = 1;
    for (let i = 0; i < otpValues.length; i++) {
      if (otpValues[i] === "") {
        flag = 0;
        break;
      }
    }
    if (flag == 0) {
      return;
    }

    const writtenOtp = otpValues.join("");
    verifyOtp();
  }

  const verifyOtp = async () => {
    // let flag = 1;
    // for (let i = 0; i < otpValues.length; i++) {
    //   if (otpValues[i] === "") {
    //     // toast("Enter correct OTP");
    //     flag = 0;
    //     break;
    //   }
    // }
    // if (flag == 0) {
    //   return;
    // }

    // const writtenOtp = otpValues.join("");
    // // phone otp working starts here
    try {
      // // // // console,log(user);
      // const data = await user.confirm(writtenOtp);
      const reqbody = {
        phone: phone,
      };
      // if (data)
      {
        const response = await axios.put(
          apiUrl.profile.sendsms + token,
          reqbody
        );
        //// // // console,log(response)
        if (response.status == 200) {
          // //// // // console,log(response.body);
          navigate(`/signup/resume`);
          window.scroll(0, 0);
        }
      }

      // // // console,log(reqbody);
    } catch (err) {
      // toast.error("something went wrong try again");
    }
  };

  const handleOtpChange = (index, value, isBackspace) => {
    if (isBackspace && index > 0 && value === "") {
      inputRefs.current[index - 1].current.focus();
    } else {
      const limitedValue = value.slice(0, 1);

      setOtpValues((prevValues) => {
        const newValues = [...prevValues];
        newValues[index] = limitedValue;
        return newValues;
      });

      if (index < inputRefs.current.length - 1 && limitedValue !== "") {
        inputRefs.current[index + 1].current.focus();
      }
    }
  };
  const [recap, setrecap] = useState("recaptcha");
  const submitform = async (data) => {
    const ph = data.extension + data.phone;
    try {
      const reqbody = {
        phone: ph,
        profilestatus: "/signup/resume",
      };
      const response = await axios.put(apiUrl.profile.sendsms + token, reqbody);
      if (response.status == 200) {
        navigate(`/signup/resume`);
      }
    } catch (err) {
      // toast.error("something went wrong try again");
    }
  };
  const handlepaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain");
    const otpArray = pastedData.split("").slice(0, 6);

    setOtpValues((prevValues) => {
      const newValues = [...prevValues];
      otpArray.forEach((value, index) => {
        if (inputRefs.current[index]) {
          newValues[index] = value;
        }
      });

      return newValues;
    });
    if (inputRefs.current[inputRefs.current.length - 1]) {
      inputRefs.current[inputRefs.current.length - 1].current.focus();
    }
  };
  const [timerotp, setTimerotp] = useState(60);
  const [disabled, setDisabled] = useState(true);

  const startTimerResend = () => {
    setTimerotp(60);

    const intervalId = setInterval(() => {
      setTimerotp((prevTimer) => prevTimer - 1);
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      setDisabled(false);
    }, 60000);
  };

  useEffect(() => {
    if (timerotp === 0) {
      setDisabled(false);
    }
  }, [timerotp]);

  const handleResendClick = () => {
    setDisabled(true); // Add this line to disable the button
    startTimerResend();
  };
  useEffect(() => {
    if (viewotp) {
      // If viewotp is true, focus on the first input field
      inputRefs.current[0].current.focus();
    }
  }, [viewotp]);

  return (
    <div className="">
      <div className=" h-1 w-full flex">
        <div className="h-full bg-[#0052ff] w-[12.5%]"></div>
        <div className="h-full  "></div>
      </div>
      <div id="recaptcha-container"></div>
      <div className="m-4 ">
        <div className="py-3 text-2xl font-[550] font-sans text-center">
          Add your phone number
        </div>
        <br />
        <br />
        <form
          onSubmit={handleSubmit(submitform)}
          className=" flex flex-col justify-center "
        >
          <label htmlFor="firstname" className=" font-[550]">
            Enter Phone number <span className=" text-red-500">*</span>
          </label>
          <div className="flex gap-2 justify-center  w-full items-center">
            <select
              className={`lg:w-[30%] p-2  focus:outline-none  border-[1.5px]  rounded-md my-2  ${
                isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : " "
              }`}
              required
              name="extension"
              {...register("extension")}
            >
              <option value="">Extension</option>
              {Object.keys(codesobj).map((item, key) => (
                <option value={codesobj[item]}>
                  {item} {codesobj[item]}
                </option>
              ))}

              {/* Add more options as needed */}
            </select>
            <input
              type="number"
              className={`w-[70%] p-2  focus:outline-none  border-[1.5px]  rounded-md my-2  ${
                isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : " "
              }`}
              name="firstname"
              required
              {...register("phone")}
            />

            {/* <button
              type="submit"
              className=" text-[#0052ff] font-[550] hover:text-[#014cec] "
            >
              send OTP
            </button> */}
          </div>
          <br />
          <div className="flex flex-row-reverse  w-full justify-between">
            {loading ? (
              <FaSpinner size={30} className=" text-[#0052ff] animate-spin" />
            ) : (
              <button
                type="submit"
                className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
              >
                Next
              </button>
            )}
            <button
              className="text-[#0052ff]  font-[550] "
              onClick={handleSkip}
            >
              Skip
            </button>
          </div>
        </form>
        {hideconf && (
          <div className="flex justify-center">
            <div id="recaptcha"></div>
          </div>
        )}
        <br />
        {viewotp && (
          <form onPaste={handlepaste} className="text-center ">
            <div className=" font-[550]">Enter OTP:</div>
            <div className="flex justify-center p-4 m-4">
              {otpValues.map((value, index) => (
                <input
                  key={index}
                  type="number"
                  name={`otp-${index}`}
                  id={`otp-${index}`}
                  className={`border-b-2 m-2 md:m-4 w-8 text-center p-2 ${
                    isDarkMode ? "border-[#2a303c] bg-[#3b4354]" : "bg-white"
                  }`}
                  required
                  value={value}
                  onChange={(e) => {
                    handleOtpChange(index, e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace" && value === "") {
                      handleOtpChange(index, "", true);
                    }
                  }}
                  ref={inputRefs.current[index]}
                />
              ))}
            </div>

            <button
              type="button"
              onClick={onOTPVerify}
              className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
            >
              Verify
            </button>
            {/* <div className="py-4 my-2 text-center w-full">
              Not received your code?{" "}
              <button
                className={`font-[550]  ${
                  disabled ? "text-gray-500" : "text-[#0052ff]"
                }`}
                onClick={handleResendClick}
                disabled={disabled}
              >
                Resend code {disabled ? `(${timerotp}s)` : ""}
              </button>
            </div> */}
          </form>
        )}
      </div>
    </div>
  );
}
