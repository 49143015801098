function getConnectionLabel(connectionCount) {
    let label;
  
    switch (true) {
      case (connectionCount === 0):
        label = "0 Connections";
        break;
      case (connectionCount === 1):
        label = "1 Connection";
        break;
      case (connectionCount === 2):
        label = "2 Connections";
        break;
      case (connectionCount === 3):
        label = "3 Connections";
        break;
      case (connectionCount === 4):
        label = "4 Connections";
        break;
      case (connectionCount === 5):
        label = "5 Connections";
        break;
      case (connectionCount <= 10):
        label = "10+ Connections";
        break;
      case (connectionCount <= 25):
        label = "25+ Connections";
        break;
      case (connectionCount <= 50):
        label = "50+ Connections";
        break;
      case (connectionCount <= 100):
        label = "100+ Connections";
        break;
      case (connectionCount <= 200):
        label = "200+ Connections";
        break;
      case (connectionCount <= 300):
        label = "300+ Connections";
        break;
      case (connectionCount <= 400):
        label = "400+ Connections";
        break;
      case (connectionCount <= 1000):
        label = "1000+ Connections";
        break;
      default:
        label = `${connectionCount}+ Connections`;
    }
  
    return label;
  }

  export default getConnectionLabel;