import React from "react";
import { GoPlus } from "react-icons/go";
import Education from "../Education";
import { IoIosArrowUp } from "react-icons/io";
import axios from "axios";
import { useState, useEffect } from "react";
import apiUrl from "../../apiConfig";
import DefaultEducation from "../../images/DefaultEducation.png";

export default function EducationComponent({
  dash,
  setshowediteducation,
  showAllEducation,
  setShowAllEducation,
  showediteducation,
  publicview,
  isDarkMode,
  api,
  setapi,
}) {
  const token = localStorage.getItem("token");

  const [universities, setUniversities] = useState([]);
  useEffect(() => {
    // Fetch university names from the backend when the component mounts

    const fetchUniversities = async () => {
      // // // console,log(apiUrl.universities.getuniversities)
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        // // // console,log(response.data);
        setUniversities(response.data);
        // setUniversities(response.data);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversities();
  }, []);

  const transformExperienceData = (education) => {
    // // console,log(education);
    const result = [];
    let lastCompany = null;

    education.forEach((item) => {
      const role = {
        city: item.city,
        country: item.country,
        field: item.field,
        fromMonth: item.fromMonth,
        fromYear: item.fromYear,
        levelofedu: item.levelofedu,
        school: item.school,
        toMonth: item.toMonth,
        toYear: item.toYear,
        graduated : item && item.graduated ? true : false,
        _id: item._id,
      };
      if (lastCompany && lastCompany.school === item.school) {
        lastCompany.roles.push(role);
      } else {
        lastCompany = {
          school: item.school,
          roles: [role],
        };
        result.push(lastCompany);
      }
    });
    // // console,log(result);
    return result;
  };

  const transformedExperience = transformExperienceData(dash.education);
  const visibleItemsEducation = showAllEducation
    ? transformedExperience
    : transformedExperience.slice(0, 2);

  return (
    <div
      className={`w-full px-4 border-[1px] py-3 md:pt-5 md:px-6 md:rounded-md  ${
        isDarkMode ? "bg-[#3b4354]" : "bg-white"
      }`}
    >
      <div className="text-lg font-[550] flex justify-between ">
        Education{" "}
        {!publicview && (
          <div className="flex gap-2">
            {" "}
            <div
              onClick={() => setshowediteducation(!showediteducation)}
              className=" w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer"
            >
              <GoPlus />
            </div>
          </div>
        )}
      </div>
      {/* <TimelineComponent /> */}
      {dash.education.length !== 0 ? (
        visibleItemsEducation.map((item, key) => {
          // Find the corresponding university object
          const university = universities.find(
            (obj) => obj.Name === item.school
          );
          // Get the image link if the university is found
          const image = university ? university.s3Link : DefaultEducation;
          return (
            <Education
              key={key}
              props={{
                token: token,
                item: item,
                image: image,
                publicview: publicview,
                isDarkMode: isDarkMode,
                api: api,
                setapi: setapi,
                length: visibleItemsEducation.length,
                key: key,
              }}
            />
          );
        })
      ) : (
        <div className="text-gray-500 text-md w-full text-center">
          {" "}
          No Education mentioned{" "}
        </div>
      )}
      {transformedExperience.length > 2 && (
        <div className="flex w-full justify-center mt-2 items-center  border-slate-300">
          {/* <hr className="w-[30%]" /> */}
          <button
            className=" border-2 p-2 px-4 rounded-3xl flex gap-2 items-center justify-center hover:bg-gray-200 focus:border-black duration-300"
            onClick={() => setShowAllEducation(!showAllEducation)}
          >
            {showAllEducation
              ? "Show Less "
              : `Show All ${dash.education.length} `}{" "}
            <IoIosArrowUp
              className={showAllEducation ? "rotate-0" : "rotate-180"}
            />
          </button>
          {/* <hr className="w-[30%]" /> */}
        </div>
      )}
    </div>
  );
}
