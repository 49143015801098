import React from "react";
import { LuPencilLine } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import EditEduModal2 from "./EditEduModal2";
import apiUrl from "../apiConfig";
import DeleteModal from "./DeleteModal";
import { useContext } from "react";
import DashSecOptions from "./DashSecOptions";

export default function Education({ props }) {
  const [showModal, setShowModal] = useState(false);
  const [desc, setdesc] = useState(false);

  const [rolenumber, setrolenumber] = useState(0);
  const [showediteducation, setshowediteducation] = useState(false);
  const onClick = () => {
    setShowModal(!showModal);
    props.setapi(!props.api);
  };

  const handleDeleteEducation = async () => {
    try {
      const exp1 = {
        company: props.item.company,
        country: props.item.country,
        ...props.item.roles[rolenumber],
      };

      const response = await axios.post(
        apiUrl.profile.deleteprofile + props.token,
        {
          education: exp1,
        }
      );
      // // console,log(response);
      if (response.status == 200) {
        // alert("Deleted Successfully");
        setShowModal(!showModal);
        props.setapi(!props.api);
        // Reload the page after successful deletion
        // .location.reload();
      } else {
        // // // console,log(response.message);
      }
    } catch (error) {
      // // // console,log(error.response.body);
    }
  };

  const [exp, setexp] = useState({});
  const handleEdit = (number) => {
    setrolenumber(number);
    const exp1 = {
      company: props.item.company,
      country: props.item.country,
      ...props.item.roles[number],
    };
    setexp(exp1);
    setshowediteducation(!showediteducation);
    props.setapi(!props.api);
  };

  const getMonthNumber = (monthName) => {
    const months = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };

    return months[monthName] || "Invalid month";
  };
  const gettotalmonthsyears = (month1, year1, month2, year2) => {
    // Parse the start and end dates, handling invalid input gracefully
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString("default", {
      month: "long",
    });
    const currentYear = currentDate.getFullYear();

    const startMonth = month1 || currentMonth;
    const startYear = year1 || currentYear;
    const startDate = new Date(startYear, getMonthNumber(startMonth) - 1, 1);
    const endDate = new Date(year2, getMonthNumber(month2) - 1, 1);
    // Ensure valid dates
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      return "Invalid date input";
    }

    // Calculate the difference in years and months
    let years = startDate.getFullYear() - endDate.getFullYear();
    let months = startDate.getMonth() - endDate.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    // Return the difference as a string
    if (years === 0) {
      return `${months} ${months === 1 ? "month" : "months"}`;
    }
    if (months === 0) {
      return `${years} ${years === 1 ? "year" : "years"}`;
    }
    return `${years} ${years === 1 ? "year" : "years"} and ${months} ${
      months === 1 ? "month" : "months"
    }`;
  };

  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  const updateDivHeight = () => {
    if (divRef.current) {
      const height = divRef.current.offsetHeight;
      setDivHeight(height);
    }
  };

  useEffect(() => {
    // Calculate height on mount and when roles change
    updateDivHeight();
    // Add a resize event listener to handle responsiveness
    window.addEventListener("resize", updateDivHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateDivHeight);
    };
  }, [props.item.roles]);

  return (
    <div className="pt-2">
      {showediteducation && (
        <EditEduModal2
          vieweditprofile={showediteducation}
          setvieweditprofile={setshowediteducation}
          values={exp}
          isDarkMode={props.isDarkMode}
          api={props.api}
          setapi={props.setapi}
        />
      )}
      {showModal && (
        <DeleteModal
          onClick={onClick}
          handleDeleteEducation={handleDeleteEducation}
          isDarkMode={props.isDarkMode}
          inst={props.item.roles[rolenumber].levelofedu}
          name={"education"}
        />
      )}

      <div className="flex justify-between w-[95%] ">
        <div className="flex gap-2 flex-col  w-full ">
          <div className=" flex gap-2 items-center">
            <div className="md:h-[50px] h-[40px] aspect-square">
              {props.image && (
                <img
                  src={props.image}
                  alt="Company Logo"
                  className={
                    props.image.startsWith("https://")
                      ? " rounded-md "
                      : " rounded-md  border-[1.5px] p-1 border-black "
                  }
                />
              )}
            </div>
            <div className=" flex flex-col  justify-center h-full ">
              <p className="font-[550]">{props.item.school} </p>
              <p className="text-sm text-gray-400">
                {/* {gettotalmonthsyears(
                  props.item.roles[0].toMonth,
                  props.item.roles[0].toYear,
                  props.item.roles[props.item.roles.length - 1].fromMonth,
                  props.item.roles[props.item.roles.length - 1].fromYear
                )} */}
                {props.item.roles[0] && props.item.roles[0].graduated ? (
                  <span>
                    <span>Class of {props.item.roles[0].toYear}</span>
                    <span className="px-1">·</span>
                    <span>Graduated</span>
                  </span>
                ) : (
                  <div>
                    <span>Class of {props.item.roles[0].toYear}</span>
                  </div>
                )}
              </p>
            </div>
          </div>

          <div className="text-md w-full relative flex gap-2 ">
            <div className="w-[40px] md:w-[50px] relative">
              <div className=" absolute h-full border-[1px]  left-1/2 "></div>
            </div>
            <div className="flex flex-col gap-0 w-full">
              <div className=" flex flex-col gap-4 w-full ">
                {props.item.roles.map((role, key) => (
                  <div
                    key={key}
                    className=" flex justify-between items-start w-full relative"
                  >
                    {key === props.item.roles.length - 1 && (
                      <div
                        style={{
                          // height: `85%`, // Apply the calculated height here if needed
                          top: `${divHeight / 2 + 1.5}px`, // Dynamically apply the top value
                        }}
                        className="absolute border-[5px] sm:h-[91%] h-[84%]  border-white left-[-27px] md:left-[-32px]"
                      ></div>
                    )}

                    <div className="flex flex-col gap-0  ">
                      <div ref={divRef} className=" flex relative">
                        <div className="absolute left-[-25px] top-1/2 md:left-[-30px] border-[1px]  w-[20px] md:w-[25px]  " />
                        <h1 className="text-sm font-[550]  px-1">
                          {role.levelofedu}
                        </h1>
                      </div>
                      <p className="text-sm  px-1">{role.field} </p>
                      <p className="text-sm text-gray-400 px-1">
                        <span>
                          {role.fromMonth} {role.fromYear} -{" "}
                          <span>
                            {role.toMonth} {role.toYear}
                          </span>
                        </span>
                        <span>
                          <span className={role.city ? " px-1" : "hidden"}>
                            {" "}
                            ·
                          </span>{" "}
                          {role.city}
                        </span>
                      </p>
                    </div>
                    {!props.publicview && (
                      <div className="">
                        <DashSecOptions
                          edititem={() => handleEdit(key)}
                          deleteitem={() => {
                            // // console,log(key, props.item.roles[key].school);
                            setrolenumber(key);
                            setShowModal(!showModal);
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.length - 1 !== props.key && (
        <hr className="text-center w-full mt-2" />
      )}
    </div>
  );
}
