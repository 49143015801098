import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import countryData from "../asserts/Countrylist";
import apiUrl from "../apiConfig";
import Spinnerpage from "./Spinnerpage";
import { FaSpinner } from "react-icons/fa";

export default function EditProfileModel({
  vieweditprofile,
  setvieweditprofile,
  isDarkMode,
  api,
  setapi
}) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const { handleSubmit, register, formState: errors } = useForm();
  const navigate = useNavigate();
  const [loadingsubmitform, setloadingsubmitform] = useState(false);
  const token = localStorage.getItem("token");
  function createEmptyEducation() {
    return {
      levelofedu: " ",
      field: " ",
      school: " ",
      city: " ",
      Country: " ",
      fromMonth: " ",
      fromYear: " ",
    };
  }

  function createEmptyJob() {
    return {
      jobTitle: " ",
      company: " ",
      Country: " ",
      city: " ",
      fromMonth: " ",
      fromYear: " ",
      description: " ",
      toMonth: " ",
      toYear: " ",
    };
  }

  function createEmptySurvey() {
    return {
      gender: " ",
      race: {
        isAsian: false,
        isPacific: false,
        isBlack: false,
        isWhite: false,
        isLatinx: false,
        isNotListed: false,
        isNativeAmerican: false,
      },
      sex: " ",
      age: " ",
      militarystatus: " ",
    };
  }

  function createEmptysociaclaccounts() {
    return {
      socialaccounts: {
        LinkedIn: "",
        GitHub: "",
        HackerRank: "",
        CodeChef: "",
        WebSite: "",
      },
    };
  }

  function createEmptyProfile() {
    return {
      DOB: "",
      FullName: {
        FirstName: "",
        MiddleName: "",
        LastName: "",
        DisplayFirstName: "",
        DisplayMiddleName: "",
        DisplayLastName: "",
      },
      Location: {
        Country: "",
        StreetAddress: "",
        City: "",
        PinCode: "",
      },
      education: [createEmptyEducation()],
      experience: [createEmptyJob()],
      skills: [],
      currentRole: "",
      socialaccounts: createEmptysociaclaccounts(),
      WorkLocation: [],
      Survey: createEmptySurvey(),
      componentOrder: [
        "Recognitions",
        "ProjectsComponent",
        "SkillsComponent",
        "ExperienceComponent",
        "EducationComponent",
      ],
    };
  }
  const [dash, setdash] = useState(createEmptyProfile);

  const [city, setCity] = useState("");
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [currentRole, setcurrentRole] = useState("");
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const getprofile = async () => {
      setloading(true);
      try {
        const response = await axios.post(apiUrl.profile.getprofile + token, {
          url: "",
        });
        //// // console,log(response.status);
        setdash(response.data.existing_profile);
        setCity(response.data.existing_profile.Location.City);
        setfirstname(response.data.existing_profile.FullName.FirstName);
        setlastname(response.data.existing_profile.FullName.LastName);
        setcurrentRole(response.data.existing_profile.currentRole);
      } catch (err) {
        if (err.response.status === 401) {
          // localStorage.removeItem("token");
          // navigate("/login");
        }
      } finally {
        setloading(false);
      }
    };

    getprofile();
    //// // console,log("working");
  }, [token]);
  const submitform = async () => {
    // // console,log(data);
    setloadingsubmitform(true);
    const reqbody = {
      FullName: {
        FirstName: firstname,
        LastName: lastname,
        DisplayName: firstname + " " + lastname,
      },
      Location: {
        City: city,
        StreetAddress: dash.Location.StreetAddress,
        Country: dash.Location.Country,
        PinCode: dash.Location.PinCode,
      },
      currentRole: currentRole,
    };

    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      if (response.status === 200) {
        setapi(!api);
        closemodal();
      }
    } catch (err) {
      // console.error(err);
      // Handle the error as needed
    } finally {
      setloadingsubmitform(false);
    }
  };
  const closemodal = () => {
    setvieweditprofile(!vieweditprofile);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setvieweditprofile(!vieweditprofile)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                    isDarkMode
                      ? "bg-[#2a303c] border-[#2a303c]"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="text-2xl font-[550]">
                      Edit Personal Details
                    </div>
                    <IoMdClose
                      size={30}
                      onClick={closemodal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  {loading ? (
                    <div className="w-full h-full flex justify-center items-center">
                      <div className="flex space-x-2 w-20">
                        <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink" />
                        <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-1" />
                        <div className="w-2 h-2 bg-[#0052ff] rounded-full animate-grow-shrink-delay-2" />
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <form
                        action=""
                        onSubmit={handleSubmit(submitform)}
                        className=" flex flex-col gap-2"
                      >
                        <div className="">
                          <label htmlFor="firstname" className=" font-[550]">
                            First Name
                          </label>
                          <input
                            type="text"
                            className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                              isDarkMode
                                ? "border-[#3b4354] bg-[#3b4354] "
                                : "bg-white text-black"
                            }`}
                            name="firstname"
                            value={firstname}
                            onChange={(e) => setfirstname(e.target.value)}
                            required
                          />
                        </div>
                        {/* <div className="">
                        <label htmlFor="middlename" className=" font-[550]">
                          Middle Name
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode
                              ? "border-[#3b4354] bg-[#3b4354] "
                              : "bg-white text-black"
                          }`}
                          name="middlename"
                          defaultValue={dash.FullName.MiddleName}
                          {...register("MiddleName")}
                        />
                      </div> */}
                        <div>
                          <label htmlFor="lastname" className=" font-[550]">
                            Last Name
                          </label>
                          <input
                            type="text"
                            //   placeholder="Last Name"
                            className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                              isDarkMode
                                ? "border-[#3b4354] bg-[#3b4354] "
                                : "bg-white text-black"
                            }`}
                            name="lastname"
                            value={lastname}
                            onChange={(e) => setlastname(e.target.value)}
                            required
                          />
                        </div>
                        <div>
                          <label htmlFor="currentRole" className=" font-[550]">
                            Headline
                          </label>
                          <input
                            type="text"
                            //   placeholder="Last Name"
                            className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                              isDarkMode
                                ? "border-[#3b4354] bg-[#3b4354] "
                                : "bg-white text-black"
                            }`}
                            name="currentRole"
                            value={currentRole}
                            onChange={(e) => setcurrentRole(e.target.value)}
                          />
                        </div>
                        <div>
                          <label htmlFor="City" className="font-[550]">
                            City
                          </label>
                          <input
                            type="text"
                            //   placeholder="Last Name"
                            className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                              isDarkMode
                                ? "border-[#3b4354] bg-[#3b4354] "
                                : "bg-white text-black"
                            }`}
                            name="currentRole"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                        </div>

                        <div className="flex flex-row-reverse pt-4">
                          {loadingsubmitform ? (
                            <FaSpinner
                              size={30}
                              className="text-[#0052ff] animate-spin"
                            />
                          ) : (
                            <div>
                              {/* <button
                    onClick={() => {navigate("/profile/page3");
                    window.scroll(0, 0)}}
                      className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                    >
                      Back
                    </button> */}
                              <button
                                type="submit"
                                className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
