import React from "react";
import { GoPlus } from "react-icons/go";
import Projects from "../Projects";
import { IoIosArrowUp } from "react-icons/io";
import axios from "axios";
import { useState, useEffect } from "react";
import apiUrl from "../../apiConfig";
import DefaultCompany from "../../images/Defaultproject.png";

export default function ProjectsComponent({
  dash,
  setshowprojectmodal,
  showprojectmodal,
  // visibleItemsprojects,
  setShowAllProjects,
  showAllProjects,
  publicview,
  isDarkMode,
  api,
  setapi,
}) {
  const token = localStorage.getItem("token");
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        const response1 = await axios.get(apiUrl.companies.getcompanies);
        // // // console,log(response.data);

        setCompanies(response.data.concat(response1.data));
      } catch (error) {
        // console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const transformExperienceData = (experience) => {
    const result = [];
    let lastCompany = null;

    experience.forEach((item) => {
      const role = {
        Title: item.Title,
        company: item.company,
        fromMonth: item.fromMonth,
        fromYear: item.fromYear,
        description: item.description,
        skills: item.skills,
        link: item.link,
        toMonth: item.toMonth,
        toYear: item.toYear,
        _id: item._id,
      };
      if (lastCompany && lastCompany.company === item.company) {
        lastCompany.roles.push(role);
      } else {
        lastCompany = {
          company: item.company,
          roles: [role],
        };
        result.push(lastCompany);
      }
    });
    // // console,log(result);
    return result;
  };

  const transformedExperience = transformExperienceData(dash.projects);
  const visibleItemsprojects = showAllProjects
    ? transformedExperience
    : transformedExperience.slice(0, 2);

  return (
    <div>
      <div
        className={`w-full  border-[1px] px-4 py-3 md:py-5 md:px-6 md:rounded-md  ${
          isDarkMode ? "bg-[#3b4354]" : "bg-white"
        }`}
      >
        <div className="text-lg font-[550] flex justify-between ">
          Projects{" "}
          {!publicview && (
            <div className="flex gap-4">
              <div
                onClick={() => setshowprojectmodal(!showprojectmodal)}
                className=" w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer"
              >
                <GoPlus />
              </div>
            </div>
          )}
        </div>
        <div>
          {dash.projects.length !== 0 ? (
            visibleItemsprojects.map((item, key) => {
              const company = companies.find(
                (obj) => obj.Name === item.company
              );
              // Get the image link if the university is found
              const image = company ? company.s3Link : DefaultCompany;
              return (
                <Projects
                  key={key}
                  props={{
                    token: token,
                    item: item,
                    publicview: publicview,
                    isDarkMode: isDarkMode,
                    api: api,
                    setapi: setapi,
                    length: visibleItemsprojects.length,
                    key: key,
                    image: image,
                  }}
                />
              );
            })
          ) : (
            <div className="text-gray-500 text-md w-full text-center">
              {" "}
              No Projects mentioned{" "}
            </div>
          )}
          {dash.projects.length > 2 && (
            <div className="flex w-full justify-center mt-2 items-center  border-slate-300">
              {/* <hr className="w-[30%]" /> */}
              <button
                className=" border-2 p-2 px-4 rounded-3xl flex gap-2 items-center justify-center hover:bg-gray-200 focus:border-black duration-300"
                onClick={() => setShowAllProjects(!showAllProjects)}
              >
                {showAllProjects
                  ? "Show Less"
                  : `Show All ${dash.projects.length}`}{" "}
                <IoIosArrowUp
                  className={showAllProjects ? "rotate-0" : "rotate-180"}
                />
              </button>
              {/* <hr className="w-[30%]" /> */}
            </div>
          )}
          {/* <Projects /> */}
        </div>
      </div>
    </div>
  );
}
