import React, { useState } from "react";

const Tooltip = ({ children, text }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div className="z-[5] absolute md:top-0 top-7 md:left-7 left-0 border-[1px] origin-top-left mb-2 px-4 py-2 bg-white text-black text-sm rounded-md shadow-lg md:w-[300px] w-[250px] break-words">
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
