import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { logincontext } from "../contexts/Logincontext";
import MailOtp from "./MailOtp";
// import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner } from "react-icons/fa";
import { IoMdInformationCircleOutline } from "react-icons/io";
import WaitlistModal from "./WaitlistModal";

export default function Signupform({ isDarkMode }) {
  const [
    currentuser,
    loginerror,
    UserloginStatus,
    Loginuser,
    Signupuser,
    VerifyOTP,
    Signupadmin,
    Logoutuser,
  ] = useContext(logincontext);
  const [viewmailotp, setviewotp] = useState(false);
  const [data, setdata] = useState();
  const [showpassword, setshowpassword] = useState("password");

  const [password, setPassword] = useState("");
  const [validLength, setValidLength] = useState(false);
  const [containsUpperCase, setContainsUpperCase] = useState(false);
  const [containsLowerCase, setContainsLowerCase] = useState(false);
  const [containsSpecialChar, setContainsSpecialChar] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [showrules, setshowrules] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, seterror] = useState(false);
  const [waitlistmodal, setwaitlistmodal] = useState(false);
  const handleChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setValidLength(newPassword.length >= 8);
    setContainsUpperCase(/[A-Z]/.test(newPassword));
    setContainsLowerCase(/[a-z]/.test(newPassword));
    setContainsSpecialChar(
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(newPassword)
    );
    setContainsNumber(/\d/.test(newPassword));
  };
  const [errorgot, seterrorgot] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const showpass = () => {
    const type = showpassword === "password" ? "text" : "password";
    setshowpassword(type);
  };
  const submitform = async (data) => {
    setLoading(!loading);
    seterror(false);
    // // // console,log(password);
    if (password !== data.cpassword) {
      // toast.error("Passwords not matching");
      setLoading(false);
      seterror(true);
      window.scroll(0, 0);
      setviewotp(false);
      seterrorgot("Passwords not matching");
      return;
    }
    // // // // console,log(data);
    else {
      const { cpassword, ...userdata } = data;
      userdata["password"] = password;
      userdata["phone"] = " ";
      // // // console,log(userdata);
      setdata(userdata);
      const resdata = await Signupuser(userdata);
      // // console,log(resdata);

      if (resdata) {
        setLoading(false);
        seterror(true);
        seterrorgot(resdata);
        window.scroll(0, 0);
        // alert("email already present");
        return;
      }
    }

    setviewotp(!viewmailotp);

    // toast.success("OTP successfully sent");
  };

  return (
    <div>
      {" "}
      {viewmailotp && (
        <MailOtp
          viewmailotp={viewmailotp}
          setviewotp={setviewotp}
          Signupuser={Signupuser}
          data={data}
          setdata={setdata}
          verifyOTP={VerifyOTP}
          isDarkMode={isDarkMode}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {waitlistmodal && (
        <WaitlistModal
          vieweditprofile={waitlistmodal}
          setvieweditprofile={setwaitlistmodal}
          isDarkMode={isDarkMode}
        />
      )}
      <div className="py-3">
        <h1 className="text-center  text-2xl font-[550] font-sans">
          Create your Aspireup profile
        </h1>
        <p className="text-center md:text-base text-sm ">
          We’re currently in Early Access. If you have an access code, please
          sign up below.
        </p>
      </div>
      <hr />
      {error && (
        <div className="text-red-700 flex justify-center items-center gap-1 pt-4 ">
          <IoMdInformationCircleOutline />
          {errorgot}
        </div>
      )}
      <form onSubmit={handleSubmit(submitform)} className="p-4">
        <input
          type="text"
          placeholder="First Name"
          className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2  ${
            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
          }`}
          name="name"
          required
          {...register("Firstname")}
        />

        <input
          type="text"
          placeholder="Last Name"
          className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2  ${
            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
          }`}
          name="name"
          required
          {...register("Lastname")}
        />
        <input
          type="email"
          placeholder="Email"
          className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2 ${
            errorgot === "Account with email already exists"
              ? "border-red-500"
              : ""
          }  ${isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : " "}`}
          name="Email"
          required
          {...register("email")}
        />
        <input
          type={showpassword}
          value={password}
          onChange={handleChange}
          placeholder="Password"
          className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2 ${
            errorgot === "Passwords not matching" ? "border-red-500" : ""
          }  ${isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : " "}`}
          name="Password"
          required
          onFocus={() => setshowrules(true)}
          onBlur={() => setshowrules(false)}
          // {...register("password", {
          //   required: "Password is required",
          //   pattern: {
          //     value:
          //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/,
          //     message:
          //       "Invalid password format. Hover on i to check the format",
          //   },
          // })}
        />
        {showrules && (
          <ul className="text-sm px-4 ">
            <li className={validLength ? "hidden" : "text-red-500"}>
              At least 8 characters
            </li>
            <li className={containsUpperCase ? "hidden" : "text-red-500"}>
              At least one uppercase letter
            </li>
            <li className={containsLowerCase ? "hidden" : "text-red-500"}>
              At least one lowercase letter
            </li>
            <li className={containsSpecialChar ? "hidden" : "text-red-500"}>
              At least one Special Character
            </li>
            <li className={containsNumber ? "hidden" : "text-red-500"}>
              At least one number
            </li>
          </ul>
        )}
        {/* <input
          type={showpassword}
          placeholder="Password"
          className="w-full p-2   border-[1.5px]  rounded-md my-2"
          name="password"
          required
          {...register("password")}
        /> */}
        {/* to insert i button to insert the rules of password */}
        {/* <div
          className={`w-full py-0.5 pr-2 items-center  border-[1.5px]  ${
            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
          }  rounded-md my-2 flex justify-between ${
            errors.password ? "border-red-500" : ""
          }`}
        >
          <input
            type={showpassword}
            placeholder="Password"
            name="password"
            className={`h-full p-2 focus:outline-none w-[90%]  ${
              isDarkMode
                ? "border-[#3b4354] bg-[#3b4354] "
                : "shadow-slate-300 "
            }`}
            required
            
          />
          <PopoverComponent isDarkMode={isDarkMode} />
        </div> */}

        <input
          type={showpassword}
          placeholder="Confirm Password"
          className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2 ${
            errorgot === "Passwords not matching" ? "border-red-500" : ""
          }  ${
            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : "shadow-slate-300 "
          }`}
          name="cpassword"
          required
          {...register("cpassword")}
        />

        <input
          type="text"
          placeholder="Access code"
          className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2 ${
            errorgot === "Invalid access code." ||
            errorgot === "Access code expired."
              ? "border-red-500"
              : ""
          } `}
          name="Accesscode"
          required
          {...register("accesscode")}
        />
        <div className="text-gray-700 text-sm">
          Interested to sign up, but no access code?{" "}
          <span
            className=" text-[#0052ff] font-[550] cursor-pointer"
            onClick={() => setwaitlistmodal(!waitlistmodal)}
          >
            {" "}
            Join the Waitlist
          </span>
        </div>
        <div className="pt-4">
          <input type="checkbox" name="show" id="show" onChange={showpass} />
          <label
            htmlFor="show"
            className="text-sm text-justify hover:cursor-pointer  px-2"
          >
            {" "}
            Show password
          </label>
        </div>
        <div className="pt-2 hover:cursor-pointer mb-6">
          <input type="checkbox" name="terms" id="terms" required />
          <label
            htmlFor="terms"
            className="text-sm text-justify hover:cursor-pointer  px-2"
          >
            By signing up you agree to Aspireup's Terms of Service and Privacy
            Policy.
          </label>
        </div>

        <hr />
        <div className="mt-6 flex md:flex-row flex-col justify-between px-4">
          <div className=" text-sm">
            Already have an account? <br />
            <NavLink
              to="/login"
              onClick={() => {
                window.scroll(0, 0);
              }}
              className=" text-[#0052ff] hover:text-[#014cec] font-[550] hover:underline"
            >
              {" "}
              Sign in{" "}
            </NavLink>
          </div>
          {loading ? (
            <FaSpinner className="animate-spin text-[#0052ff] mr-2" />
          ) : (
            <button
              type="submit"
              className="bg-[#0052ff] text-white   hover:bg-[#014cec]   font-[550] py-1 px-4 rounded-3xl md:w-[30%] w-[100%] my-4 md:my-0"
            >
              Register
            </button>
          )}
        </div>
        <br />
      </form>
    </div>
  );
}
