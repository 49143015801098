import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Months from "../asserts/Months";
import defaultproject from "../images/Defaultproject.png";
import { ImBin } from "react-icons/im";
import apiUrl from "../apiConfig";
import { FaSpinner } from "react-icons/fa";

export default function EditProjectModal2({
  vieweditprofile,
  setvieweditprofile,
  values,
  isDarkMode,
  api,
  setapi,
}) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const [loading, setloading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({});
  const [skil, setskills] = useState(values.skills);
  const [newSkill, setNewSkill] = useState("");

  const [schoolsAndCompanies, setSchoolsAndCompanies] = useState([]);

  useEffect(() => {
    const fetchUniversitiesAndCompanies = async () => {
      try {
        const response = await axios.get(apiUrl.universities.getuniversities);
        const response1 = await axios.get(apiUrl.companies.getcompanies);
        setSchoolsAndCompanies(response.data.concat(response1.data));
        const img = response.data
          .concat(response1.data)
          .find((obj) => obj.Name === values.company);
        setSelectedImage(img ? img.s3Link : defaultproject);
      } catch (error) {
        // console.error("Error fetching universities:", error);
      }
    };

    fetchUniversitiesAndCompanies();
  }, []);

  const handleSchoolOrCompanyChange = (e) => {
    const inputSchoolOrCompany = e.target.value;
    setSelectedSchoolOrCompany(inputSchoolOrCompany);
    // Filter schools based on input
    // You would replace usSchools with the array containing your school names
    // For the purpose of this example, let's assume we have a dummy array called usSchools

    const filtered1 = schoolsAndCompanies.filter((obj) =>
      obj.Name.toLowerCase().startsWith(inputSchoolOrCompany.toLowerCase())
    );

    const filtered2 = schoolsAndCompanies.filter((obj) =>
      obj.Name.toLowerCase().includes(inputSchoolOrCompany.toLowerCase())
    );

    // Concatenate the two filtered lists
    const uniqueItemsSet = new Set(filtered1.concat(filtered2));

    // Convert back to array
    const finalFiltered = Array.from(uniqueItemsSet);

    if (inputSchoolOrCompany == "") {
      setSelectedImage("");
    }
    if (finalFiltered.length > 0) {
      // setSelectedSchoolImage(filtered[0].s3Link);
    } else {
      setSelectedImage(defaultproject);
    }
    // // // console,log(finalFiltered);
    setFilteredSchoolsOrCompanies(finalFiltered);
  };

  const handleSchoolOrCompanySelect = (obj) => {
    setSelectedSchoolOrCompany(obj.Name);
    setSelectedImage(obj.s3Link);
    setFilteredSchoolsOrCompanies([]);
  };

  const [iniskills, setiniskills] = useState([]);

  const delitem = (itemToDelete) => {
    const updatedSkills = skil.filter((item) => item !== itemToDelete);
    setskills(updatedSkills);
    setiniskills([...iniskills, itemToDelete]);
  };

  const [selectedImage, setSelectedImage] = useState("");
  const [selectedSchoolOrCompany, setSelectedSchoolOrCompany] = useState(
    values.company
  );
  const [filteredSchoolsOrCompanies, setFilteredSchoolsOrCompanies] = useState(
    []
  );

  const handleAddNewSkill = () => {
    if (newSkill.trim() !== "" && !skil.includes(newSkill)) {
      setskills([...skil, newSkill]);
      setNewSkill("");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleAddNewSkill();
  };

  const handleNewSkillChange = (e) => {
    setNewSkill(e.target.value);
  };
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [check, setcheck] = useState(
    values.toMonth === "" && values.toYear === "" ? true : false
  );
  const submitform = async (data) => {
    setloading(true);
    if (data.isPursuing) {
      data.toMonth = "";
      data.toYear = "";
    }
    data.skills = skil;
    data.fromMonth = selectedMonth;
    data.fromYear = selectedYear;
    data.company = selectedSchoolOrCompany;
    const reqbody = {
      projects: data,
      id: values._id,
    };
    // // // // console,log(reqbody)

    try {
      const response = await axios.post(
        apiUrl.profile.editexp + token,
        reqbody
      );
      //// // // console,log(response);
      if (response.status == 200) {
        //// // // console,log(response.body);
        // Navigate to the next page or wherever you want
        setapi(!api);
        closemodal();
      }
    } catch (err) {
      //// // // console,log(err);
    }finally{
      setloading(false);
    }
  };
  const closemodal = () => {
    setvieweditprofile(!vieweditprofile);
  };

  const [toyears, settoyears] = useState(
    Months[1].slice(0, Months[1][0] - values.fromYear + 1)
  );
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedtoYear, setSelectedtoYear] = useState("");
  const yearselected = (e) => {
    setSelectedYear(e.target.value);
    settoyears(Months[1].slice(0, Months[1][0] - parseInt(e.target.value) + 1));
  };
  const [tomonths, settomonths] = useState(Months[0]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const monthsselected = (e) => {
    setSelectedMonth(e.target.value);
    // // // // console,log(values.fromYear === values.toYear || selectedYear === selectedtoYear)
    if (selectedYear === selectedtoYear) {
      settomonths(Months[0].slice(Months[0].indexOf(e.target.value)));
    }
  };
  useEffect(() => {
    // // // console,log(values.fromYear, values.toYear, selectedYear, selectedtoYear);
    if (selectedYear === selectedtoYear) {
      settomonths(Months[0].slice(Months[0].indexOf(values.fromMonth)));
    } else {
      settomonths(Months[0]);
    }
  }, [selectedYear, selectedtoYear]);

  useEffect(() => {
    setSelectedMonth(values.fromMonth);
    setSelectedYear(values.fromYear);
    // // // console,log(values.fromYear, values.toYear, selectedYear, selectedtoYear);
    if (values.fromYear === values.toYear) {
      settomonths(Months[0].slice(Months[0].indexOf(values.fromMonth)));
    } else {
      settomonths(Months[0]);
    }
    setSelectedtoYear(values.toYear);
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setvieweditprofile(!vieweditprofile)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                    isDarkMode
                      ? "bg-[#2a303c] border-[#2a303c]"
                      : "bg-white text-black"
                  }`}
                >
                  {" "}
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="text-2xl font-[550]">Edit Project</div>
                    <IoMdClose
                      size={30}
                      onClick={closemodal}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  <br />
                  <div className="">
                    <form
                      action=""
                      onSubmit={handleSubmit(submitform)}
                      className="flex flex-col gap-4"
                    >
                      <div className="relative">
                        <label htmlFor="company" className="font-[550]">
                          Company or School{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <div
                          className={`w-full p-2 border-[1.5px] flex gap-4 items-center rounded-md my-2 ${
                            isDarkMode
                              ? `border-[${apiUrl.themes.light_dark}] bg-[${apiUrl.themes.light_dark}] `
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedImage && (
                            <img
                              src={selectedImage}
                              alt="School Logo"
                              className="h-6 w-6 ml-2"
                            />
                          )}
                          <input
                            type="text"
                            className="focus:outline-none w-[99%]"
                            name="company"
                            {...register("company", {
                              maxLength: {
                                value: 100,
                                message:
                                  "Company must not exceed 100 characters",
                              },
                              pattern: {
                                value:
                                  /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                                message:
                                  "Company can only contain alphanumeric characters, spaces, underscores, and hyphens",
                              },
                            })}
                            value={selectedSchoolOrCompany}
                            onChange={handleSchoolOrCompanyChange}
                          />
                        </div>
                        {errors.company && (
                          <p className="text-red-500 text-sm">
                            {errors.company.message}
                          </p>
                        )}
                        {/* Dropdown menu for filtered schools */}
                        {filteredSchoolsOrCompanies.length > 0 && (
                          <div
                            id="schoolAndCompanyDropdown"
                            className="absolute z-10 mt-1 bg-white border rounded-md w-[100%]  max-h-[150px] overflow-y-auto shadow-lg"
                          >
                            {filteredSchoolsOrCompanies.map((obj, index) => (
                              <div
                                key={index}
                                className="cursor-pointer flex hover:bg-gray-100 p-2"
                                onClick={() => handleSchoolOrCompanySelect(obj)}
                              >
                                <img
                                  src={obj.s3Link}
                                  alt="School or Company Logo"
                                  className="h-6 w-6 mr-2 rounded-sm"
                                />
                                {/* Display School logo */}
                                {obj.Name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div>
                        <label htmlFor="Title" className="font-[550]">
                          Title <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode
                              ? "border-[#3b4354] bg-[#3b4354] "
                              : "bg-white text-black"
                          }`}
                          name="Title"
                          defaultValue={values.Title}
                          required
                          {...register("Title", {
                            maxLength: {
                              value: 200,
                              message: "Title must not exceed 200 characters",
                            },
                            pattern: {
                              value:
                                /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9\s_\-&(),.']+$/,
                              message:
                                "Title can only contain alphanumeric characters, spaces, underscores, and hyphens",
                            },
                          })}
                        />
                        {errors.Title && (
                          <p className="text-red-500 text-sm">
                            {errors.Title.message}
                          </p>
                        )}
                      </div>

                      <div>
                        <div>
                          <label
                            htmlFor="fromMonth"
                            className="font-[550] block"
                          >
                            From <span className="text-red-500">*</span>
                          </label>
                          <div className="flex justify-between">
                          <select
                              className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  ${
                                isDarkMode
                                  ? "border-[#3b4354] bg-[#3b4354] "
                                  : ""
                              }`}
                              name="fromYear"
                              required
                              {...register("fromYear")}
                              value={selectedYear}
                              onChange={yearselected}
                            >
                              <option value="">Year</option>
                              {Months[1].map((year, index) => (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                            <select
                              className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  ${
                                isDarkMode
                                  ? "border-[#3b4354] bg-[#3b4354] "
                                  : "bg-white text-black"
                              }`}
                              name="fromMonth"
                              required
                              {...register("fromMonth")}
                              value={selectedMonth}
                              onChange={monthsselected}
                            >
                              <option value="">Month</option>
                              {Months[0].map((month, index) => (
                                <option key={index} value={month}>
                                  {month}
                                </option>
                              ))}
                            </select>
                            
                          </div>
                        </div>

                        <div className="py-3 hover:cursor-pointer">
                          <input
                            type="checkbox"
                            name="pursuing"
                            id="pursuing"
                            defaultChecked={
                              values.toMonth === "" && values.toYear === ""
                                ? true
                                : false
                            }
                            {...register("isPursuing")}
                            onChange={() => {
                              setcheck(!check);
                            }}
                          />
                          <label
                            htmlFor="pursuing"
                            className="text-sm text-justify hover:cursor-pointer px-2"
                          >
                            I am currently working on this project
                          </label>
                        </div>

                        {!check || check === "false" ? (
                          <div>
                            <label
                              htmlFor="toMonth"
                              className="font-[550] block"
                            >
                              To <span className="text-red-500">*</span>
                            </label>
                            <div className="flex justify-between">
                            <select
                                className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  ${
                                  isDarkMode
                                    ? "border-[#3b4354] bg-[#3b4354] "
                                    : "bg-white text-black"
                                }`}
                                name="toYear"
                                required
                                defaultValue={values.toYear}
                                {...register("toYear")}
                                onChange={(e) =>
                                  setSelectedtoYear(e.target.value)
                                }
                              >
                                <option value="">Year</option>
                                {toyears.map((year, index) => (
                                  <option key={index} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              <select
                                className={`w-[48%] py-2    border-[1.5px]  rounded-md my-2  ${
                                  isDarkMode
                                    ? "border-[#3b4354] bg-[#3b4354] "
                                    : "bg-white text-black"
                                }`}
                                name="toMonth"
                                required
                                {...register("toMonth")}
                                defaultValue={values.toMonth}
                              >
                                <option value="">Month</option>
                                {tomonths.map((month, index) => (
                                  <option key={index} value={month}>
                                    {month}
                                  </option>
                                ))}
                              </select>
                              
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <label htmlFor="skills" className="font-[550]">
                          Skills
                        </label>
                        <div className="quill-container">
                          <div className="w-full h-[40vh] border-[1.5px] rounded-2xl my-2 overflow-y-auto">
                            <ul className="flex flex-wrap p-4">
                              <div className="w-full justify-center flex items-center gap-2 ">
                                <div className="my-2">
                                  <input
                                    type="text"
                                    className={`p-2 border-[1.5px] rounded-md mx-3 ${
                                      isDarkMode
                                        ? "border-[#3b4354] bg-[#3b4354] "
                                        : "bg-white text-black"
                                    }`}
                                    placeholder="Type a new skill"
                                    value={newSkill}
                                    onChange={handleNewSkillChange}
                                  />
                                  <button
                                    className=" duration-300   font-[550] py-1 px-4 rounded-xl"
                                    type="submit"
                                    onClick={handleFormSubmit}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                              {skil.map((item, key) => (
                                <li
                                  className="text-black flex items-center m-1  text-xs md:text-sm duration-300 cursor-pointer py-1 px-4 border rounded-3xl border-black hover:bg-[#80808025] "
                                  key={key}
                                  onClick={() => delitem(item)}
                                >
                                  <ImBin />
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label htmlFor="description" className="font-[550]">
                          Description
                        </label>
                        <div className="quill-container">
                          <textarea
                            className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                              isDarkMode
                                ? "border-[#3b4354] bg-[#3b4354] "
                                : "bg-white text-black"
                            }`}
                            name="description"
                            rows="4"
                            defaultValue={values.description}
                            {...register("description")}
                          ></textarea>
                        </div>
                      </div>
                      <div>
                        <label htmlFor="link" className="font-[550]">
                          Link <span className="text-red-500"></span>
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                          }`}
                          name="link"
                          defaultValue={values.link}
                          {...register("link", {
                            pattern: {
                              value:
                                /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/,
                              message: "Invalid website URL",
                            },
                          })}
                        />
                        {errors.link && (
                          <p className="text-red-500">{errors.link.message}</p>
                        )}
                      </div>
                      <div className="flex flex-row-reverse justify-between">
                      {loading ? (
                    <FaSpinner
                      size={30}
                      className="text-[#0052ff] animate-spin"
                    />
                  ) : (
                    <div>
                      {/* <button
                    onClick={() => {navigate("/profile/page3");
                    window.scroll(0, 0)}}
                      className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                    >
                      Back
                    </button> */}
                      <button
                        type="submit"
                        className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                      >
                        Save
                      </button>
                    </div>
                  )}
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
