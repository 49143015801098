import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { IoMdSave } from "react-icons/io";
import apiUrl from "../apiConfig";
import { toast, ToastContainer } from "react-toastify";
import ForgotPasswordForm from "./ForgotPasswordForm";
import DeleteAccountModal from "./DeleteAccountModal";

export default function DeleteAccount({ url, isDarkMode, email }) {
  const [showpassword, setshowpassword] = useState("password");
  const { register, handleSubmit } = useForm();
  const token = localStorage.getItem("token");

  const [deleteAccountModal,setDeleteAccountModal] = useState(false);

  const handleDeleteAccountModal = () => {
    setDeleteAccountModal(!deleteAccountModal);
  }


  const validateForm = (password, cpassword) => {
    if (password === cpassword) {
      return true;
    } else {
      return false;
    }
  };

  const submitform = async (data) => {
    if (validateForm(data.Password, data.CPassword)) {
      try {
        const response = await axios.post(
          apiUrl.forgotpassword.changepassword + token,
          {
            currentpassword: data.CurrentPassword,
            newpassword: data.Password,
          }
        );
        if (response.status == 200) {
          // toast.success('Password Changed Successfully');
          // // // console,log(response.data)
        }
      } catch (error) {
        // toast.error('Enter correct current password');
        // // // console,log(error);
      }
    }
  };

  const showpass = () => {
    const type = showpassword === "password" ? "text" : "password";
    setshowpassword(type);
  };

  return (
    <div
      className={` ${
        isDarkMode ? "bg-[#282c34]" : "bg-white"
      } w-full  rounded-xl p-4`}
    > 
    {deleteAccountModal && (<DeleteAccountModal handleDeleteAccountModal={handleDeleteAccountModal} email={email} token={token}/>)}
      
      <h1 className="text-xl font-[550]">Delete Account</h1>
      <br />
      {/* <h4 className="py-2 text-sm">
        Send a request mail to service@aspireup.ai from the mail id mentioned below. After verifying the email, the account will be deleted
      </h4> */}
      <input
        type="email"
        value={email}
        className={`w-full p-2  focus:outline-none  border-[1.5px]  rounded-md my-2  ${
          isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
        }`}
        disabled
      />
      <br />
      <div className="flex justify-end">
        <button
          type="submit"
          className="D"
          onClick={handleDeleteAccountModal}
        >
          Delete Account
        </button>
      </div>
    </div>
  );
}
