import React from "react";

import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export default function Landingsection4() {
  const navigate = useNavigate();
  return (
    <div className=" text-center flex flex-col justify-center items-center gap-4 p-4 ">
      <div className=" md:text-4xl text-2xl font-[550] ">
      Eager to achieve your career goals?
      </div>
      <div
        onClick={() => {
          navigate("/signup");
          window.scroll(0, 0);
        }}
        className=" cursor-pointer text-center bg-[#0052ff] p-2 flex gap-1 items-center h-full text-white rounded-md font-[550] w-fit"
      >
        Sign up now <FaArrowRight />
      </div>
      <br />
      <br />
    </div>
  );
}
