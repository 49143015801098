import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import apiUrl from "../../apiConfig";
import { FaSpinner } from "react-icons/fa";
import { useEffect } from "react";
export default function ProfileSocial({
  formdetails,
  isDarkMode,
  url,
  seturl,
}) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchurl = async () => {
      {
        try {
          const reqbody = {};
          // // // console,log("app.js: ", reqbody, { token: token });
          const response = await axios.post(apiUrl.url.geturl + token, reqbody);
          // // // console,log(response);
          if (response && response.status === 200) {
            seturl(response.data.url.url);
            // // console,log(response.data.url.url)

            // navigate(response.data.url.url);
          }
        } catch (err) {
          // // console,log(err.message);
        }
      }
    };

    const postProfileStatus = async () => {
      try{
        const reqbody = {
          profilestatus : "/signup/add-socialprofiles"
        }
        const response = await axios.post(apiUrl.profile.editprofile + `${token}`,reqbody);
        if(response.status == 200){
        }
      }catch(error){
        //console.log(error)
      }
    }

    postProfileStatus();

    fetchurl();
  }, []);

  const submitform = async (data) => {
    setLoading(true);
    const reqbody = {
      socialaccounts: data,
      profilestatus: "/",
    };
    try {
      const response = await axios.post(
        apiUrl.profile.editprofile + token,
        reqbody
      );
      //// // console,log(response);
      if (response.status === 200) {
        //// // console,log(response.body);
        // Navigate to the next page or wherever you want
        // navigate(`/profile/page7/${id}`);
        // // console,log(url);
        navigate("/" + url);
      }
    } catch (err) {
      //// // console,log(err);
    }
    // //// // console,log(formdetails);
    setLoading(!loading);
    window.scroll(0, 0);
  };
  const skip = () => {
    navigate("/" + url);
    window.scroll(0, 0);
  };

  return (
    <div>
      {" "}
      <div>
        <div className=" h-1 w-full flex">
          <div className="h-full bg-[#0052ff] w-[100%]"></div>
          <div className="h-full"></div>
        </div>
        <div className="m-4 ">
          <div className="py-3 text-2xl font-[550] font-sans">
            Drop your social account links here!
          </div>

          <form onSubmit={handleSubmit(submitform)}>
            <div className="my-4">
              <label htmlFor="LinkedIn" className=" font-[550]">
                LinkedIn
              </label>
              <input
                type="text"
                className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                  isDarkMode ? "border-[#3b4354] bg-[#3b4354]" : ""
                }`}
                name="LinkedIn"
                {...register("LinkedIn", {
                  pattern: {
                    value:
                      /^(https:\/\/)?(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?$/,
                    message: "Invalid LinkedIn URL",
                  },
                })}
              />
              {errors.LinkedIn && (
                <p className="text-red-500">{errors.LinkedIn.message}</p>
              )}
            </div>
            <div className="my-4">
              <label htmlFor="HackerRank" className=" font-[550]">
                X (Twitter)
              </label>
              <input
                type="text"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                  isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                }`}
                name="HackerRank"
                {...register("Twitter", {
                  pattern: {
                    value:
                      /^((https:\/\/)?(www\.)?(?:x\.com|twitter\.com)\/[A-Za-z0-9_-]+)$/,
                    message: "Invalid X profile link",
                  },
                })}
              />
              {errors.Twitter && <p>{errors.Twitter.message}</p>}
            </div>

            <div className="my-4">
              <label htmlFor="WebSite" className=" font-[550]">
                Website
              </label>
              <input
                type="text"
                //   placeholder="Last Name"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                  isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                }`}
                name="WebSite"
                {...register("WebSite", {
                  pattern: {
                    value:
                      /^(https:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/,
                    message: "Invalid Website URL",
                  },
                })}
              />
              {errors.WebSite && (
                <p className="text-red-500">{errors.Website.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="GitHub" className=" font-[550]">
                GitHub
              </label>
              <input
                type="text"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                  isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                }`}
                name="GitHub"
                {...register("GitHub", {
                  pattern: {
                    value: /^(https:\/\/)?(www\.)?github\.com\/[A-Za-z0-9_-]+$/,
                    message: "Invalid GitHub profile link",
                  },
                })}
              />
              {errors.GitHub && <p className="text-red-500">{errors.GitHub.message}</p>}
            </div>

            <div className="">
              <label htmlFor="firstname" className=" font-[550]">
                Instagram
              </label>
              <input
                type="text"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                  isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                }`}
                name="Instagram"
                {...register("Instagram", {
                  pattern: {
                    value:
                      /^(https:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._-]+$/,
                    message: "Invalid Instagram profile link",
                  },
                })}
              />
              {errors.Instagram && (
                <p className="text-red-500">{errors.Instagram.message}</p>
              )}
            </div>
            <div className="">
              <label htmlFor="firstname" className=" font-[550]">
                Discord
              </label>
              <input
                type="text"
                className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                  isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                }`}
                name="Discord"
                {...register("Discord", {
                  pattern: {
                    value:
                      /^(https:\/\/)?(www\.)?discord\.com\/[A-Za-z0-9_-]+$/,
                    message: "Invalid Discord profile link",
                  },
                })}
              />
              {errors.Discord && (
                <p className="text-red-500">{errors.Discord.message}</p>
              )}
            </div>

            <div className="flex flex-row-reverse pt-4 w-full justify-between">
              {loading ? (
                <FaSpinner
                  size={30}
                  className="animate-spin text-[#0052ff] mr-2"
                />
              ) : (
                <div>
                  {/* <button
                    onClick={() => {
                      navigate("/profile/page7");
                      window.scroll(0, 0);
                    }}
                    className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                  >
                    Back
                  </button> */}
                  <button
                    type="submit"
                    className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                  >
                    Next
                  </button>
                </div>
              )}
              <button
                className="text-[#0052ff] hover:text-[#014cec] font-[550] flex justify-center items-center gap-1"
                onClick={skip}
              >
                Skip
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
