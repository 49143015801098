const host = process.env.REACT_APP_Backend_Host_Link
const websockethost = process.env.REACT_APP_Backend_Host_Link
const apiUrl = {
     
    login : host + "api/users/userslogin",
    senddeleteaccountmessage : host + "api/users/senddeleteaccountmessage/",
    sendeleteaccountconfirmationmessage : host + "api/users/senddeleteaccountconfirmationmessage/",
    deleteaccount : host + "api/users/deleteaccount",
    getuserdetails : host + "api/users/getuserdetails/",
    usersignup : host + "api/users/userssignup",
    adminsignup : host + "api/admin/adminsignup",
    verifyotp : host + "api/users/verifyOTP",
    checktoken: host + "api/chats/checktoken/",
    getcountry : host + "api/users/getcountry",
    profile : {
        sendsms : host + "api/users/editphone/",
        addresume : host + "api/profile/addresume/",
        getresume : host + "api/profile/getresume/",
        addimage : host + "api/profile/addimage/",
        getimage : host + "api/profile/getimage/",
        getcovimage : host + "api/profile/getcovimage/",
        getprofile : host + "api/profile/getprofile/",
        editprofile : host + "api/profile/editprofile/",
        editmultipleinprofile : host + "api/profile/editmultipleinprofile/",
        deleteprofile : host + "api/profile/deleteprofile/",
        addexp : host + "api/profile/addeduexp/",
        editexp : host + "api/profile/editprojeduexp/",
        getprofilefromid : host + "api/profile/getprofilefromid/",
        geturlanddp : host + "api/profile/geturlanddp/",
        getrandomprofiles : host + "api/profile/getrandomprofiles/",
        getprofilesectionsstatus: host + "api/profile/getprofilesectionsstatus/"
    },
    forgotpassword : {
        sendcode : host + "api/users/reqotp/",
        forgotpassword : host + "api/users/forgotpassword/",
        changepassword : host + "api/users/changepassword/",
    },
    documents : {
        adddocument : host + "api/documents/adddocument/",
        deletedocument : host + "api/documents/deletedocument/",
        deletesection : host + "api/documents/deletesection/",
        getdocuments : host + "api/documents/getdocuments/",
        ordersection: host + "api/documents/updatesections/",
        addsection : host + "api/documents/addsection/",
        editsection : host + "api/documents/editsection/",
        createresume : host + "api/documents/createresume/"
    },
    url: {
        geturl: host + "api/users/geturl/",
        seturl: host + "api/users/seturl/",
        checkurl: host + "api/users/checkurl/",
        sendusermessage: host + "api/users/sendusermessage/",
        geturlusingid : host + "api/users/geturlusingid/"
    },
    universities : {
     getuniversities : host + "api/universities/getuniversities",
     adduniversities : host + "api/universities/adduniversities"
    },
    companies : {
        getcompanies : host + "api/companies/getcompanies" ,
        addcompanies : host + "api/companies/addcompanies"
    },
    admin : {
        getallusersdata : host + "api/admin/getallusersdata",
        addlogo : host + "api/admin/addlogo",
    },
    connections: {
        request: host + "api/connections/requestconnection/",
        accept: host + "api/connections/acceptconnection/",
        reject: host + "api/connections/rejectconnection/",
        disconnect: host + "api/connections/disconnectconnection/",
        getdetails: host + "api/connections/getdetails/",
        getrequests: host + "api/connections/getrequests/",
        getnewreqs: host + "api/connections/getnewreqs/",
        getnewpeople : host + "api/connections/getnewpeople/",
        getsearchedconnections : host + "api/connections/getsearchedconnections/",
        getconnections : host + "api/connections/getconnections/"
    },
    jobposting : {
        edit : host + "api/jobposting/edit/",
        getjobs : host + "api/jobposting/getjobs",
        getjob: host + "api/jobposting/getjobbyid/",
        getjobsperuser : host + "api/jobposting/getjobsperuser/"
    },
    location : {
        getcountry : host + "api/location/getcountry"
    },
    chats : {
        get : host + "api/chats/get/",
        getactivechats : host + "api/chats/getactivechats/",
        getnewchats : host + "api/chats/getnewchats/",
        makeunseenzero : host + "api/chats/makeunseenzero/",
        deletechat : host + "api/chats/deletechat/",
        editmessage : host + "api/chats/editmessage/",
        deletemessage : host + "api/chats/deletemessage/"
    },
    websocket : {
        
        connection : websockethost
    },
    filters : {
        getFilteredProfiles : host + "api/filters/getfilteredprofiles/"
    },
    jobapplications : {
        addapplication : host + "api/jobapplications/addapplication/",
        getapplications : host + "api/jobapplications/getapplications/",
        getappliedjobs : host + "api/jobapplications/getappliedjobs/",
        withdrawapplication : host + "api/jobapplications/withdrawapplication/"
    },
    employer: {
        signupemployeruser : host + "api/employerusers/signupemployeruser",
        loginemployeruser : host + "api/employerusers/loginemployeruser",
        getemployercompany : host + "api/employerusers/getemployerusercompany/"
    },
    publicinterest : {
        updatepublicinterest : host + "api/publicinterest/updatepublicinterest/",
        checkfeaturepublicinterest : host + "api/publicinterest/checkfeaturelinkedwithuser/"
    },
    waitlist: {
        addwaitlist : host + "api/waitlist/addwaitlist/",
        getwaitlist : host + "api/waitlist/getwaitlist/"
    },addorg: {
        addorganization: host + "api/org/addorganization/"
    },
    tags : {
        gettags : host + "api/tags/gettags/",
        addtag : host + "api/tags/addtag/",
        addusertotag : host + "api/tags/addusertotag/",
        deletetag : host + "api/tags/deletetag/",
        untaguser : host + "api/tags/untaguser/",
        addnewtag : host + "api/tags/addnewtag/",
        edittag : host + "api/tags/edittag/"
    },
    lists : {
        getlists : host + "api/lists/getlists/",
        addlist : host + "api/lists/addlist/",
        addusertolist : host + "api/lists/addusertolist/",
        deletelist : host + "api/lists/deletelist/",
        untaguser : host + "api/lists/unlistuser/",
        addnewlist : host + "api/lists/addnewlist/",
        editlist : host + "api/lists/editlist/"
    },
    views : {
        addviewcount : host + "api/analytics/addviewcount/",
        getviewcount : host + "api/analytics/getviewcount/"
    },
    globaladmin : {
        getalladmins : host + "api/globaladmin/getalladmins",
        deleteadmin : host + "api/globaladmin/deleteadmin",
        changeadminpassword : host + "api/globaladmin/changeadminpassword",
        addnewadmin : host + "api/globaladmin/addnewadmin",
        getallaccesscodes : host + "api/globaladmin/getallaccesscodes",
        deleteaccesscode : host + "api/globaladmin/deleteaccesscode"
    }
}






export default apiUrl