import React from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "./setCanvasPreview";
import apiUrl from "../apiConfig";
import axios from "axios";
import { FaSpinner } from "react-icons/fa6";

export default function ProfilepicModal({
  toggleProfile,
  isDarkMode,
  dummyDP,
  image,
  setimage,
  title,
  type,
  setapi,
  api,
}) {
  const imgRef = useRef(null);
  const ASPECT_RATIO = title === "Cover picture" ? 14 / 5 : 1; // Dynamic aspect ratio based on title

  const MIN_DIMENSION = 150;
  const previewCanvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState();
  const [error, setError] = useState("");
  const [files, setfiles] = useState();
  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    // // // console,log(file);
    setfiles(file);

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          title !== "Cover picture"
            ? setError("Image must be at least 150 x 150 pixels.")
            : setError("Image must be at least 150 x 420 pixels.");
          return setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  const [loading, setLoading] = useState(false);

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };
  const token = localStorage.getItem("token");
  const resizeImage = (imgData, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        // Calculate new dimensions while maintaining aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        // Set canvas dimensions
        canvas.width = width;
        canvas.height = height;

        // Draw image on canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        // Get resized image as data URL
        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/jpeg");
      };
      img.onerror = (error) => reject(error);
      img.src = URL.createObjectURL(new Blob([imgData]));
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setCanvasPreview(
      imgRef.current, // HTMLImageElement
      previewCanvasRef.current, // HTMLCanvasElement
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    );
    const dataUrl = previewCanvasRef.current.toDataURL();

    // Split the data URL into parts
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);

    // Convert base64 data to a byte array
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    // Resize the image (optional)

    const resizedBlob = await resizeImage(u8arr, 800, 800); // Resize to a maximum dimension of 800x800 pixels

    // Create a File object with the resized blob
    const fileupload = new File([resizedBlob], files.name, { type: mime });

    // Calculate the file size
    const fileSizeInMegabytes = fileupload.size / (1024 * 1024);
    // // // console,log(fileSizeInMegabytes + " MB");
    // // // console,log(title)
    // Append the File object to the FormData
    const form = new FormData();
    if (title === "Cover picture") {
      form.append("image", fileupload);
      form.append("coverpicture", title === "Cover picture");
    } else {
      form.append("image", fileupload);
      form.append("coverpicture", title === "Cover picture");
    }
    // // // console,log("form*******************:",form);
    try {
      const response = await axios.post(
        `${apiUrl.profile.addimage}${token}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Make sure to set the correct Content-Type header
          },
          params: {
            coverpicture: title === "Cover picture",
          },
        }
      );

      if (response.status === 200) {
        const result = response.data;
        // // // console,log(response);
        // formdetails.uploadedFile = result.link; // Adjust based on your backend response
        // setLoading(false);
        setimage(result.link);
        // // // console,log(result.link);
        toggleProfile();
        setLoading(false);
      } else {
        // console.error("File upload failed");
        // Handle the error as needed
      }
    } catch (err) {
      // // // console,log(err);
    }
  };

  const handleDeleteProfilePicture = async () => {
    try {
      const response = await axios.post(apiUrl.profile.editprofile + token, {
        Profilepic: "https://aspireup-docs.s3.amazonaws.com/dummyDp.png",
      });
      if (response.status === 200) {
        setapi(!api);
        toggleProfile();
      } else {
        // console.error("File upload failed");
      }
    } catch (err) {
      // // // console,log(err);
    }
  };

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={toggleProfile}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform   overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw]  w-[100%] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                    isDarkMode
                      ? "bg-[#2a303c] border-[#2a303c]"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="flex w-full items-end justify-between py-2 cursor-pointer">
                    <div className=" font-[550] text-xl">{title}</div>
                    <IoMdClose
                      size={30}
                      onClick={toggleProfile}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>

                  <p>
                    Recomended image size is 150x150 pixels.
                  </p>

                  {/* <div className="">
                    click on the image to update the picture
                  </div> */}
                  <div className="flex flex-col gap-4 pt-4">
                    <input
                      type="file"
                      accept="image/*"
                      // className="hidden"
                      className="file:text-[#0052ff] file:bg-white file:cursor-pointer focus:outline-none file:text-xs file:md:text-sm duration-300 cursor-pointer file:py-1 file:px-4 file:border file:rounded-3xl file:border-[#0052ff] file:hover:bg-blue-200 file:my-4 file:md:my-0"
                      onChange={onSelectFile}
                    />
                  </div>
                  {error && <p className="text-red-400 text-xs">{error}</p>}
                  {imgSrc && (
                    <div className="flex flex-col items-center">
                      <ReactCrop
                        crop={crop}
                        onChange={(pixelCrop, percentCrop) =>
                          setCrop(percentCrop)
                        }
                        keepSelection
                        aspect={ASPECT_RATIO}
                        minWidth={MIN_DIMENSION}
                      >
                        <img
                          ref={imgRef}
                          src={imgSrc}
                          alt="Upload"
                          style={{ maxHeight: "70vh" }}
                          onLoad={onImageLoad}
                        />
                      </ReactCrop>
                    </div>
                  )}

                  <div className="flex pt-2 w-full justify-end ">
                    {loading ? (
                      <FaSpinner className="animate-spin text-[#0052ff] mr-2" />
                    ) : (
                      <div className=" flex gap-2">
                        {type != "signup" && (
                          <button
                            className="text-red-500 border h-fit border-red-500 py-1 px-4 rounded-3xl hover:bg-red-100 cursor-pointer w-fit text-sm"
                            onClick={handleDeleteProfilePicture}
                          >
                            Remove
                          </button>
                        )}
                        <div
                          onClick={handleSubmit}
                          className="bg-[#0052ff]  text-white   hover:bg-[#014cec]  cursor-pointer py-1 px-4 rounded-3xl "
                        >
                          <div className="flex gap-1 justify-center items-center">
                            {/* <IoMdSave size={20} /> */}
                            <p>Save</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* {
                      croppedimg && <img src={URL.createObjectURL(croppedimg)} alt="" />
                    } */}
                  </div>

                  {crop && (
                    <canvas
                      ref={previewCanvasRef}
                      className="mt-4"
                      style={{
                        display: "none",
                        border: "1px solid black",
                        objectFit: "contain",
                        width: 150,
                        height: 150,
                      }}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
