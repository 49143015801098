import React, { useState, useRef, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import axios from "axios";
import apiUrl from "../apiConfig";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaSpinner } from "react-icons/fa";
export default function EditSocialAcc({
  dash,
  handleEditProfile,
  openModal,
  publicview,
  setpublicview,
  isDarkMode,
  api,
  setapi,
}) {
  const [resumes, setresumes] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [socialacc, setsocialacc] = useState("");
  const [loading, setloading] = useState(false);
  var errorSocial;
  const validateAllSocialUrls = () => {
    let result = true;
    for (const [key, value] of Object.entries(duplicateDash || {})) {
      if (value !== "" && key !== "_id") {
        switch (key) {
          case "LinkedIn":
            result =
              /^https?:\/\/(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?$/.test(
                value
              );
            errorSocial = "LinkedIn";
            setsocialacc("LinkedIn");
            break;
          case "GitHub":
            result = /^https:\/\/github\.com\/[A-Za-z0-9_-]+$/.test(value);
            errorSocial = "GitHub";
            setsocialacc("GitHub");
            break;
          case "Twitter":
            result =
              /^(https:\/\/(?:x\.com|twitter\.com)\/[A-Za-z0-9_-]+)$/.test(
                value
              );
            errorSocial = "Twitter";
            setsocialacc("Twitter");
            break;
          case "Instagram":
            result = /^https:\/\/instagram\.com\/[A-Za-z0-9_-]+$/.test(value);
            errorSocial = "Instagram";
            setsocialacc("Instagram");
            break;
          case "Discord":
            result = /^https:\/\/discord\.com\/[A-Za-z0-9_-]+$/.test(value);
            errorSocial = "Discord";
            setsocialacc("Discord");
            break;
          case "WebSite":
            result =
              /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/.test(
                value
              );
            errorSocial = "WebSite";
            setsocialacc("WebSite");
            break;
          default:
            result = false;
        }
        if (!result) {
          return false;
        }
      }
    }
    return result;
  };
  const submitform = async () => {
    let data = duplicateDash;
    data[social] = socialUrl;
    setloading(true);
    const reqbody = {
      socialaccounts: data,
    };

    if (validateAllSocialUrls() == true) {
      try {
        const response = await axios.post(
          apiUrl.profile.editprofile + token,
          reqbody
        );
        if (response.status == 200) {
          // console.log(response);
          setError("");
          setsocialacc("");
          setsocial({});
          setsocialUrl("");
          setMessage("Social accounts updated successfully");
          setOpenSocialInputs(false);
          setapi(!api);
          setTimeout(() => {
            handleEditProfile();
          }, 1000);
        }
      } catch (err) {
        setMessage("");
        setError(err.response.data.message);
        // console.log(err)
      } finally {
        setloading(false);
      }
    } else {
      data[social] = "";
      setMessage("");
      setloading(false);
      setsocialacc(errorSocial);
      setError(
        `Please enter valid ${
          errorSocial === "WebSite" ? "Website" : errorSocial
        } url`
      );
    }
  };
  const [duplicateDash, setDuplicateDash] = useState({});

  useEffect(() => {
    setDuplicateDash(dash.socialaccounts);
  }, []);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [actres, setactres] = useState("");
  // useEffect(() => {
  //   const getresumes = async () => {
  //     try {
  //       const response = await axios.post(
  //         apiUrl.documents.getdocuments + `${token}`
  //       );
  //       // // // console,log(response.data.documents);
  //       const sections = response.data.documents;

  //       const index = sections.findIndex((sec) => sec.Name === "Resume");
  //       setresumes(sections[index].Documents);
  //     } catch (err) {}
  //   };

  //   const getactres = async () => {
  //     try {
  //       const resp = await axios.post(apiUrl.profile.getprofile + token);
  //       // // // console,log(resp.data.existing_profile.Resume);
  //       setactres(resp.data.existing_profile.Resume);
  //     } catch (err) {}
  //   };
  //   getactres();
  //   getresumes();
  // }, []);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const token = localStorage.getItem("token");

  const [openSocialInputs, setOpenSocialInputs] = useState(false);
  const [social, setsocial] = useState({});
  const [socialUrl, setsocialUrl] = useState("");

  const handleOpenSocialInputs = () => {
    setMessage("");
    setError("");
    setOpenSocialInputs(!openSocialInputs);
  };

  const handleChangeDuplicateDash = (e, key) => {
    setDuplicateDash((prevState) => ({
      ...prevState,
      [key]: e.target.value,
    }));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleEditProfile}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
          <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                <div
                  className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                    isDarkMode
                      ? "bg-[#2a303c] border-[#2a303c]"
                      : "bg-white text-black"
                  }`}
                >
                  <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                    <div className="text-2xl font-[550]">
                      Edit Social Details
                    </div>
                    <IoMdClose
                      size={30}
                      onClick={handleEditProfile}
                      className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                    />
                  </div>
                  {Object.values(dash.socialaccounts).some(
                    (val) => val === ""
                  ) && (
                    <div
                      className="text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100  cursor-pointer font-[550] py-1 md:px-4 px-3 text-sm md:text-left text-center rounded-3xl my-2 md:my-0 w-fit"
                      onClick={handleOpenSocialInputs}
                    >
                      Add Social Account
                    </div>
                  )}

                  <div className="my-3 overflow-auto">
                    {openSocialInputs && (
                      <div className=" flex gap-2  border p-2 rounded-md items-center ">
                        <div className="flex flex-col gap-2 w-full">
                          <div className="flex flex-col">
                            <label htmlFor="Social" className=" font-[550]">
                              Social Profile Name
                            </label>
                            <select
                              className={`w-full p-2 border-[1.5px]  rounded-md ${
                                isDarkMode
                                  ? "border-[#3b4354] bg-[#3b4354] "
                                  : ""
                              }`}
                              name="Social"
                              // required

                              value={social}
                              onChange={(e) => setsocial(e.target.value)}
                            >
                              <option value="">Select a Social Profile</option>
                              {!dash.socialaccounts.LinkedIn && (
                                <option value="LinkedIn">Linkedin</option>
                              )}
                              {!dash.socialaccounts.Twitter && (
                                <option value="Twitter">X(Twitter)</option>
                              )}
                              {!dash.socialaccounts.WebSite && (
                                <option value="WebSite">Website</option>
                              )}
                              {!dash.socialaccounts.GitHub && (
                                <option value="GitHub">GitHub</option>
                              )}
                              {!dash.socialaccounts.Instagram && (
                                <option value="Instagram">Instagram</option>
                              )}
                              {!dash.socialaccounts.Discord && (
                                <option value="Discord">Discord</option>
                              )}
                            </select>
                          </div>

                          <div className="flex flex-col">
                            <label htmlFor="Social" className=" font-[550]">
                              URL
                            </label>
                            <div className=" flex gap-2 items-center">
                              <input
                                type="text"
                                placeholder="Enter URL"
                                className={`w-full p-2 border-[1.5px] focus:outline-none  rounded-md ${
                                  error &&
                                  socialacc === social &&
                                  "border-red-500"
                                }`}
                                value={socialUrl}
                                onChange={(e) => setsocialUrl(e.target.value)}
                              />
                            </div>
                            {error && socialacc === social && (
                              <p className="text-red-500">{error}</p>
                            )}
                          </div>
                        </div>
                        <div className="flex gap-2 justify-end ">
                          <button
                            className=" border-[1px] border-red-500 p-2 rounded-full hover:bg-red-100 cursor-pointer"
                            onClick={() => {
                              setDuplicateDash(dash.socialaccounts);
                              setOpenSocialInputs(false);
                              setsocialUrl("");
                              setsocial("");
                            }}
                          >
                            <RiDeleteBin6Line className="text-red-500 size-5" />
                          </button>
                        </div>
                      </div>
                    )}

                    {Object.entries(duplicateDash).map(([key, value]) => {
                      if (value !== "" && key != "_id") {
                        const newSocial = { ...duplicateDash };
                        return (
                          <div
                            key={key}
                            className="border p-2 rounded-md my-2 flex gap-2 items-center"
                          >
                            <div className="flex flex-col gap-2 w-full ">
                              <div className="flex flex-col">
                                <label htmlFor="Social" className=" font-[550]">
                                  Social Profile Name
                                </label>
                                <input
                                  className={`w-full p-2 border-[1.5px]  rounded-md ${
                                    isDarkMode
                                      ? "border-[#3b4354] bg-[#3b4354] "
                                      : ""
                                  }`}
                                  name={key}
                                  value={key === "WebSite" ? "Website" : key}
                                  disabled
                                  onChange={(e) => setsocial(e.target.value)}
                                />
                              </div>
                              <div className="flex flex-col">
                                <label htmlFor="Social" className=" font-[550]">
                                  URL
                                </label>
                                <div className="flex gap-2 items-center ">
                                  <input
                                    type="text"
                                    placeholder="Enter URL"
                                    className={`w-full p-2 border-[1.5px] focus:outline-none rounded-md ${
                                      error &&
                                      socialacc === key &&
                                      "border-red-500"
                                    }`}
                                    value={value}
                                    onChange={(e) =>
                                      handleChangeDuplicateDash(e, key)
                                    }
                                  />
                                </div>

                                {error && socialacc === key && (
                                  <p className="text-red-500">{error}</p>
                                )}
                              </div>
                            </div>
                            <div className="flex gap-2 justify-end h-fit ">
                              <button
                                className=" border-[1px] border-red-500 p-2 rounded-full hover:bg-red-100 cursor-pointer"
                                onClick={() => {
                                  setDuplicateDash((prevState) => ({
                                    ...prevState,
                                    [key]: "",
                                  }));
                                  setsocial(key);
                                }}
                              >
                                <RiDeleteBin6Line className="text-red-500 size-5" />
                              </button>
                            </div>
                          </div>
                        );
                      }
                    })}
                    {message && (
                      <p className="text-green-500  text-sm">{message}</p>
                    )}
                    <div className="flex gap-2 justify-end p-2  ">
                      {loading ? (
                        <FaSpinner
                          size={30}
                          className="text-[#0052ff] animate-spin"
                        />
                      ) : (
                        <div>
                          {/* <button
                    onClick={() => {navigate("/profile/page3");
                    window.scroll(0, 0)}}
                      className="bg-[#eef0f3] font text-black hover:bg-[#e9ebee]  py-1 px-4 rounded-3xl mx-2 font-[550]"
                    >
                      Back
                    </button> */}
                          <button
                            onClick={submitform}
                            className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>

                    {/* <form
                      action=""
                      onSubmit={handleSubmit(submitform)}
                      className=" flex flex-col gap-2"
                    >
                      <div className="">
                        <label htmlFor="Resume" className=" font-[550]">
                          Resume
                        </label>
                        <select
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                          }`}
                          name="country"
                          // required
                          {...register("Resume")}
                          value={actres}
                          onChange={(e) => setactres(e.target.value)}
                        >
                          <option value="">Select a Resume</option>
                          {resumes.map((country, index) => (
                            <option key={index} value={country.Link}>
                              {country.Name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <label htmlFor="firstname" className=" font-[550]">
                          LinkedIn
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2 border-[1.5px] rounded-md my-2 ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354]" : ""
                          }`}
                          name="LinkedIn"
                          defaultValue={dash.socialaccounts.LinkedIn}
                          {...register("LinkedIn", {
                            pattern: {
                              value:
                                /^(https:\/\/)?(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?$/,
                              message: "Invalid LinkedIn URL",
                            },
                          })}
                        />
                        {errors.LinkedIn && (
                          <p className="text-red-500">
                            {errors.LinkedIn.message}
                          </p>
                        )}
                      </div>
                      <div>
                        <label htmlFor="City" className="font-[550]">
                          X (Twitter)
                        </label>
                        <input
                          type="text"
                          //   placeholder="Last Name"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode
                              ? "border-[#3b4354] bg-[#3b4354] "
                              : "bg-white text-black"
                          }`}
                          name="currentRole"
                          defaultValue={dash.socialaccounts.Twitter}
                          {...register("Twitter", {
                            pattern: {
                              value:
                                /^((https:\/\/)?(www\.)?(?:x\.com|twitter\.com)\/[A-Za-z0-9_-]+)$/,
                              message: "Invalid X profile link",
                            },
                          })}
                        />
                        {errors.Twitter && (
                          <p className="text-red-500">
                            {errors.Twitter.message}
                          </p>
                        )}
                      </div>
                      <div>
                        <label htmlFor="currentRole" className=" font-[550]">
                          Website
                        </label>
                        <input
                          type="text"
                          //   placeholder="Last Name"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                          }`}
                          defaultValue={dash.socialaccounts.WebSite}
                          name="Website"
                          {...register("WebSite", {
                            pattern: {
                              value:
                                /^(https:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/,
                              message: "Invalid Website URL",
                            },
                          })}
                        />
                        {errors.WebSite && (
                          <p className="text-red-500">
                            {errors.WebSite.message}
                          </p>
                        )}
                      </div>
                      <div className="">
                        <label htmlFor="firstname" className=" font-[550]">
                          GitHub
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                          }`}
                          name="GitHub"
                          defaultValue={dash.socialaccounts.GitHub}
                          {...register("GitHub", {
                            pattern: {
                              value:
                                /^(https:\/\/)?(www\.)?github\.com\/[A-Za-z0-9_-]+$/,
                              message: "Invalid GitHub profile link",
                            },
                          })}
                        />
                        {errors.GitHub && (
                          <p className="text-red-500">
                            {errors.GitHub.message}
                          </p>
                        )}
                      </div>
                      <div className="">
                        <label htmlFor="firstname" className=" font-[550]">
                          Instagram
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                          }`}
                          name="Instagram"
                          defaultValue={dash.socialaccounts.Instagram}
                          {...register("Instagram", {
                            pattern: {
                              value:
                                /^(https:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._-]+$/,
                              message: "Invalid Instagram profile link",
                            },
                          })}
                        />
                        {errors.Instagram && (
                          <p className="text-red-500">
                            {errors.Instagram.message}
                          </p>
                        )}
                      </div>
                      <div className="">
                        <label htmlFor="firstname" className=" font-[550]">
                          Discord
                        </label>
                        <input
                          type="text"
                          className={`w-full p-2    border-[1.5px]  rounded-md my-2  ${
                            isDarkMode ? "border-[#3b4354] bg-[#3b4354] " : ""
                          }`}
                          name="Discord"
                          defaultValue={dash.socialaccounts.Discord}
                          {...register("Discord", {
                            pattern: {
                              value:
                                /^(https:\/\/)?(www\.)?discord\.com\/[A-Za-z0-9_-]+$/,
                              message: "Invalid Discord profile link",
                            },
                          })}
                        />
                        {errors.Discord && (
                          <p className="text-red-500">
                            {errors.Discord.message}
                          </p>
                        )}
                      </div>

                      <div className="flex flex-row-reverse pt-4">
                        <button
                          type="submit"
                          onClick={() => {
                            window.scroll(0, 0);
                          }}
                          className="bg-[#0052ff] text-white   hover:bg-[#014cec]  cursor-pointer font-[550] py-1 px-4 rounded-3xl my-4 md:my-0"
                        >
                          Save
                        </button>
                      </div>
                    </form> */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
