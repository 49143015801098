import React, { useState, useEffect } from "react";
import { GoPlus } from "react-icons/go";
import axios from "axios";
import apiUrl from "../../apiConfig";
import TimelineComponent from "../EduExp";
import { IoIosArrowUp } from "react-icons/io";
import DefaultCompany from "../../images/DefaultCompany.png";

export default function ExperienceComponent({
  dash,
  setshoweditexperience,
  // showAllJobs,
  // setShowAllJobs,
  showeditexperience,
  visibleItemsJobs,
  publicview,
  isDarkMode,
  api,
  setapi,
}) {
  const token = localStorage.getItem("token");
  const [showAllJobs, setShowAllJobs] = useState(false);
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(apiUrl.companies.getcompanies);
        setCompanies(response.data);
      } catch (error) {
        // console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const transformExperienceData = (experience) => {
    const result = [];
    let lastCompany = null;
  
    experience.forEach((item) => {
      const role = {
        jobTitle: item.jobTitle,
        city: item.city,
        country: item.country,
        fromMonth: item.fromMonth,
        fromYear: item.fromYear,
        description: item.description,
        toMonth: item.toMonth,
        toYear: item.toYear,
        _id: item._id,
      };
      if (lastCompany && lastCompany.company === item.company) {
        lastCompany.roles.push(role);
      } else {
        lastCompany = {
          company: item.company,
          roles: [role],
        };
        result.push(lastCompany);
      }
    });
  
    return result;
  };
  

  const transformedExperience =  transformExperienceData(dash.experience);
  const visiblejobitem = showAllJobs? transformedExperience : transformedExperience.slice(0, 2);

  return (
    <div
      className={`w-full border-[1px] md:pt-5 md:px-6 px-4 py-3 md:rounded-md ${
        isDarkMode ? "bg-[#3b4354]" : "bg-white"
      }`}
    >
      <div className="text-lg font-[550] flex justify-between">
        Experience{" "}
        {!publicview && (
          <div className="flex gap-4">
            <div
              onClick={() => setshoweditexperience(!showeditexperience)}
              className="w-auto p-2 hover:bg-slate-200 rounded-full duration-150 cursor-pointer"
            >
              <GoPlus />
            </div>
          </div>
        )}
      </div>
      {visiblejobitem.length !== 0 ? (
        visiblejobitem.map((item, key) => {
         
          const company = companies.find((obj) => obj.Name === item.company);
          const image = company ? company.s3Link : DefaultCompany;
   
          return (
            <TimelineComponent
              key={key}
              props={{
                token: token,
                item: item,
                image: image,
                publicview: publicview,
                isDarkMode: isDarkMode,
                api: api,
                setapi: setapi,
                length: transformedExperience.length,
                key: key,
              }}
            />
          );
        })
      ) : (
        <div className="text-gray-500 text-md w-full text-center">
          No Experience mentioned
        </div>
      )}
      {transformedExperience.length > 2 && (
        <div className="flex w-full mt-2 justify-center items-center border-slate-300">
          <button
            className="border-2 p-2 px-4 rounded-3xl flex gap-2 items-center justify-center hover:bg-gray-200 focus:border-black duration-300"
            onClick={() => setShowAllJobs(!showAllJobs)}
          >
            {showAllJobs
              ? "Show Less"
              : `Show All ${transformedExperience.length}`}{" "}
            <IoIosArrowUp className={showAllJobs ? "rotate-0" : "rotate-180"} />
          </button>
        </div>
      )}
    </div>
  );
}
