import axios from "axios";
import React, { useState, useEffect } from "react";
import apiUrl from "../apiConfig";
import { Table, AreaChart } from "keep-react";
import { Divider, Dropdown } from "keep-react";

import AdminDeleteAccountModal from "../components/AdminDeleteAccountModal";
import { useNavigate } from "react-router-dom";

export default function AdminPage() {
  const [users, setUsers] = useState([]);
  const [deleterequests, setDeleteRequests] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [displaytype, setDisplayType] = useState("Sort by Oldest First");
  const navigate = useNavigate();
  const handleDeleteAccountModal = (request) => {
    // console,log(request);
    setSelectedRequest(request);
    setOpenDeleteAccountModal(!openDeleteAccountModal);
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await axios.post(apiUrl.deleteaccount, {
        email: selectedRequest.email,
      });
      if (response.status == 200) {
        // // console,log(response.data)
        // console,log("Account Deleted Successfully");
      }
    } catch (error) {
      // console,log(error);
    }
    setOpenDeleteAccountModal(false);    
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(apiUrl.admin.getallusersdata, {
          displaytype: displaytype,
        });
        if (response.status === 200) {
          console.log(response.data);
          setUsers(response.data.users);
          setDeleteRequests(response.data.deleterequests);
          // Group data based on createdAt date and count users
          const groupedData = response.data.users.reduce((acc, user) => {
            const date = user.createdAt.split("T")[0]; // Extract date without time
            if (acc[date]) {
              acc[date]++;
            } else {
              acc[date] = 1;
            }
            return acc;
          }, {});

          // Convert grouped data into chart format
          const chartData = Object.entries(groupedData).map(
            ([date, count]) => ({ name: date, users: count })
          );

          setChartData(chartData);
        }
      } catch (error) {
        // // // console,log(error);
      }
    };
    fetchUserData();
  }, [displaytype]);

  return (
    <div
        className={`flex justify-center min-h-screen bg-[#ffffff]`}
      >
        <div className="w-full md:max-w-screen-xl md:w-[100%] px-4 ">
        <br />
        <div className="flex justify-between">
        <div className="text-3xl">Admin Dashboard</div>
        <div className="flex gap-2">
        <button className="w-fit text-[#0052ff] duration-300 cursor-pointer mt-2 py-1 px-4 border rounded-3xl border-[#0052ff] hover:bg-blue-200" onClick={() =>navigate("/admin/addlogos")}>Add Logos</button>
        </div>
        </div>
        <br />
        <div className="flex gap-4 justify-between ">
          <div className="bg-gray-200 w-1/4 p-4">
            <div className="text-md font-[550]">Total Number of Users</div>
            <br />
            <div className="text-3xl text-center">{users.length}</div>
          </div>
          <div className="bg-gray-200 w-1/4 p-4">
            <div className="text-md font-[550]">
              Total Number of Delete Requests
            </div>
            <br />
            <div className="text-3xl text-center">{deleterequests.length}</div>
          </div>
          <div className="bg-gray-200 w-1/4 p-4">
            <div className="text-md font-[550]">
              Number of users logged in last month
            </div>
            <br />
            <div className="text-3xl text-center">0</div>
          </div>
          <div className="bg-gray-200 w-1/4 p-4">
            <div className="text-md font-[550]">
              Number of users logged in last week
            </div>
            <br />
            <div className="text-3xl text-center">0</div>
          </div>
          <div className="bg-gray-200 w-1/4 p-4">
            <div className="text-md font-[550]">
              Number of users logged in yesterday
            </div>
            <br />
            <div className="text-3xl text-center">0</div>
          </div>
        </div>
        <br />
        <div className="bg-gray-100 p-2">
          <div className="font-[550]">Number of users vs Date</div>
          <div className="flex justify-end">
            <div className="w-1/3">
              <select
                className="w-full p-2 border-[1.5px] rounded-md my-2"
                name="country"
                value={displaytype}
                onChange={(e) => setDisplayType(e.target.value)}
              >
                <option value="Sort by Latest First">
                  Sort by Latest First
                </option>
                <option value="Sort by Oldest First">
                  Sort by Oldest First
                </option>
              </select>
            </div>
          </div>
          <AreaChart
            chartData={chartData}
            dataKey="users"
            showTooltip={true}
            showXAxis={true}
            showYAxis={true}
            showGridLine={true}
            xAxisLabel="Date"
            yAxisLabel="Number of Users"
          />
        </div>
        <br />
        {openDeleteAccountModal && selectedRequest && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className="flex flex-col gap-2 bg-white p-4 w-96 h-50 rounded-lg relative">
              <div>
                <div className="font-semibold text-xl">Delete Account</div>
                <button
                  className="absolute top-4 right-4"
                  onClick={handleDeleteAccountModal}
                >
                  X
                </button>
              </div>
              <div>
                <div>
                  Are you sure you want to delete this account? To confirm
                  please enter the above mentioned email in the field provided
                  i.e, {selectedRequest.email}
                </div>
                <input
                  type="text"
                  placeholder={`${selectedRequest.email}`}
                  className="w-full p-2 border-[1.5px] rounded-md p-2 my-2"
                />
              </div>
              <button className="bg-red-500 text-white px-3 py-1 font-[550] border rounded-3xl hover:bg-red-600 my-2 md:my-0" onClick={handleDeleteAccount}>
                Delete
              </button>
            </div>
          </div>
        )}
        {deleterequests.length > 0 && (
          <div className="bg-gray-100 p-2">
          <div className="font-[550]">List of Delete Requests</div>
          <br />
          <Table className="w-full divide-y divide-gray-200 font-[550] bg-white">
            <thead className="border">
              <tr className="h-[3rem] text-black bg-gray-200">
                <th className="min-w-[300px] w-1/6 text-left px-2 text-md text-black">
                  Email
                </th>
                <th className="min-w-[300px] w-1/6 text-left px-2 text-md text-black">
                  Created At
                </th>
                <th className="min-w-[300px] w-1/6 text-left px-2 text-md text-black">
                  Number of days left
                </th>
                <th className="min-w-[300px] w-1/6 text-left px-2 text-md text-black">
                  Action
                </th>
              </tr>
            </thead>
            {deleterequests.map((request, index) => (
              <tbody className="divide-gray-25 divide-y">
                <tr
                  key={index}
                  className="h-[3rem] border-b-[1px] bg-white text-black hover:bg-gray-100"
                >
                  <td>{request.email}</td>
                  <td>{new Date(request.createdAt).toLocaleString()}</td>
                  <td>
                    {3 -
                      Math.floor(
                        (Date.now() - new Date(request.createdAt).getTime()) /
                          (1000 * 60 * 60 * 24)
                      )}{" "}
                    days left
                  </td>
                  <td>
                    <button
                      className="bg-red-500 text-white px-3 py-1 font-[550] border rounded-3xl hover:bg-red-600 my-2 md:my-0"
                      onClick={() => handleDeleteAccountModal(request)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        </div>
        )}
        
        <br />
        <div className="bg-gray-100 p-2">
          <div className="font-[550]">List of Users</div>
          <br />
          <Table className="w-full divide-y divide-gray-200 font-[550] bg-white">
            <thead className="border">
              <tr className="h-[3rem] text-black bg-gray-200">
              <th className="min-w-[300px] w-1/6 text-left px-2 text-md text-black">
                  User Id
                </th>
                <th className="min-w-[300px] w-1/6 text-left px-2 text-md text-black">
                  Is Phone Number Present
                </th>
                <th className="min-w-[300px] w-1/6 text-left px-2 text-md text-black">
                  Created At
                </th>
              </tr>
            </thead>
            <tbody className="divide-gray-25 divide-y">
              {users.map((user, index) => (
                <tr
                  key={index}
                  className="h-[3rem] border-b-[1px] bg-white text-black hover:bg-gray-100"
                >
                  <td>{user._id}</td>
                  {user.phone ? <td>Yes</td> : <td>No</td>}
                  <td>{new Date(user.createdAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
