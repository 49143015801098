import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ProfileTile from "./ProfileTile";

export default function Requests({ requests, accept, reject, findimage }) {
  const navigate = useNavigate();



  return (
    <div className="w-full">
      {Object.keys(requests).length !== 0 ? (
        requests.map((user, index) => (
          <div
          key={index}
          className={` ${index === requests.length - 1? "": "border-b-[1px]"} p-2 h-full flex md:flex-row flex-col justify-between md:items-start py-2 w-full`}
        >
            <ProfileTile user={user} findimage={findimage} />
            <div className=" flex gap-2 ">
              {/* <div className="w-12 sm:hidden  "></div> */}
              <div
                onClick={() => accept(user.url)}
                className=" text-[#0052ff] border-[1.5px] border-[#0052ff] hover:bg-blue-100  cursor-pointer font-[550] py-[0.125rem] md:px-4 px-3 md:text-sm text-xs md:text-left text-center rounded-3xl my-2 md:my-0 h-fit"
              >
                Accept
              </div>
              <div
                onClick={() => reject(user.url)}
                className=" text-gray-500 border-[1.5px] border-gray-500 hover:bg-gray-100  cursor-pointer font-[550] py-[0.125rem] md:px-4 px-3 md:text-sm text-xs md:text-left text-center rounded-3xl my-2 md:my-0 h-fit"
              >
                Ignore
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className=" text-base font-[550] text-center w-full  pb-2">
          No new Invitations
        </div>
      )}
    </div>
  );
}
