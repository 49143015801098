import React, { useEffect, useState } from "react";
import axios from "axios";
import EditProfileModel from "../components/EditProfileModal";
import EditEduModel from "../components/AddEduModal";
import EditExpModel from "../components/AddExpModal";
import EditSkillsModal from "../components/AddSkillsModal";
import EditProjectModal from "../components/AddProjectModal";
import createEmptyProfile from "../asserts/ProfileModel";
import "../App.css";
import PersonalDetails from "../components/DashBoardComponents/PersonalDetails";
import ProjectsComponent from "../components/DashBoardComponents/ProjectsComponent";
import SkillsComponent from "../components/DashBoardComponents/SkillsComponent";
import ExperienceComponent from "../components/DashBoardComponents/ExperienceComponent";
import EducationComponent from "../components/DashBoardComponents/EducationComponent";
import apiUrl from "../apiConfig";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import URLComponent from "../components/URLcomponent";
import AddSectionModal from "../components/AddSectionDashModal";
import { useLocation } from "react-router-dom";
import SummaryComponent from "../components/DashBoardComponents/SummaryComponent";
import CollegeSuggestedProfiles from "../components/CollegeSuggestedProfiles";
import Spinnerpage from "../components/Spinnerpage";
import EditSummaryModal from "../components/EditSummaryModal";
import CertificationComponent from "../components/DashBoardComponents/CertificationComponent";
import AwardsComponent from "../components/DashBoardComponents/AwardsComponent";
import AddCertificationModal from "../components/AddCertificationModal";
import AddAawardsModal from "../components/AddAawardsModal";
import VolExpcomponent from "../components/VolExpcomponent";
import AddVolExpModal from "../components/AddVolExpModal";
import AppliedJobsComponent from "../components/AppliedJobsComponent";
import EnrolledCourses from "../components/EnrolledCourses";
import { Textarea } from "keep-react";
import AnalyticsComponent from "../components/AnalyticsComponent";
const Layout = ({
  formdetails,
  isDarkMode,
  curradd,
  setcuradd,
  url,
  seturl,
  api,
  setapi,
  curraddsubmit,
}) => {
  const [recruiterview, setrecruiterview] = useState(true);
  const [publicview, setpublicview] = useState(false);
  const [vieweditprofile, setvieweditprofile] = useState(false);
  const [showeditexperience, setshoweditexperience] = useState(false);
  const [showediteducation, setshowediteducation] = useState(false);
  const [showskills, setshowskills] = useState(false);
  const [dash, setdash] = useState(createEmptyProfile);
  const [showAllJobs, setShowAllJobs] = useState(false);
  const [showAllVolExp, setShowAllVolExp] = useState(false);
  const [showAllEducation, setShowAllEducation] = useState(false);
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [showprojectmodal, setshowprojectmodal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [profilepicmodal, setprofilepicmodal] = useState(false);
  const [coverpicmodal, setcoverpicmodal] = useState(false);
  const [image, setimage] = useState(createEmptyProfile.Profilepic);
  const [cov, setcov] = useState(createEmptyProfile.Coverpic);
  const [collegeprofiles, setCollegeProfiles] = useState([]);

  const [openCompModal, setopenCompModal] = useState(false);
  const manageCompModal = () => {
    setopenCompModal(!openCompModal);
  };
  const urlsubmit = async (data) => {
    const da = {
      url: data,
    };
    try {
      const response = await axios.post(apiUrl.url.seturl + token, da);
      // // // console,log(response);

      seturl(response.data.details.url);
      setapi(!api);
      navigate("/" + response.data.details.url);
    } catch (err) {
      // // // console,log(err.response.data.message);
    }
    // // // // console,log(data);
  };

  const [getprofile, setgetprofile] = useState(false);
  const navigate = useNavigate();
  const openModal = () => setShowModal(!showModal);
  const getMonthNumber = (monthName) => {
    const months = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };

    return months[monthName] || "Invalid month";
  };

  function sortByFromYearDesc(educationArray) {
    educationArray.sort(function (a, b) {
      if (Number(b.fromYear) === Number(a.fromYear)) {
        return (
          Number(getMonthNumber(b.fromMonth)) -
          Number(getMonthNumber(a.fromMonth))
        );
      }
      return Number(b.fromYear) - Number(a.fromYear);
    });
    return educationArray;
  }
  function sortbyyearcert(educationArray) {
    educationArray.sort(function (a, b) {
      if (Number(b.issueyear) === Number(a.issueyear)) {
        return (
          Number(getMonthNumber(b.issuemonth)) -
          Number(getMonthNumber(a.issuemonth))
        );
      }
      return Number(b.issueyear) - Number(a.issueyear);
    });
    return educationArray;
  }

  const [showsummarymodal, setshowsummarymodal] = useState(false);
  const [showcertificationmodal, setshowcertificationmodal] = useState(false);
  const [showawardsmodal, setshowawardsmodal] = useState(false);
  const [showallAwards, setshowallAwards] = useState(false);
  const [showallCertificates, setshowallCertificates] = useState(false);
  const [showvolexpmodal, setShowvolexpmodal] = useState(false);
  const manageSummarymodal = () => {
    setshowsummarymodal(!showsummarymodal);
  };

  const visibleItemsJobs = showAllJobs
    ? sortByFromYearDesc(dash.experience)
    : sortByFromYearDesc(dash.experience).slice(0, 2);
  const visibleItemsVol = showAllVolExp
    ? sortByFromYearDesc(dash.volexperience)
    : sortByFromYearDesc(dash.volexperience).slice(0, 2);
  const visibleItemsprojects = showAllProjects
    ? sortByFromYearDesc(dash.projects)
    : sortByFromYearDesc(dash.projects).slice(0, 2);
  const visibleItemsEducation = showAllEducation
    ? sortByFromYearDesc(dash.education)
    : sortByFromYearDesc(dash.education).slice(0, 2);
  const visibleItemsAwards = showallAwards
    ? sortByFromYearDesc(dash.awards)
    : sortByFromYearDesc(dash.awards).slice(0, 2);
  const visibleItemsCertificates = showallCertificates
    ? sortbyyearcert(dash.certifications)
    : sortbyyearcert(dash.certifications).slice(0, 2);

  const token = localStorage.getItem("token");
  const refreshtoken = localStorage.getItem("refreshtoken");
  const Location = useLocation();
  const [viewprof, setviewprof] = useState(false);
  const [loading, setloading] = useState(true);
  const [connectionres, setconnectionres] = useState({});

  //// // // console,log(token);
  const [connections, setconnections] = useState(0);
  useEffect(() => {
    const getprofile = async () => {
      setloading(true);
      try {
        const reqbody = {
          refreshtoken: refreshtoken,
          url: Location.pathname.slice(1),
        };
        // // // console,log("dashboard: ", reqbody, { token: token });
        const response = await axios.post(
          apiUrl.profile.getprofile + token,
          reqbody
        );
        //// // // console,log(response.status);
        // // // console,log(response);
        if (response.status === 401) {
          // localStorage.removeItem("token");
          // navigate("/");
        } else if (response.status === 200) {
          setviewprof(true);
          setconnections(response.data.noofconnections);
          setdash(response.data.existing_profile);
          setgetprofile(response.data.existing_profile);
          setpublicview(response.data.publicview);
          setimage(response.data.existing_profile.Profilepic);
          setcov(response.data.existing_profile.Coverpic);
          setCollegeProfiles(response.data.edushortlist);
          // // // console,log(response.data.token);
        }
      } catch (err) {
        // // // console,log("error encountered");
        // localStorage.removeItem("token");
        // navigate("/login");
      }
      setloading(false);
    };

    const getprofilewot = async () => {
      setloading(true);
      try {
        const reqbody = {
          url: Location.pathname.slice(1),
        };
        const response = await axios.post(apiUrl.profile.getprofile, reqbody);
        //// // // console,log(response.status);
        // // // console,log(response);
        if (response.status === 401) {
          // localStorage.removeItem("token");
          // navigate("/");
        } else if (response.status === 200) {
          setviewprof(false);
          // // // console,log(response.data.existing_profile);
          setdash(response.data.existing_profile);
          setconnections(response.data.noofconnections);
          setgetprofile(response.data.existing_profile);
          setpublicview(response.data.publicview);
          setimage(response.data.existing_profile.Profilepic);
          setcov(response.data.existing_profile.Coverpic);
          // // // console,log(response.data.token);
        }
      } catch (err) {
        // // // console,log("error encountered");
        if (err.response.status === 501) {
          setviewprof(true);
        }
      }
      setloading(false);
    };

    const postProfileStatus = async () => {
      try {
        const reqbody = {
          profilestatus: "/",
        };
        const response = await axios.post(
          apiUrl.profile.editprofile + `${token}`,
          reqbody
        );
        if (response.status == 200) {
        }
      } catch (error) {}
    };

    if (token) {
      postProfileStatus();
      getprofile();
    } else {
      getprofilewot();
    }
    //// // // console,log("working");
  }, []);

  // for all updates, does not effect the loading of page
  useEffect(() => {
    const getconnectiondetails = async () => {
      try {
        const resp = await axios.post(apiUrl.connections.getdetails + token, {
          url: url,
        });
        if (resp && resp.status === 200) {
          setconnectionres(resp.data);
          // setapi(!api)
          // // // console,log(resp.data)
        }
      } catch (err) {}
    };
    const getprofile = async () => {
      try {
        const reqbody = {
          refreshtoken: refreshtoken,
          url: Location.pathname.slice(1),
        };
        // // // console,log("dashboard: ", reqbody, { token: token });
        const response = await axios.post(
          apiUrl.profile.getprofile + token,
          reqbody
        );
        //// // // console,log(response.status);
        // // // console,log(response);
        if (response.status === 401) {
          // localStorage.removeItem("token");
          // navigate("/");
        } else if (response.status === 200) {
          setviewprof(true);
          // console.log(response.data.existing_profile);
          setdash(response.data.existing_profile);
          setgetprofile(response.data.existing_profile);
          setpublicview(response.data.publicview);
          setimage(response.data.existing_profile.Profilepic);
          setcov(response.data.existing_profile.Coverpic);
          setCollegeProfiles(response.data.edushortlist);
          // // // console,log(response.data.token);
        }
      } catch (err) {
        // // // console,log("error encountered");
        // localStorage.removeItem("token");
        // navigate("/login");
      }
    };

    const getprofilewot = async () => {
      try {
        const reqbody = {
          url: Location.pathname.slice(1),
        };
        // // console,log(reqbody)
        const response = await axios.post(apiUrl.profile.getprofile, reqbody);
        //// // // console,log(response.status);
        // // console,log(response);
        if (response.status === 401) {
          // localStorage.removeItem("token");
          // navigate("/");
        } else if (response.status === 200) {
          setviewprof(false);
          // // console,log(response.data.existing_profile);
          setdash(response.data.existing_profile);
          setgetprofile(response.data.existing_profile);
          setpublicview(response.data.publicview);
          setimage(response.data.existing_profile.Profilepic);
          setcov(response.data.existing_profile.Coverpic);
          // // // console,log(response.data.token);
        }
      } catch (err) {
        // // // console,log("error encountered");
        if (err.response.status === 501) {
          setviewprof(true);
        }
      }
    };

    if (token) {
      getprofile();
    } else {
      getprofilewot();
    }
    getconnectiondetails();
    //// // // console,log("working");
  }, [
    vieweditprofile,
    showediteducation,
    showeditexperience,
    showskills,
    showprojectmodal,
    api,
    openCompModal,
    Location,
  ]);

  const [addsection, setaddsection] = useState(false);
  const toggleaddsection = () => {
    setaddsection(!addsection);
  };

  const updateAnalytics = async () => {
    try {
      const resp = await axios.post(apiUrl.views.addviewcount + token, {
        url: url,
      });
      if (resp && resp.status === 200) {
        // console.log(resp.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if(token){
      // console.log("updating")
      updateAnalytics();
    }
  }, []);

  const renderComponent = (componentId) => {
    switch (componentId) {
      // case "Recognitions":
      //   return <Recognitions isDarkMode={isDarkMode} />;
      case "Projects":
        return (
          dash.projects.length !== 0 && (
            <ProjectsComponent
              dash={dash}
              setshowprojectmodal={setshowprojectmodal}
              showprojectmodal={showprojectmodal}
              visibleItemsprojects={visibleItemsprojects}
              setShowAllProjects={setShowAllProjects}
              showAllProjects={showAllProjects}
              publicview={publicview}
              isDarkMode={isDarkMode}
              api={api}
              setapi={setapi}
            />
          )
        );
      case "Skills":
        return (
          dash.skills.length !== 0 && (
            <SkillsComponent
              dash={dash}
              setshowskills={setshowskills}
              showskills={showskills}
              publicview={publicview}
              isDarkMode={isDarkMode}
            />
          )
        );
      case "Experience":
        return (
          dash.experience.length !== 0 && (
            <ExperienceComponent
              dash={dash}
              setshoweditexperience={setshoweditexperience}
              showAllJobs={showAllJobs}
              setShowAllJobs={setShowAllJobs}
              showeditexperience={showeditexperience}
              visibleItemsJobs={visibleItemsJobs}
              publicview={publicview}
              isDarkMode={isDarkMode}
              api={api}
              setapi={setapi}
            />
          )
        );
      case "Education":
        return (
          dash.education.length !== 0 && (
            <EducationComponent
              dash={dash}
              setshowediteducation={setshowediteducation}
              showAllEducation={showAllEducation}
              setShowAllEducation={setShowAllEducation}
              showediteducation={showediteducation}
              visibleItemsEducation={visibleItemsEducation}
              publicview={publicview}
              isDarkMode={isDarkMode}
              api={api}
              setapi={setapi}
            />
          )
        );
      case "volexperience":
        return (
          dash.volexperience.length !== 0 && (
            <VolExpcomponent
              isDarkMode={isDarkMode}
              dash={dash}
              publicview={publicview}
              showvolexpmodal={showvolexpmodal}
              setShowvolexpmodal={setShowvolexpmodal}
              showAllVolExp={showAllVolExp}
              setShowAllVolExp={setShowAllVolExp}
              api={api}
              setapi={setapi}
              visibleItemsVol={visibleItemsVol}
            />
          )
        );
      case "Certifications":
        return (
          dash.certifications.length !== 0 && (
            <CertificationComponent
              publicview={publicview}
              isDarkMode={isDarkMode}
              handleCertificationModal={handleCertificationModal}
              dash={dash}
              api={api}
              setapi={setapi}
              visibleItemsCertificates={visibleItemsCertificates}
              showallCertificates={showallCertificates}
              setshowallCertificates={setshowallCertificates}
            />
          )
        );
      case "Awards":
        return (
          dash.awards.length !== 0 && (
            <AwardsComponent
              publicview={publicview}
              isDarkMode={isDarkMode}
              handleAwardsModal={handleAwardsModal}
              dash={dash}
              api={api}
              setapi={setapi}
              visibleItemsAwards={visibleItemsAwards}
              showallAwards={showallAwards}
              setshowallAwards={setshowallAwards}
            />
          )
        );
      default:
        return null;
    }
  };

  const handleCertificationModal = () => {
    setshowcertificationmodal(!showcertificationmodal);
  };
  const handleAwardsModal = () => {
    setshowawardsmodal(!showawardsmodal);
  };

  const handleEditProfile = () => {
    setvieweditprofile(!vieweditprofile);
  };
  if (loading) {
    return <Spinnerpage />;
  } else if (viewprof && token == null) {
    return navigate("/login");
  } else {
    return (
      <div>
        <div>
          {/* {showsummarymodal &&
            // <EditSummaryModal
            //   manageSummarymodal={manageSummarymodal}
            //   isDarkMode={isDarkMode}
            //   dash={dash}
            //   api={api}
            //   setapi={setapi}
            // />
            // // // console,log(showsummarymodal)} */}
          {showvolexpmodal && (
            <AddVolExpModal
              isDarkMode={isDarkMode}
              showvolexpmodal={showvolexpmodal}
              setShowvolexpmodal={setShowvolexpmodal}
              setapi={setapi}
              api={api}
            />
          )}
          {showsummarymodal && (
            <EditSummaryModal
              manageSummarymodal={manageSummarymodal}
              isDarkMode={isDarkMode}
              dash={dash}
              api={api}
              setapi={setapi}
            />
          )}
          {showcertificationmodal && (
            <AddCertificationModal
              handleCertificationModal={handleCertificationModal}
              isDarkMode={isDarkMode}
              api={api}
              setapi={setapi}
            />
          )}
          {showawardsmodal && (
            <AddAawardsModal
              handleAwardsModal={handleAwardsModal}
              isDarkMode={isDarkMode}
              api={api}
              setapi={setapi}
            />
          )}
          {vieweditprofile && (
            <EditProfileModel
              vieweditprofile={vieweditprofile}
              setvieweditprofile={setvieweditprofile}
              isDarkMode={isDarkMode}
              api={api}
              setapi={setapi}
            />
          )}

          {addsection && (
            <AddSectionModal
              toggleaddsection={toggleaddsection}
              toggleproject={() => setshowprojectmodal(!showprojectmodal)}
              toggleeducation={() => setshowediteducation(!showediteducation)}
              toggleexperience={() =>
                setshoweditexperience(!showeditexperience)
              }
              toggleskills={() => setshowskills(!showskills)}
              togglesummary={manageSummarymodal}
              handleAwardsModal={handleAwardsModal}
              handleCertificationModal={handleCertificationModal}
              handleVolExpModal={() => {
                setShowvolexpmodal(!showvolexpmodal);
              }}
              isDarkMode={isDarkMode}
            />
          )}
          {showprojectmodal && (
            <EditProjectModal
              vieweditprofile={showprojectmodal}
              setvieweditprofile={setshowprojectmodal}
              isDarkMode={isDarkMode}
              api={api}
              setapi={setapi}
            />
          )}
          {showediteducation && (
            <EditEduModel
              vieweditprofile={showediteducation}
              setvieweditprofile={setshowediteducation}
              isDarkMode={isDarkMode}
              api={api}
              setapi={setapi}
            />
          )}
          {showeditexperience && (
            <EditExpModel
              vieweditprofile={showeditexperience}
              setvieweditprofile={setshoweditexperience}
              isDarkMode={isDarkMode}
              api={api}
              setapi={setapi}
            />
          )}
          {showskills && (
            <EditSkillsModal
              vieweditprofile={showskills}
              setvieweditprofile={setshowskills}
              isDarkMode={isDarkMode}
              api={api}
              setapi={setapi}
            />
          )}
          <div></div>
        </div>
        <div
          className={`flex justify-center min-h-screen ${
            // f0f1f4
            isDarkMode ? "bg-[#282c34]" : "bg-[#ffffff]"
          }`}
        >
          <div className="w-full md:max-w-screen-xl md:w-[100%] flex md:flex-row flex-col gap-x-4 md:p-4">
            <div className="rounded-md md:w-[70%] w-[100%]">
              <div className={`md:pb-2 md:space-y-4 space-y-2 z-0 `}>
                <PersonalDetails
                  dash={dash}
                  handleEditProfile={handleEditProfile}
                  openModal={openModal}
                  publicview={publicview}
                  setpublicview={setpublicview}
                  isDarkMode={isDarkMode}
                  api={api}
                  setapi={setapi}
                  toggleaddsection={toggleaddsection}
                  url={url}
                  manageCompModal={manageCompModal}
                  openCompModal={openCompModal}
                  coverpicmodal={coverpicmodal}
                  setcoverpicmodal={setcoverpicmodal}
                  profilepicmodal={profilepicmodal}
                  setprofilepicmodal={setprofilepicmodal}
                  image={image}
                  setimage={setimage}
                  cov={cov}
                  setcov={setcov}
                  connectionres={connectionres}
                  connections={connections}
                />
                {dash.summary.trim() !== "" && (
                  <SummaryComponent
                    isDarkMode={isDarkMode}
                    publicview={publicview}
                    dash={dash}
                    api={api}
                    setapi={setapi}
                    manageSummarymodal={manageSummarymodal}
                    showsummarymodal={showsummarymodal}
                  />
                )}
                {Object.entries(dash.componentOrder).map(
                  ([item, isEnabled], key) => (
                    <div key={key}>{isEnabled[2] && renderComponent(item)}</div>
                  )
                )}
              </div>
            </div>

            <div className="md:w-[30%] w-[100%] h-full  md:space-y-4 space-y-2">
            
              {!publicview && (
                <div>
                  <div className="">
                    <URLComponent
                      urlsubmit={urlsubmit}
                      curraddsubmit={curraddsubmit}
                      isDarkMode={isDarkMode}
                      url={url}
                      curradd={curradd}
                      publicview={publicview}
                      connectionres={connectionres}
                      api={api}
                      setapi={setapi}
                    />
                  </div>
                  {/* <div className="my-4">
                    <AppliedJobsComponent />
                  </div>
                  <div className="my-4">
                  <EnrolledCourses />
                  </div> */}
                  <div className="my-4">
                    <AnalyticsComponent
                    urlsubmit={urlsubmit}
                    curraddsubmit={curraddsubmit}
                    isDarkMode={isDarkMode}
                    url={url}
                    curradd={curradd}
                    publicview={publicview}
                    connectionres={connectionres}
                    api={api}
                    setapi={setapi}
                    />
                  </div>
                </div>
              )}
              {/* <CollegeSuggestedProfiles
                edushortlist={collegeprofiles}
                seturl={seturl}
              /> */}

              {/* {publicview && (
                <textarea className="resize-y rounded-md border border-gray-300 p-2 w-full text-black h-[25%]" placeholder="Enter your notes..."></textarea>
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Layout;
