import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import certificate from "../images/DefaultCertificate.png";
import award from "../images/DefaultAwards.png";
import project from "../images/Defaultproject.png";
import experience from "../images/DefaultCompany.png";
import education from "../images/DefaultEducation.png";
import skill from "../images/DefaultSkills.png";
import volunteer from "../images/DefaultVolunteer.png";
export default function AddSectionModal({
  toggleaddsection,
  toggleproject,
  toggleeducation,
  toggleexperience,
  toggleskills,
  togglesummary,
  handleAwardsModal,
  handleCertificationModal,
  handleVolExpModal,
  isDarkMode,
}) {
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const sections = ["Project", "Eduction", "Experience", "Skills"];
  const sectionsModals = {
    Summary: togglesummary,
    Project: toggleproject,
    Education: toggleeducation,
    Experience: toggleexperience,
    Skill: toggleskills,
    Achievement: handleAwardsModal,
    Certification: handleCertificationModal,
    "Volunteer Experience": handleVolExpModal,
  };

  const svgs = {
    Summary: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-6 "
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
        />
      </svg>
    ),
    Project: <img src={project} className="size-6" alt="" />,

    Education: <img src={education} className="size-6" alt="" />,
    Experience: (
      <img src={experience} className="size-6" alt="" />
    ),
    Skill: (
      <img src={skill} className="size-6" alt="" />
    ),
    Achievement: (
      <img src={award} className="size-6" alt="" />
    ),
    Certification: (
      <img src={certificate} className="size-6" alt="" />
    ),
    "Volunteer Experience": (
      <img src={volunteer} className="size-6" alt="" />
    
    ),
  };

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={toggleaddsection}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-full h-screen overflow-y-auto">
            <div className="flex h-screen justify-center p-4 text-center md:items-center  sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform  overflow-hidden rounded-xl  text-left transition-all  mx-4">
                  <div
                    className={` md:w-[40vw] max-w-screen-lg  w-[75vw] max-h-[80vh] md:max-h-[100vh] overflow-auto  rounded-xl border-[1.5px] p-4  ${
                      isDarkMode
                        ? "bg-[#2a303c] border-[#2a303c]"
                        : "bg-white text-black"
                    }`}
                  >
                    <div className="flex w-full items-end justify-between py-4 cursor-pointer">
                      <div className="text-2xl font-[550]">Add Section</div>
                      <IoMdClose
                        size={30}
                        onClick={toggleaddsection}
                        className="p-1 hover:bg-gray-200 border border-none rounded-full duration-300"
                      />
                    </div>
                    <div className=" pb-2 text-md text-metal-700">
                      Start with the basics. Filling out these sections will
                      help you be discovered by recruiters and people you may
                      know
                    </div>

                    <div>
                      <ul className=" list-none grid md:grid-cols-3 grid-cols-2 gap-2 w-full justify-center items-center ">
                        {Object.keys(sectionsModals).map((item, key) => (
                          <li
                            key={key}
                            className={` hover:bg-blue-50  w-full rounded-md  text-sm hover:scale-[1.05] duration-100  md:p-4 p-6   text-center  flex items-center justify-center flex-col py-2 cursor-pointer`}
                            onClick={() => {
                              sectionsModals[item]();
                              toggleaddsection();
                            }}
                          >
                            {svgs[item]}
                            {item === "Volunteer Experience"
                              ? "Volunteering"
                              : item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
